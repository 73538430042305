import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import ResetIcon from "@material-ui/icons/Refresh";
import SubmitIcon from "@material-ui/icons/CheckCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { storeEntityId, getEntityId, getMaxEntityAccess } from "../utils/Utils";
import {
  getEntity,
  getLocations,
  getCollections,
  showMessage
} from "../actions/index";
import { connect } from "react-redux";
import { compose } from "recompose";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiRequest } from "../utils/Utils";
import AlertDialog from "./AlertDialog";
import { Link } from "react-router-dom";
import { PFContext } from "./PrimaryFilterContext";

const styles = theme => ({
  input: {
    height: 38
  },
  label: {
    marginTop: "-5px"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    flexGrow: 1
  },
  root2: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  textField: { width: "100%" },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: "#4b2e83",
    width: "250px"
  },
  progress: {
    color: "#4b2e83"
  }
});

class CreateEntity extends React.Component {
  constructor(props) {
    super(props);
    this.page = props.params;
  }
  state = {
    stateModel: {
      EntityId: sessionStorage.getItem("entityID") ? JSON.parse(sessionStorage.getItem("entityID")) : "",
      Name: "",
      ShortName: "",
      Description: ""
    },
    serverModel: {
      EntityId: "",
      Name: "",
      ShortName: "",
      Description: ""
    },
    entityData: [],
    isSubmitting: false,
    disableFields: false,
    openAlert: false,
    showRedField: false
  };

  handleChange = name => event => {
    this.old_EntityId = this.state.stateModel.EntityId;

    this.setState(
      {
        stateModel: {
          ...this.state.stateModel,
          [name]: event.target.value
        }
      },
      () => {
        if (name === "EntityId") this.checkAccess(this.old_EntityId);
      }
    );
  };

  checkAccess(oldEntityId) {
    const { EntityId } = this.state.stateModel;
    apiRequest(
      `/accesscontrol?resourceType=entity&resourceId=${EntityId}`,
      "get"
    )
      .then(response => {
        if (response.data[0] == 700) {
          this.setState({
            disableFields: false
          });
          if (this.state.stateModel.EntityId != oldEntityId) {
            this.getEntityDetails();
          }
        } else {
          this.setState({
            disableFields: true
          });
          if (this.state.stateModel.EntityId != oldEntityId) {
            this.getEntityDetails();
          }
        }
      })
      .catch(error => console.log("error", error));
  }

  getEntityDetails() {
    const that = this;
    this.props.showTables(true, this.state.stateModel.EntityId);
    this.props.getLocation(this.state.stateModel.EntityId);
    this.props.getCollection(this.state.stateModel.EntityId, 0);
    storeEntityId(this.state.stateModel.EntityId);

    apiRequest(`/entities/${this.state.stateModel.EntityId}`, "get")
      .then(response => {
        this.setState(
          {
            stateModel: this.state.serverModel
          },
          () => {
            this.setState({
              stateModel: response.data[0] || this.state.serverModel
            });
          }
        );
      })
      .catch(error => console.log("error", error));
  }

  componentWillMount() {
    this.props.storeEntity();
    if (sessionStorage.getItem("entityID")) {
      storeEntityId(JSON.parse(sessionStorage.getItem("entityID")));
    }
    if (this.props.params === "Modify" && getEntityId() > 0) {
      this.setState(
        {
          stateModel: {
            ...this.state.stateModel,
            EntityId: getEntityId()
          }
        },
        () => this.getEntityDetails()
      );
    }
  }

  handleReset = () => {
    const stateModel = this.state.serverModel;
    this.setState({
        stateModel: {
          ...this.state.serverModel,
          //EntityId: sessionStorage.getItem("entityID") ? JSON.parse(sessionStorage.getItem("entityID")) : "",
          EntityId: ""
        },
    });
    this.props.showTables(false);
  };

  handleSubmt = () => {
    const THAT = this;
    THAT.setState({
      isSubmitting: true,
      openAlert: false,
      showRedField: false
    });
    const data = this.state.stateModel;
    let url = "";
    let method = "";
    if (this.props.params === "Create") {
      url = `/entities`;
      method = "post";
    } else if (this.props.params === "Modify") {
      url = `/entities/${this.state.stateModel.EntityId}`;
      method = "put";
    }

    apiRequest(url, method, data)
      .then(x => {
        if (x.status > 0) {
          storeEntityId(x.data[0]);
          if (THAT.props.params === "Create") {
            THAT.props.showTables(true, THAT.state.stateModel.EntityId);
            THAT.props.history.push("/options/Modify/entity");
          }
          THAT.props.storeEntity();
        }
        THAT.setState({
          isSubmitting: false
        });
        if (x.status > 0 && x.message == null)
          THAT.props.showMessageBox("SUCCESS");
        else
          THAT.props.showMessageBox(x.message);
      })
      .catch(err => {
        THAT.setState({
          isSubmitting: false
        });
        THAT.props.showMessageBox("ERROR");
      });
  };

  stateChange() {
    const { serverModel, stateModel } = this.state;
    return JSON.stringify(serverModel) === JSON.stringify(stateModel)
      ? false
      : true;
  }

  componentWillReceiveProps({ params, entities, id }) {
    if (this.page !== params) {
      this.handleReset();
      this.props.storeEntity();
      this.page = params;
    }

    this.setState({
      entityData: entities
    });

    //Entity handling start
    if (id !== this.state.stateModel.EntityId && this.props.location.pathname.includes("Modify")) {
      if (id == "") {
        //Organization is cleared, so let's clear everything
        this.handleReset();
      } else {
        this.setState(
          {
            stateModel: {
              ...this.state.stateModel,
              EntityId: id
            }
          },
          () => this.getEntityDetails()
        );
      }
      // this.setState(
      //   {
      //     stateModel: {
      //       ...this.state.stateModel,
      //       EntityId: id
      //     }
      //   },
      //   () => this.getEntityDetails()
      // );
    }
    //Entity handling end

    // if (
    //   //this.props.params === "Modify" &&
    //   this.props.location.pathname.includes("Modify") &&
    //   getEntityId() > 0 &&
    //   this.state.stateModel.EntityId /*=== ""*/
    // ) {
    //   this.setState(
    //     {
    //       stateModel: {
    //         ...this.state.stateModel,
    //         EntityId: getEntityId()
    //       }
    //     },
    //     () => this.getEntityDetails()
    //   );
    // }
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        className="justify-content-between  align-items-center pb-2 mb-3"
        style={{
          paddingLeft: "12px",
          paddingRight: "12px"
        }}
      >
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={this.handleSubmt}
          action={"Save"}
          close={() => {
            this.setState({ openAlert: false });
          }}
        />
        {getMaxEntityAccess() == 700 ? (
          this.props.params === "Create" ? (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid #4b2e83"
                  }}
                >
                  <div className="nav-link">
                    <h5
                      style={{
                        color: "#4b2e83"
                      }}
                    >
                      Add Organization
                    </h5>{" "}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid lightgrey"
                  }}
                >
                  <Link className="nav-link" to="/options/Modify/entity" onClick={() => this.setState({ showRedField: false })}>
                    <h5
                      style={{
                        color: "lightgrey"
                      }}
                    >
                      Edit Organization
                    </h5>
                  </Link>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid lightgrey"
                  }}
                >
                  <Link className="nav-link" to="/options/Create/entity" onClick={() => this.setState({ showRedField: false })}>
                    <h5
                      style={{
                        color: "lightgrey"
                      }}
                    >
                      Add Organization
                    </h5>
                  </Link>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid #4b2e83"
                  }}
                >
                  <div className="nav-link">
                    <h5
                      style={{
                        color: "#4b2e83"
                      }}
                    >
                      Edit Organization
                    </h5>{" "}
                  </div>
                </div>
              </Grid>
            </Grid>
          )
        ) : null}
        <Paper
          className={classes.root}
          style={{
            padding: 10
          }}
        >
          <div>
            <div>
              <Grid
                container
                spacing={2}
                style={{ marginBottom: "-13px", marginTop: "-13px" }}
              >
                <Grid item xs={4}>
                  {this.props.params === "Create" ? (
                    <div className="float-left">
                      <h4
                        style={{
                          color: "#4b2e83",
                          width: "100%",
                          paddingLeft: 2,
                          paddingTop: 8
                        }}
                      >
                        Add Organization
                      </h4>
                    </div>) :
                    (
                      <PFContext.Consumer>
                        {(data) => (
                            <TextField
                              width="100%"
                              id="outlined-select"
                              select
                              required
                              label="Organization"
                              margin="dense"
                              className={classes.textField}
                              value={this.state.stateModel.EntityId}
                              //onChange={this.handleChange("EntityId")}
                              onChange={evt => {
                                data.handeChange(evt.target.value);
                                this.old_EntityId = this.state.stateModel.EntityId;
                                this.setState(
                                  {
                                    stateModel: {
                                      ...this.state.stateModel,
                                      EntityId: evt.target.value
                                    }
                                  },
                                  () => {
                                    this.checkAccess(this.old_EntityId);
                                  }
                                );
                              }}
                              disabled={this.state.stateModel.EntityId != ""}
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu
                                },
                                className: classes.input
                              }}
                              InputProps={{ className: classes.input }}
                              InputLabelProps={{
                                shrink: true,
                                className: classes.label
                              }}
                              variant="outlined"
                            >
                              {this.state.entityData.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                        )}
                      </PFContext.Consumer>
                    )
                  }
                </Grid>
                <Grid item xs={4}>
                </Grid>
                {!this.state.isSubmitting ? (
                  <Grid item xs={4}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Tooltip title="Reset">
                        <IconButton
                          style={
                            this.stateChange()
                              ? {
                                  color: "#4b2e83",
                                  opacity: "1",
                                  outline: "none"
                                }
                              : { color: "#343a40", opacity: "0.4" }
                          }
                          aria-label="Reset"
                          onClick={this.handleReset}
                        >
                          <ResetIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Done">
                        <IconButton
                          style={
                            this.stateChange() && !this.state.disableFields
                              ? {
                                  color: "#4b2e83",
                                  opacity: "1",
                                  outline: "none"
                                }
                              : { color: "#343a40", opacity: "0.4" }
                          }
                          aria-label="Done"
                          disabled={
                            !this.stateChange() || this.state.disableFields
                          }
                          onClick={() => {
                            if (
                              this.state.stateModel.Name == "" ||
                              this.state.stateModel.ShortName == ""
                            ) {
                              this.setState({
                                showRedField: true
                              });
                              this.props.showMessageBox(
                                "Please fill the required fields"
                              );
                              return;
                            }
                            this.setState({ openAlert: true });
                          }}
                        >
                          <SubmitIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={4}>
                    <div
                      style={{
                        width: "100%",
                        marginTop: 5,
                        display: "flex",
                        justifyContent: "flex-end"
                      }}
                    >
                      <CircularProgress className={classes.progress} />
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
            <hr />

            <Grid container spacing={2}>
              <Grid item xxs={12} sm={6} md={4} lg={4}>
                <TextField
                  id="outlined-name"
                  label="Name"
                  value={this.state.stateModel.Name}
                  type="text"
                  error={
                    this.state.showRedField && this.state.stateModel.Name == ""
                      ? true
                      : false
                  }
                  disabled={this.state.disableFields || (this.props.params === "Modify" && !this.state.stateModel.Name)}
                  style={{
                    paddingBottom: "5px",
                    width: "100%",
                    borderColor: "#4b2e83"
                  }}
                  required
                  onChange={this.handleChange("Name")}
                  margin="dense"
                  variant="outlined"
                  placeholder="Organization Name"
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label
                  }}
                />
                <TextField
                  id="outlined-name"
                  label="Short Name"
                  value={this.state.stateModel.ShortName}
                  type="text"
                  style={{
                    width: "100%",
                    borderColor: "#4b2e83"
                  }}
                  required
                  error={
                    this.state.showRedField &&
                    this.state.stateModel.ShortName == ""
                      ? true
                      : false
                  }
                  disabled={this.state.disableFields || (this.props.params === "Modify" && !this.state.stateModel.ShortName)}
                  onChange={this.handleChange("ShortName")}
                  margin="dense"
                  variant="outlined"
                  placeholder="Organization Short Name"
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={8} lg={8}>
                <TextField
                  id="outlined-name"
                  label="Description"
                  value={this.state.stateModel.Description}
                  type="text"
                  style={{
                    width: "100%",
                    borderColor: "#4b2e83"
                  }}
                  disabled={this.state.disableFields || (this.props.params === "Modify" && !this.state.stateModel.Description)}
                  onChange={this.handleChange("Description")}
                  margin="dense"
                  multiline
                  rows="3"
                  variant="outlined"
                  placeholder="Description"
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { entities } = state;
  return {
    entities
  };
};

const mapDispatchToProps = dispatch => {
  return {
    storeEntity: () => getEntity(dispatch),
    getLocation: entityId => getLocations(dispatch, entityId),
    getCollection: (entityId, locId) => getCollections(dispatch, entityId, locId),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(CreateEntity);
