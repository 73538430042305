import $ from "jquery";
import Cookies from "js-cookie";
import { h } from "preact";

let entityId = -1;
let locationId = -1;
export let access = null;
export let isPortalAdmin = "";

export const API_BASE_URL = window.BASE_URL;
export const admintoken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0IjoxNTg1MTUxMDkzLCJ2YWx1ZSI6IjU1NTQ5OTdjZDc2ODgwYTI4NzE1YjJiZDQ2ZWFlOTZiYzZlMDgwODcifQ.6zmgDTp6-zONGze3jCiXHW9C7jYaBDiCNB1mf_QP_XM";
export const meridianLocationId = "5103333125914624";
export const token = admintoken;

export const defaultImage = 'https://files.meridianapps.com/meridian-web-sdk/0.25.0/files/tags/generic.svg';

export const allowedDomains = ["@uw.edu", "@hpe.com", "@hpepngalway.onmicrosoft.com"];

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$_&@%]).{8,15}$/;
export const PASSWORD_ERROR = "Password does not meet the criteria";

export const lowBatteryClass = "tag" + Date.now().toString(16);


export function getOption(method = 'get') {
  let headers = new Headers();
  headers.append('Authorization', `Token ${admintoken}`);
  headers.append('Content-Type', 'application/json');
  var option = {
    method,
    headers
  };
  return option;
}

export function byName(a, b) {
  const alc = a.name ? a.name.toLowerCase() : '';
  const blc = b.name ? b.name.toLowerCase() : '';
  if (alc < blc)
    return -1;
  else if (alc > blc)
    return 1;
  else
    return 0;
}

export function byPropertyCalled(property="name") {
  return function(a, b) {
    const alc = a[property] ? a[property].toLowerCase() : '';
    const blc = b[property] ? b[property].toLowerCase() : '';
    if (alc < blc)
      return -1;
    else if (alc > blc)
      return 1;
    else
      return 0;
  }
}

export function formatDate(date) {
  return date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2)
  + "-" + ("00" + date.getDate()).slice(-2)
  + " "
  + ("00" + date.getHours()).slice(-2) + ":"
  + ("00" + date.getMinutes()).slice(-2)
  + ":" + ("00" + date.getSeconds()).slice(-2);
}

export function storeUser(userName) {
  localStorage.setItem("user", userName);
}

export function getUser() {
  return localStorage.getItem("user");
}

export function storeEntityId(data) {
  entityId = parseInt(data);
}

export function getEntityId() {
  return entityId;
}

export function getlocationId() {
  return locationId;
}

export function storelocationId(data) {
  locationId = parseInt(data);
}

export function setAccess(accessLevels) {
  access = accessLevels;
}

export function getAccess() {
  return access;
}

export function getMaxEntityAccess() {
  let maxAccess = 100;
  let data = [];

  data = access.filter(item => item.resourceType == "global");
  if (data.length > 0) {
    isPortalAdmin = true;
    return data[0].access_level;
  }
  isPortalAdmin = false;
  data = access.filter(item => item.resourceType == "entity");
  if (data.length === 0) maxAccess = 100;
  else if (data.length === 1) maxAccess = data[0].access_level;
  else
    maxAccess = data
      .map(el => el.access_level)
      .reduce((a, b) => Math.max(a, b));
  return maxAccess;
}

export function getMaxLocationAccess() {
  let maxAccess = 100;
  const data = access.filter(item => item.resourceType == "location");
  if (data.length === 0) maxAccess = 100;
  else if (data.length === 1) maxAccess = data[0].access_level;
  else
    maxAccess = data
      .map(el => el.access_level)
      .reduce((a, b) => Math.max(a, b));
  return maxAccess;
}

export function getMaxDepartmentAccess() {
  let maxAccess = 100;
  const data = access.filter(item => item.resourceType == "department" || item.resourceType == "collection");
  if (data.length === 0) maxAccess = 100;
  else if (data.length === 1) maxAccess = data[0].access_level;
  else
    maxAccess = data
      .map(el => el.access_level)
      .reduce((a, b) => Math.max(a, b));
  return maxAccess;
}

export function fetchRequest(url, method, next = null) {
  const URL = API_BASE_URL + url;

  const tokenJson = Cookies.getJSON(`owAuth${window.location.port}`) || "";
  if (tokenJson == "") {
    localStorage.setItem("lastUrl", window.location.pathname);
    return new Promise((resolve, reject) => {
      $.ajax({
        method: "post",
        url: `${API_BASE_URL}/autologout?userId=${getUser()}`,
        cache: false,
        dataType: "json",
        success: res => {
          resolve(res);
          window.location = "/";
        },
        error: err => {
          reject(err);
          window.location = "/";
        }
      });
      return;
    });
  }
  const token = tokenJson.token;

  let headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Content-Type', 'application/json');
  headers.append('username', localStorage.getItem("user"));
  if (next) {
    Object.entries(next).forEach(itm => headers.append(itm[0], itm[1]));
  }
  var option = {
    method,
    headers
  };
  return fetch(URL, option);
}

export function apiRequest(url, method, data = "") {
  const URL = API_BASE_URL + url;

  return new Promise((resolve, reject) => {
    const tokenJson = Cookies.getJSON(`owAuth${window.location.port}`) || "";
    if (tokenJson == "") {
      localStorage.setItem("lastUrl", window.location.pathname);
      $.ajax({
        method: "post",
        url: `${API_BASE_URL}/autologout?userId=${getUser()}`,
        cache: false,
        dataType: "json",
        success: res => {
          resolve(res);
          window.location = "/";
        },
        error: err => {
          reject(err);
          window.location = "/";
        }
      });
      return;
    }
    const token = tokenJson.token;

    $.ajax({
      url: URL,
      method: method,
      cache: false,
      dataType: "json",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        username: localStorage.getItem("user")
      },
      success: function(x) {
        // console.log(
        //   "url:",
        //   URL,
        //   " method:",
        //   method,
        //   " data:",
        //   JSON.stringify(data)
        // );
        // console.log("uow-response", x);
        resolve(x);
      },
      error: function(err) {
        reject(err);
      }
    });
  });
}

export function atLastPageScrollToTop(datalength, rowsPerPage, currentpage) {
  //currentpage start from 0
  var a = Math.floor(datalength / rowsPerPage);
  var b = datalength % rowsPerPage;
  if ((b !== 0 && a == currentpage) || (b == 0 && a-1 == currentpage)) {
    window.scrollTo(0, 0);
  }
}

export function addLowBatteryStyle(doc) {
  if (!doc.getElementById(lowBatteryClass)) {
    var style = doc.createElement("style");
    style.id = lowBatteryClass;
    style.innerHTML = `.${lowBatteryClass} { border: 3px solid red; overflow: visible; }`;
    style.setAttribute("type", "text/css");
    doc.head.appendChild(style);
  }
}

export function addLowBatteryHint(t) {
  if (t.battery_level && t.battery_level < 30 && (t.tags == null || Array.isArray(t.tags))) {
    t.tags = {
      battery: t.battery_level,
      labels: t.tags ? t.tags : [],
      map(f1) {
        return {
          battery: this.battery,
          values: this.labels.map(f1),
          map(f2) {
            let result = new Array(this.values.length + 1);
            if (typeof f2(lowBatteryClass) == "string")
              result[0] = lowBatteryClass;
            else
              result[0] = h("div", { style: { margin: "-8px 0 8px -3px", color: "red" } }, `Battery Level: ${Math.round(this.battery)}%`);
            for (let i = 0; i < this.values.length; i++)
              result[i+1] = f2(this.values[i], i, this.values);
            return result;
          }
        };
      }
    };
  }
}
