import {
  STORE_ENTITIES,
  STORE_LOCATIONS,
  STORE_COLLECTIONS,
  STORE_ASSETS,
  STORE_DEVICE_FLAGS,
  STORE_ASSET_TYPES,
  CLEAN_DATA,
  SHOW_MESSAGE,
  CLEAR_MESSAGE,
  STORE_ASSET_MODELS,
  STORE_ASSET_MANUFACTURERS,
  CLEAN_ASSETS,
  STORE_ACCESS_LEVELS,
  HIDE_LOADER
} from "../actions/index";

const initialState = {
  entities: [],
  locations: [],
  collections: [],
  assets: [],
  deviceFlags: [],
  assetTypes: [],
  assetModel: [],
  assetManufacturer: [],
  message: "",
  accessLevels: [],
  hideLoader: false
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_ENTITIES:
      return Object.assign({}, state, {
        entities: action.entities
      });

    case STORE_LOCATIONS:
      return Object.assign({}, state, {
        locations: action.locations
      });

    case STORE_COLLECTIONS:
      return Object.assign({}, state, {
        collections: action.collections
      });

    case STORE_ASSETS:
      return Object.assign({}, state, {
        assets: action.assets
      });

    case STORE_DEVICE_FLAGS:
      return Object.assign({}, state, {
        deviceFlags: action.flags
      });

    case STORE_ASSET_TYPES:
      return Object.assign({}, state, {
        assetTypes: action.assetsTypes
      });
    case STORE_ASSET_MANUFACTURERS:
      return Object.assign({}, state, {
        assetManufacturer: action.assetManufacturers
      });
    case STORE_ASSET_MODELS:
      return Object.assign({}, state, {
        assetModel: action.assetmodels
      });

    case STORE_ACCESS_LEVELS:
      return Object.assign({}, state, {
        accessLevels: action.accessLevels
      });

    case CLEAN_DATA:
      return Object.assign({}, state, {
        locations: [],
        collections: []
      });

    case CLEAN_ASSETS:
      return Object.assign({}, state, {
        assets: []
      });

    case SHOW_MESSAGE:
      return Object.assign({}, state, {
        message: action.message
      });

    case CLEAR_MESSAGE:
      return Object.assign({}, state, {
        message: ""
      });

    case HIDE_LOADER:
      return Object.assign({}, state, {
        hideLoader: action.hide
      });

    default:
      return state;
  }
}
export default rootReducer;
