import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import SubmitIcon from "@material-ui/icons/CheckCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ResetIcon from "@material-ui/icons/Refresh";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { showMessage, getAccessLevels } from "../actions/index";
import { connect } from "react-redux";
import { compose } from "recompose";
import { apiRequest, byName, byPropertyCalled } from "../utils/Utils";
import AlertDialog from "./AlertDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const tableHeader = [
  { id: "0", label: "Image" },
  { id: "1", label: "User Id" },
  { id: "2", label: "Name" },
  { id: "3", label: "Action" }
];

const styles = theme => ({
  input: {
    height: 40
  },
  label: {
    marginTop: "-5px"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  textField: { width: "100%" },
  table: { marginTop: 10 },

  tableHead: {
    fontWeight: 400,
    fontSize: 14,
    fontWeight: 500,
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.54)"
  },
  tableRow: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    verticalAlign: "middle !important",
    fontWeight: 400,
    fontSize: "0.8125rem",
    color: "rgba(0, 0, 0, 0.87)"
  }
});

class AssignOrRemoveUsers extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      user: "",
      access: "",
      accessLevels: [],
      UnMappeduserData: [],
      MappedUserData: [],
      hideControls: true,
      memberToDelete: "",
      openAlert: false,
      //entity: ""
    };
  }

  componentWillMount() {
    this.props.getAccessLevel();
    this.fetchUnMappedUsers();
    this.fetchMappedUsers();
    this.checkAccess();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps({ accessLevels, entityId, locationId, collectionId }) {
    if (this.state.accessLevels.length == 0 && accessLevels.length > 0) {
      this.setState(
        {
          accessLevels: accessLevels.sort(byName)
        },
        () => {
          this.checkAccess();
        }
      );
    }
    //if (entityId != this.state.entity) {
    if (entityId != this.props.entityId || locationId != this.props.locationId || collectionId != this.props.collectionId) {
      //this.setState({ entity: entityId },
      this.setState({ MappedUserData: [] },
        () => {
          //this.props.getAccessLevel();
          //this.fetchUnMappedUsers();
          this.fetchMappedUsers();
          this.checkAccess();
        }
      );
    }
  }

  checkAccess() {
    const { entityId, locationId = "", collectionId, type } = this.props;
    let resourceType = "";
    let resourceId = "";

    if (type == "collection") {
      if (this.props.params === "Create") {
        if (locationId == "") {
          resourceType = "entity";
          resourceId = entityId;
        } else {
          resourceType = "location";
          resourceId = locationId;
        }
      } else if (this.props.params === "Modify") {
        resourceType = "collection";
        resourceId = collectionId;
      }
    } else {
      if (locationId == "") {
        resourceType = "entity";
        resourceId = entityId;
      } else {
        resourceType = "location";
        resourceId = locationId;
      }
    }

    apiRequest(
      `/accesscontrol?resourceType=${resourceType}&resourceId=${resourceId}`,
      "get"
    )
      .then(response => {
        if (this._isMounted) {
          if (response.data[0] <= 300) {
            this.setState({
              hideControls: true
            });
          } else {
            this.setState({
              hideControls: false
            });
          }
        }
      })
      .catch(error => console.log("error", error));
  }

  fetchUnMappedUsers() {
    apiRequest("/users?is_active=true", "get")
      .then(x => {
        if (this._isMounted) {
          this.setState({
            UnMappeduserData: x.data.map(user => {
              user.Label = `${user.FullName} (${user.UserId})`;
              return user;
            })
          });
        }
      })
      .catch(err => console.log(err));
  }

  fetchMappedUsers() {
    const { entityId, locationId, collectionId, type } = this.props;
    let url = "";
    switch (type) {
      case "entity":
        url = `/entities/${entityId}/members`;
        break;
      case "location":
        url = `/locations/${locationId}/members`;
        break;
      case "collection":
        url = `/collections/${collectionId}/members`;
        break;
      default:
        url = "";
        break;
    }

    apiRequest(url, "get")
      .then(x => {
        if (this._isMounted) {
          this.setState({
            MappedUserData: x.data
          });
        }
      })
      .catch(err => console.log(err));
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChangeUser = name => (event,value) => {
    this.setState({
      [name]: value
    });
  };

  handleSubmt = () => {
    const that = this;
    const { user, access } = this.state;
    if (user && access) {
      const data = {
        Name: user.FullName,
        AccessLevel: access,
        EntityId: this.props.entityId,
        LocationId: this.props.locationId,
        CollectionId: this.props.collectionId,
        CreatedBy: "",
        UserName: user.UserId
      };
      apiRequest("/accesscontrol", "post", data)
        .then(x => {
          if (x.status > 0 && x.message == null) {
            this.setState({
              user: "",
              access: ""
            });
            that.fetchMappedUsers();
            that.props.showMessageBox("SUCCESS");
          }
          else
            that.props.showMessageBox(x.message);
        })
        .catch(err => {
          that.props.showMessageBox("ERROR");
        });
    }
    else {
      that.props.showMessageBox("Field are required");
    }
  };

  handleReset = () => {
    this.setState({
      user: "",
      access: ""
    });
  };

  handleDelete = () => {
    this.setState({
      openAlert: false
    });
    apiRequest(`/accesscontrol/${this.state.memberToDelete}`, "delete")
      .then(x => {
        if (x.status > 0 && x.message == null) {
          this.fetchMappedUsers();
          this.props.showMessageBox("SUCCESS");
        }
        else
          this.props.showMessageBox(x.message);
      })
      .catch(err => console.log(err));
  };

  render() {
    const { classes } = this.props;
    const tableHead = (
      <thead>
        <tr>
          {tableHeader.map(row => (
            <th className={classes.tableHead} key={row.id}>
              {row.label}
            </th>
          ))}
        </tr>
      </thead>
    );
    return !this.state.hideControls ? (
      <React.Fragment>
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={this.handleDelete}
          action={"Remove"}
          close={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Paper
          style={{
            padding: "10px 10px 0 10px",
            marginLeft: "12px",
            marginRight: "12px",
            marginTop: "25px",
            marginBottom: "20px"
          }}
        >
          <h5 style={{ color: "#4b2e83", marginBottom: 15 }}>
            Assign Access to Users
          </h5>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} lg={5}>
            <Autocomplete
              disableClearable={true}
              id="outlined-select"
              select
              value={this.state.user}
              options={this.state.UnMappeduserData.sort(byPropertyCalled("Label"))}
              getOptionSelected={(option, value) =>
                option.UserId == value.UserId
              }
              getOptionLabel={(user) => user? user.Label : ""}
              renderOption={(user, {inputValue}) => {
                const matches = match(user.Label, inputValue);
                const parts = parse(user.Label, matches);
                return (
                  <div style={{ padding: "8px 2px" }}>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
              onChange={this.handleChangeUser("user")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="User"
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  //InputProps={{ className: classes.input }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label
                  }}
                />
                )}
              />
            </Grid>

            <Grid item xs={12} md={5} lg={5}>
              <TextField
                width="100%"
                id="outlined-select"
                select
                required
                label="Access Level"
                margin="dense"
                className={classes.textField}
                value={this.state.access}
                onChange={this.handleChange("access")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                InputProps={{ className: classes.input }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label
                }}
                variant="outlined"
              >
                {this.state.accessLevels.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              lg={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Tooltip title="Reset">
                <IconButton
                  style={{
                    color: "#4b2e83",
                    opacity: "1",
                    outline: "none"
                  }}
                  aria-label="Reset"
                  onClick={this.handleReset}
                >
                  <ResetIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Create">
                <IconButton
                  style={{
                    color: "#4b2e83",
                    opacity: "1",
                    outline: "none"
                  }}
                  aria-label="Create"
                  onClick={this.handleSubmt}
                >
                  <SubmitIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
        {window.location.pathname.includes("Modify") ? (
          <Paper
            style={{
              padding: "10px 10px 10px 10px",
              margin: "32px 12px"
            }}
          >
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                Administrator
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ display: "block" }}>
                <table
                  style={{ marginBottom: 0 }}
                  className="table table-striped coll-table table-bordered table-condensed"
                >
                  {tableHead}
                  <tbody>
                    {this.state.MappedUserData.filter(
                      item => item.AccessLevel === 500
                    ).map(row => (
                      <tr key={row.MembershipId}>
                        <td
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            verticalAlign: "middle"
                          }}
                        >
                          <img
                          className="image"
                            src={row.ImageUrl}
                            alt="user_img"
                            height="27px"
                            width="27px"
                          />
                        </td>
                        <td className={classes.tableRow}>{row.UserName}</td>
                        <td className={classes.tableRow}>{row.FullName}</td>
                        <td>
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="Delete"
                              className={classes.icons}
                              style={{ padding: 0 }}
                              onClick={() => {
                                this.setState({
                                  memberToDelete: row.MembershipId,
                                  openAlert: true
                                });
                              }}
                            >
                              <DeleteIcon
                                style={{ color: "#4b2e83" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                Read Only
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ display: "block" }}>
                <table
                  style={{ marginBottom: 0 }}
                  className="table table-striped coll-table table-bordered table-condensed"
                >
                  {tableHead}
                  <tbody>
                    {this.state.MappedUserData.filter(
                      item => item.AccessLevel === 100
                    ).map(row => (
                      <tr key={row.MembershipId}>
                        <td
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            verticalAlign: "middle"
                          }}
                        >
                          <img
                          className="image"
                            src={row.ImageUrl}
                            alt="user_img"
                            height="27px"
                            width="27px"
                          />
                        </td>
                        <td className={classes.tableRow}>{row.UserName}</td>
                        <td className={classes.tableRow}>{row.FullName}</td>
                        <td>
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="Delete"
                              style={{ padding: 0 }}
                              className={classes.icons}
                              onClick={() => {
                                this.setState({
                                  memberToDelete: row.MembershipId,
                                  openAlert: true
                                });
                              }}
                            >
                              <DeleteIcon
                                style={{ color: "#4b2e83" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Paper>
        ) : null}
      </React.Fragment>
    ) : (
      <div />
    );
  }
}
const mapStateToProps = state => {
  const { accessLevels } = state;
  return {
    accessLevels
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAccessLevel: () => getAccessLevels(dispatch),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(AssignOrRemoveUsers);
