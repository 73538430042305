import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import "./Configure.css";
import Button from "@material-ui/core/Button";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import GoBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CollectionsIcon from "@material-ui/icons/CollectionsBookmark";
import Battery80Icon from "@material-ui/icons/Battery80";
import Battery20Icon from "@material-ui/icons/Battery20";
import Battery30Icon from "@material-ui/icons/Battery30";
import Battery50Icon from "@material-ui/icons/Battery50";
import Battery60Icon from "@material-ui/icons/Battery60";
import Battery90Icon from "@material-ui/icons/Battery90";
import BatteryLowIcon from "@material-ui/icons/BatteryAlert";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";
import BatteryUnknown from "@material-ui/icons/BatteryUnknown";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ResetIcon from "@material-ui/icons/Refresh";
import { Link } from "react-router-dom";
import {
  getEntity,
  getLocations,
  getCollections,
  getAssets,
  getDeviceFlags,
  cleanData,
  showMessage
} from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiRequest, isPortalAdmin, storeEntityId, getOption, token, byPropertyCalled, formatDate, defaultImage, admintoken, meridianLocationId, byName, getAccess, addLowBatteryHint } from "../utils/Utils";
import AlertDialog from "./AlertDialog";
import Collapse from "@material-ui/core/Collapse";
import CollectionsTable from "./CollectionsTable";
import DeleteIcon from "@material-ui/icons/Delete";
import RefreshIcon from '@material-ui/icons/Refresh';
import ViewIcon from "@material-ui/icons/OpenInNew";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import NoBarcode from "../images/No_Barcode.png";
import * as MeridianSDK from "@meridian/web-sdk";
import './Map.css';

import History from './TableHistory';
import SvgIcon from '@material-ui/core/SvgIcon';

import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Chip from "@material-ui/core/Chip";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";

var Barcode = require("react-barcode");

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    paddingTop: 10,
    paddingBottom: 10
  },
  input2: {
    width: "100%",
    padding: "4px",
    borderRadius: "5px",
    outline: "none",
    border: "1px solid lightgrey"
  },
  input: {
    height: 38
  },
  input3: {
    color: "black !important",
    height: 38
  },
  input1: {
    width: "80%",
    padding: "6px",
    borderRadius: "5px",
    outline: "none",
    border: "1px solid lightgrey",
    height: "40px",
    "&:disabled": {
      backgroundColor: "white"
    }
  },

  label: {
    //marginTop: "-5px"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    flexGrow: 1
  },
  root2: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  textField: { width: "100%" },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  tableHead: {
    fontWeight: 400,
    fontSize: 14,
    fontWeight: 500,
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.54)"
  },
  tableRow: {
    paddingTop: 0,
    paddingBottom: 0,
    verticalAlign: "middle",
    fontWeight: 400,
    fontSize: "0.8125rem",
    color: "rgba(0, 0, 0, 0.87)"
  },
  progress: {
    color: "#4b2e83"
  },
  appBar: {
    position: "relative",
    backgroundColor: "#4b2e83"
  },
  title: {
    marginLeft: 10,
    flex: 1,
    color: "white"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Configure extends React.Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.lastKnownModified = "";
    this.numberOfFailures = 0;
    this.replacements = [];
    this.state = {
      isSubmitting: false,
      created: "2019-05-08 11:30:03.727",
      modified: "2019-05-010 09:15:02.233",
      savebuttondisabled: true,
      deletebuttondisabled: true,
      backbtnvisible: true,
      isEditable: false,
      isDeleteAllowed: false,
      canRetireDevice: false,
      manufacturerData: [],
      modelData: [],
      typeData: [],
      flagData: [],
      EntityId: "",
      //MacId: props.selectedAssetDetail.MacId,
      MacId: this.props.match.params.id,
      Manufacturer: null,
      DeviceType: null,
      DeviceModel: null,
      DisplayName: "",
      DeviceFlag: "",
      Description: "",
      DeviceStatus: "",
      manufacturerId: "",
      ExternalId: "",
      SerialNumber: "",
      data: [],
      hideMap: false,
      openAlert: false,
      actionType: "",
      openAddColection: false,
      openAddDepartment: false,
      collectiondata: [],
      showCollectionTable: true,
      selectedCollection: {},
      newCollection: "",
      newDepartment: "",
      departmentdata: [],
      deviceToDelete: {},
      loaded: false,
      message: "",
      orphanedDevices: [],
      showOrphanedList: false,
      showRemoveEntityButton: false,
      selectedCollType: "",
      meridiandata: [],
      Name: "",
      isNameChanged: false,
      isCurrentlySyncing: false,
      index: 0,
      tab: 0,
      currentZone: "",
      isFullScreen: false,
      searchText: "",

      replaceRadio: "delete", //delete vs rename
      deleteRadio: "hard", //hard vs soft
      isReplaceInProgress: false,

      fileImage: null,

      addDepartmentClicked: false,
      addCollectionClicked: false,

      type: "All",
      rowsPerPage: 10,
      page: 0
    };
  }

  inputChange = name => (e, value) => {
    this.setState(
      (name === "Manufacturer") ? { [name]: value, DeviceModel: null } : { [name]: value },
      () => {
        if (name === "Manufacturer") {
          let id = "";
          for (let i = 0; i < this.state.manufacturerData.length; i++) {
            if (this.state.manufacturerData[i].name === value/*.name*/) {
              id = this.state.manufacturerData[i].id;
            }
          }
          if (id === "")
            this.setState({ modelData: [], DeviceModel: null });
          else
            this.fetchData("assetmodels", id);

          this.setState({
            manufacturerId: id
          });
        }
      }
    );
  };

  handleAdd(name) {
    const that = this;
    if (name === "Manufacturer") {
      const { Manufacturer } = this.state;
      if (Manufacturer == "" || Manufacturer == null) {
        this.props.showMessageBox(
          "Please select from list or add new Manufacturer"
        );
        return;
      }

      apiRequest(`/masters/addmanufactures?name=${Manufacturer}`, "post")
        .then(x => {
          that.fetchData("manufactures");
          if (x.status > 0) {
            that.setState({
              manufacturerId: x.data[0]
            });
          }
          if (x.status > 0 && x.message == null)
            that.props.showMessageBox("SUCCESS");
          else
            that.props.showMessageBox(x.message);
        })
        .catch(err => {
          that.props.showMessageBox("ERROR");
        });
    } else if (name === "Model") {
      const { DeviceModel, manufacturerId, Manufacturer } = this.state;
      if (Manufacturer == "" || Manufacturer == null) {
        this.props.showMessageBox("Please select Manufacturer first");
        return;
      }
      if (DeviceModel == "" || DeviceModel == null) {
        this.props.showMessageBox("Please select from list or add new Model");
        return;
      }
      let id = manufacturerId;
      if (manufacturerId == "") {
        for (let i = 0; i < this.state.manufacturerData.length; i++) {
          if (this.state.manufacturerData[i].name === Manufacturer) {
            id = this.state.manufacturerData[i].id;
          }
        }
      }
      apiRequest(
        `/masters/addassetmodels?manfactureId=${id}&model=${DeviceModel}`,
        "post"
      )
        .then(x => {
          if (x.status > 0) that.fetchData("assetmodels", manufacturerId);

          if (x.status > 0 && x.message == null)
            that.props.showMessageBox("SUCCESS");
          else
            that.props.showMessageBox(x.message);
        })
        .catch(err => {
          that.props.showMessageBox("ERROR");
        });
    } else if (name === "Type") {
      const { DeviceType } = this.state;
      if (DeviceType == "" || DeviceType == null) {
        this.props.showMessageBox("Please select from list or add new Type");
        return;
      }
      apiRequest(`/masters/addassettypes?name=${DeviceType}`, "post")
        .then(x => {
          if (x.status > 0) that.fetchData("assettypes");

          if (x.status > 0 && x.message == null)
            that.props.showMessageBox("SUCCESS");
          else
            that.props.showMessageBox(x.message);
        })
        .catch(err => {
          that.props.showMessageBox("ERROR");
        });
    }
  }

  renderMap(mapID = this.state.meridiandata.map.id) {
    if (mapID) {
      let isCurrent = this.state.index == 0 ? true : false;
      let point = !isCurrent ? [{
        type: "point",
        x: this.state.data.X,
        y: this.state.data.Y,
        // x: 100,
        // y: 100,
        backgroundColor: /*"#c00"*/"none",
        //size: 25,
        size: 100,
        backgroundImage: '/locationmarker.gif',
        title: "Last Known"
      }] : [];
      const that = this;
      if (!this.map) {
        const api = new MeridianSDK.API({
          environment: "production",
          token: token
        });
        let element = this.state.isFullScreen ? document.getElementById("meridian-map-full") : document.getElementById("meridian-map");
        this.map = MeridianSDK.createMap(
          element,//document.getElementById("meridian-map"),
          {
            api: api,
            locationID: this.state.meridiandata.location || this.state.data.MeridianLocationId,
            floorID: mapID, //this.state.meridiandata.map.id,
            shouldMapPanZoom: function restrictedPanZoom(event) {
              if (event.type === "wheel" && !event.altKey) {
                return false;
              } else if (event.type === "touchstart") {
                return event.touches.length >= 2;
              }
              return true;
            },
            annotations: point,
            //height: "500px",
            height: this.state.isFullScreen ? "calc(100vh - 145px)" : "500px",
            placemarks: {
              labelZoomLevel: 0.1,
              filter: t => {
                return true;
              }
            },

            onPlacemarksUpdate: ({ allPlacemarks, filteredPlacemarks }) => {
              this.map.update({
                overlays: filteredPlacemarks
                  .filter(placemark => placemark.area)
                  .map((placemark, i) => {
                    const hue = (i * (360 / 6)) % 360;
                    return {
                      type: "polygon",
                      points: MeridianSDK.pointsFromArea(placemark.area),
                      //points: [3032,629],
                      fill: `hsla(${hue}, 100%, 60%, 0.3)`,
                      stroke: `hsl(${hue}, 100%, 60%)`
                    };
                  })
              });
            },

            loadTags: isCurrent,
            tags: {
              //filter: function(tag) {
              //  return isCurrent && tag.mac === that.state.data.MacId;
              filter: (tag) => {
                if (tag.mac === that.state.data.MacId) {
                  addLowBatteryHint(tag);
                  return true;
                }
                return false;
              },
              updateInterval: 10000
            }
          }
        );
      } else {
        this.map.update({
          locationID: this.state.meridiandata.location || this.state.data.MeridianLocationId,
          floorID: mapID, //this.state.meridiandata.map.id,
          annotations: point,
          loadTags: isCurrent,
          tags: {
            filter: (tag) => {
              if (tag.mac === that.state.data.MacId) {
                addLowBatteryHint(tag);
                return true;
              }
              return false;
            },
            updateInterval: 10000
          }
        });
      }
    }
  }

  componentDidMount() {
    this.props.getAsset();
    //this.props.getLocation(this.state.EntityId);
    //if (!isPortalAdmin)
      //this.getOrphanDevices();

    let that = this;

    apiRequest(
      `/accesscontrol?resourceType=tag&resourceId=${
        this.props.match.params.id
      }`,
      "get"
    )
      .then(x => {
        if (x.data[0] == 500 || x.data[0] == 700) {
          this.setState({
            isDeleteAllowed: true,
            canRetireDevice: true,
            isEditable: true
          });
        } else if (x.data[0] == 300) {
          this.setState({
            isDeleteAllowed: false,
            canRetireDevice: false,
            isEditable: true
          });
        } else {
          this.setState({
            isDeleteAllowed: false,
            canRetireDevice: false,
            isEditable: false
          });
        }
      })
      .catch(err => {
        console.log(err);
      });

    if (
      !this.state.data.MeridianLocationId ||
      !this.state.data.MeridianMapId
    ) {
      that.setState({
        hideMap: true
      });
    } else {
      //meridian get
      //if (that.props.selectedAssetDetail.DeviceFlag !== "NOT_TRACEABLE")
      that.getAssetFromMeridian(this.state.data.MeridianLocationId,this.state.data.MacId);
      // var url = `https://edit.meridianapps.com/api/locations/${that.props.selectedAssetDetail.MeridianLocationId}/asset-beacons/` + that.props.selectedAssetDetail.MacId;
      // fetch(url, getOption())
      //   .then(response => {
      //     if (response.ok) {
      //       return response.json();
      //     } else {
      //       this.setState({ hideMap: true });
      //     }
      //   })
      //   .then(data => {
      //     if (data && data.location && data.map.id) {
      //       console.log("meridiandata", data);
      //       var date = new Date();
      //       var strDate =
      //       date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2)
      //       + "-" + ("00" + date.getDate()).slice(-2)
      //       + " "
      //       + ("00" + date.getHours()).slice(-2) + ":"
      //       + ("00" + date.getMinutes()).slice(-2)
      //       + ":" + ("00" + date.getSeconds()).slice(-2);
      //     that.setState(
      //       {
      //         hideMap: false,
      //         meridiandata: data,
      //         syncTime: strDate,
      //       },
      //       () => {
      //         setTimeout(() => {
      //           this.renderMap();
      //         }, 1000);
      //       }
      //     );
      //   }
      //   else {
      //     this.setState({ hideMap: true, syncTime: "Error has occured" });
      //   }
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    }
  }

  getAssetFromMeridian(locationId, assetId) {
    //meridian get
    var url = `https://edit.meridianapps.com/api/locations/${locationId}/asset-beacons/` + assetId;
    fetch(url, getOption())
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          //console.log("meridiandata-not ok");
          //this.setState({ hideMap: true });
          return null;
        }
      })
      .then(data => {
        if (data /*&& data.location && data.map.id*/) {
          var modi = data.modified + "Z";
          var modi_date = new Date(modi);
          var date = new Date(data.last_heard * 1000);
          var strDate = formatDate(date);
          if (this.lastKnownModified == "")
            this.lastKnownModified = strDate;
          console.log("meridiandata", data, strDate,formatDate(modi_date) );
          // date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2)
          // + "-" + ("00" + date.getDate()).slice(-2)
          // + " "
          // + ("00" + date.getHours()).slice(-2) + ":"
          // + ("00" + date.getMinutes()).slice(-2)
          // + ":" + ("00" + date.getSeconds()).slice(-2);

          //GET ZONE
          if (data.map.id) {
            let zoneUrl = `https://edit.meridianapps.com/api/locations/${locationId}/tag-zones?map_id=${data.map.id}`;
            //let zoneUrl = `https://edit.meridianapps.com/api/locations/${locationId}/tag-zones?tag_id=${this.props.selectedAssetDetail.MacId}`;
            fetch(zoneUrl, getOption())
              .then(response => {
                if (response.ok) {
                  return response.json();
                } else {
                  //NO ZONE
                }
              })
              .then(data => {
                //this.setState({ zone: data });
                let isZoneFound = false;
                data.results.forEach(zone => {
                  //console.log("currentZone", zone);
                  if (zone.tag_ids.includes(this.state.data.MacId)) {
                    this.setState({ currentZone: zone.name });
                    isZoneFound = true;
                    return;
                  }
                });
                if (!isZoneFound) {
                  this.setState({ currentZone: "No Zone" });
                }
              })
              .catch(error => this.setState({ currentZone: "Zone Error" }));
          }

          this.setState(
            {
              hideMap: false,
              meridiandata: data,
              syncTime: strDate,
              isCurrentlySyncing: false
            },
            () => {
              setTimeout(() => {
                if (document.getElementById("meridian-map"))
                  this.renderMap();
              }, 1000);
            }
          );
        }
        else {
          this.setState({ hideMap: true, syncTime: "Error has occured" });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleBackButton = () => {
    //this.props.data("false");
    if (this.props.location.state && this.props.location.state.selectedAssetDetail)
      this.props.history.goBack();
    else
      this.props.history.push("/devices/viewtable");
  };

  handleChange = name => event => {
    this.setState(
      {
        [name]: event.target.value
      },
      () => {
        if (this.state.message != "" && name == "newDepartment") {
          this.setState({
            message: ""
          });
        }
        if (name === "Manufacturer") {
          let id = "";
          for (let i = 0; i < this.state.manufacturerData.length; i++) {
            if (this.state.manufacturerData[i].name === event.target.value) {
              id = this.state.manufacturerData[i].id;
            }
          }
          if (id === "")
            this.setState({ modelData: [], DeviceModel: null });
          else
            this.fetchData("assetmodels", id);
        }
        if (name === "Name" ) {
          this.setState({ isNameChanged: true });
        }
      }
    );

    this.setState({ savebuttondisabled: false });
  };

  componentWillMount() {
    this.props.getFlags();
    this.fetchData("manufactures");
    this.fetchData("assettypes");
    this.fetchAssetData(true);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.map && !this.map.isDestroyed &&
      (this.state.tab !== nextState.tab || this.state.index !== nextState.index || this.state.isFullScreen !== nextState.isFullScreen || this.state.hideMap !== nextState.hideMap)
    ) {
      this.map.destroy();
      this.map = null;
    }
    return true;
  }

  componentWillUnmount() {
    if (this.map && !this.map.isDestroyed) {
      this.map.destroy();
    }
  }

  fetchData(dataType, id = "") {
    let url =
      dataType === "assetmodels"
        ? `/masters/${dataType}/${id}`
        : `/masters/${dataType}`;

    apiRequest(url, "get")
      .then(response => {
        // create an array of contacts only with relevant data
        const data = response.data.map(c => {
          return {
            id: c.ID,
            name: c.Value
          };
        });
        switch (dataType) {
          case "manufactures":
            this.setState(
              {
                manufacturerData: data.sort(byPropertyCalled("name"))
              },
              () => {
                let id = "";
                let value = this.state.Manufacturer;

                for (let i = 0; i < this.state.manufacturerData.length; i++) {
                  if (this.state.manufacturerData[i].name === value) {
                    id = this.state.manufacturerData[i].id;
                  }
                }
                if (id === "")
                  this.setState({ modelData: [], DeviceModel: null });
                else
                  this.fetchData("assetmodels", id);
              }
            );
            break;
          case "assetmodels":
            this.setState({
              modelData: data.sort(byPropertyCalled("name"))
            });
            break;
          case "assettypes":
            this.setState({
              typeData: data.sort(byPropertyCalled("name"))
            });
            break;
          default:
            break;
        }
      })
      .catch(error => console.log(error));
  }

  saveChanges() {
    const {
      Manufacturer,
      DeviceType,
      DeviceModel,
      DisplayName,
      DeviceFlag,
      Description,
      ExternalId,
      SerialNumber,
      Name,
      ImageUrl
    } = this.state;

    let data = {
      ...this.state.data,
      Manufacturer,
      DeviceType,
      DeviceModel,
      DisplayName,
      DeviceFlag,
      Description,
      ExternalId,
      SerialNumber,
      Name,
      ImageUrl
    };
    const that = this;
    that.setState({
      isSubmitting: true,
      isEditable: false
    });
    apiRequest(`/assets/${that.props.match.params.id}`, "put", data)
      .then(x => {
        if (x.status > 0) {
          this.props.getAsset();
          this.fetchAssetData();
        }
        if (x.status > 0 && x.message == null) {
          that.props.showMessageBox("SUCCESS");
        } else {
          if (x.message && x.message == "Error : no rows changed") {
            that.props.showMessageBox(x.message);
          } else {
            this.numberOfFailures++;
            throw new Error(x.message);
          }
        }
        that.setState({
          isSubmitting: false,
          isEditable: true
        });
      })
      .catch(err => {
        //that.props.showMessageBox("ERROR");
        if (this.numberOfFailures < 3)
          this.props.showMessageBox("Action failed, please try again.");
        else
          this.props.showMessageBox("Action failed, please contact support.");
        that.setState({
          isSubmitting: false,
          isEditable: true
        });
      });
  }

  saveData = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );

    console.log("namechange:", this.state.isNameChanged);
    if (true) { //Meridian Patch is handled by the backend
      if (this.state.fileImage == null) {
        this.saveChanges();
      } else {
        //img upload handled by frontend, so response img url can be saved in our db
        let fm = new FormData();
        fm.append('upload', this.state.fileImage);
        var url = `https://edit.meridianapps.com/api/locations/${meridianLocationId}/asset-beacons/${this.props.match.params.id}/image`;
        fetch(url, {
          method: "PUT",
          headers: {
            'Authorization': `Token ${admintoken}`
          },
          body: fm
        })
          .then(response => {
            if (response.ok)
              return response.json();
            return Promise.reject(response);
          })
          .then(r => this.setState({ ImageUrl: r.public_url }, () => this.saveChanges() ))
          .catch(error => this.setState({ fileImage: null }, () => this.props.showMessageBox("Error: Image upload failed")))
      }
    }
    else {
      //Meridian Patch Rest api, if its ok, our db can be updated
      var meridianName = {name: this.state.Name};
      var options = getOption('patch');
      options.body = JSON.stringify(meridianName);
      console.log("opt", options);
      this.setState({
        isSubmitting: true,
        isEditable: false
      });
      //Make meridian call invalid if env is localhost or dev & name includes "apgo"
      let fake = ((document.URL.includes("dev")|| document.URL.includes("localhost")) && this.state.Name.toLocaleLowerCase().includes("apgo")) ? "fake" : "";
      var url =`https://edit.meridianapps.com/api/locations/${this.state.data.MeridianLocationId}/asset-beacons/${this.state.data.MacId + fake}`;
      fetch(url, options)
        .then(response => {
          if (response.ok) {
            //Save changes in our db
            this.saveChanges();
            this.numberOfFailures = 0;
          } else {
            //no save
            console.log("fail");
            this.numberOfFailures++;
          }
          this.setState({
            isSubmitting: false,
            isEditable: true,
            //isNameChanged: false
          });
          console.log("resp", response);
          return response.json();
        })
        // .then(data => {
        //   this.props.showMessageBox(data.detail)
        //   return data;
        // })
        .catch(err => {
          console.log("errorResp:", err);
          if (this.numberOfFailures < 3)
            this.props.showMessageBox("Action failed, please try again.");
          else
            this.props.showMessageBox("Action failed, please contact support.");
          this.setState({
            isSubmitting: false,
            isEditable: true,
            //isNameChanged: false
          });
        });
    }
  };

  handleRemoveFromEntity = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );
    const that = this;
    const data = {
      entityId: that.state.EntityId,
      mac: [that.state.MacId]
    };

    apiRequest(`/assets/ownership`, "delete", data)
      .then(x => {
        if (x.status > 0) {
          that.handleBackButton();
          that.props.getAsset();
        }
        if (x.status > 0 && x.message == null)
          that.props.showMessageBox("SUCCESS");
        else
          that.props.showMessageBox(x.message);
      })
      .catch(err => {
        that.setState({
          isSubmitting: false,
          isEditable: true
        });
      });
  };

  handleRetire = () => {
    const that = this;
    that.setState({
      isSubmitting: true,
      isEditable: false
    });

    apiRequest(
      `/assets/RetireDevice/${that.props.match.params.id}`,
      "post"
    )
      .then(x => {
        that.setState({
          isSubmitting: false,
          isEditable: true
        });
        if (x.status > 0) {
          that.handleBackButton();
          that.props.getAsset();
        }
        if (x.status > 0 && x.message == null)
          that.props.showMessageBox("SUCCESS");
        else
          that.props.showMessageBox(x.message);
      })
      .catch(err => {
        that.setState({
          isSubmitting: false,
          isEditable: true
        });
      });
  };

  handleSoftDelete = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );
    const that = this;
    that.setState({
      isSubmitting: true,
      isEditable: false
    });

    apiRequest(`/assets/${that.props.match.params.id}`, "delete")
      .then(x => {
        if (x.status > 0) {
          that.handleBackButton();
          that.props.getAsset();
        }
        that.setState({
          isSubmitting: false,
          isEditable: true
        });
        if (x.status > 0 && x.message == null)
          that.props.showMessageBox("SUCCESS");
        else
          that.props.showMessageBox(x.message);
      })
      .catch(err => {
        that.setState({
          isSubmitting: false,
          isEditable: true
        });
      });
  };

  handleHardDelete = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );
    const that = this;
    that.setState({
      isSubmitting: true,
      isEditable: false
    });

    apiRequest(`/assets/hard/${that.props.match.params.id}`, "delete")
      .then(x => {
        if (x.status > 0) {
          that.handleBackButton();
          that.props.getAsset();
        }
        that.setState({
          isSubmitting: false,
          isEditable: true
        });
        if (x.status > 0 && x.message == null)
          that.props.showMessageBox("SUCCESS");
        else
          that.props.showMessageBox(x.message);
      })
      .catch(err => {
        that.setState({
          isSubmitting: false,
          isEditable: true
        });
      });
  };

  componentWillReceiveProps({ deviceFlags, collections, assets }) {
    this.setState({
      flagData: deviceFlags,
      collectiondata: collections
    });
    if (assets && assets.length > 0 && !this.inProgress) {
      this.inProgress = true;
      if (isPortalAdmin) {
        this.replacements = assets.filter(a => a.MacId != this.props.match.params.id);
      }
      else {
        apiRequest(`/assets/replace`, "get")
        .then(res => {
          this.setState({
            orphanedDevices: res.data
          }, () => {
            let access = getAccess();
            if (access.some(a => a.resourceType == "department" && a.access_level == 500)) {
              apiRequest(`/assets/replacements`, "get")
              .then(resp => {
                let adminDepartments = access.reduce((res, a) => (a.resourceType == "department" && a.access_level == 500) ? res.push({locationdId: a.location_id, deptName: a.collection_name, deptId: a.resourceId})&& res : res, []);
                //console.log("azi", resp.data.find(e => !assets.map(a => a.MacId).includes(e)));
                this.replacements = assets.filter(o => resp.data.includes(o.MacId) && o.MacId != this.props.match.params.id && (o.LocationId && o.LocationId > 0 && adminDepartments.some(ad => ad.locationdId == o.LocationId && ad.deptName == o.DepartmentName))).concat(this.state.orphanedDevices);
                this.setState({ update: true });
              })
              .catch(err => {
                this.props.showMessageBox("ERROR");
              });
            }
            else {
              this.replacements = this.state.orphanedDevices;
            }
            //this.replacements = this.props.assets.concat(this.state.orphanedDevices)/*.filter(d => this.filterByName(d.Name) && this.filterDepartment(d))*/;
          });
        })
        .catch(err => {
          this.props.showMessageBox("ERROR");
        });
      }
    }
       //this.replacements = assets.filter(a => (a.LocationId && a.LocationId > 0) || a.EntityId == null ).concat(this.state.orphanedDevices)/*.filter(d => this.filterByName(d.Name) && this.filterDepartment(d))*/;
  }

  handleScroll = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  fetchAssetData(first = false) {
    apiRequest(`/assets/${this.state.MacId}`, "get")
      .then(x => {
        this.setState(
          {
            data: x.data[0],
            EntityId: x.data[0].EntityId,
            MacId: x.data[0].MacId,
            Manufacturer: x.data[0].Manufacturer == "" ? null : x.data[0].Manufacturer,
            DeviceType: x.data[0].DeviceType == "" ? null : x.data[0].DeviceType,
            DeviceModel: x.data[0].DeviceModel == "" ? null : x.data[0].DeviceModel,
            DisplayName: x.data[0].DisplayName,
            DeviceFlag: x.data[0].DeviceFlag,
            Description: x.data[0].Description,
            DeviceStatus: x.data[0].DeviceStatus,
            ExternalId: x.data[0].ExternalId,
            SerialNumber: x.data[0].SerialNumber,
            Name: x.data[0].Name,
            loaded: true
          },
          () => {
            if (first && x.data[0].EntityId) {
              this.props.getLocation(x.data[0].EntityId);
            }
            if (first && x.data[0].ImageUrl) {
              fetch(x.data[0].ImageUrl)
              .then(r => {
                if (r.status != 200)
                  this.setState(prevState => ({
                    data: {
                        ...prevState.data,
                        ImageUrl: defaultImage
                    }
                }))
              })
              .catch(err =>
                this.setState(prevState => ({
                  data: {
                      ...prevState.data,
                      ImageUrl: defaultImage
                  }
              })))
            }
            /////////////Map//////
            this.getAssetFromMeridian(this.state.data.MeridianLocationId,this.state.data.MacId);
            /////////////////////
            this.checkEntityAccess();
            if (x.data[0].EntityId) {
              apiRequest(
                `/entities/${x.data[0].EntityId}/locations/0/collections`,
                "get"
              )
                .then(res => {
                  this.setState({
                    departmentdata: res.data
                  });
                })
                .catch(err => console.log(err));
              this.props.getCollection(x.data[0].EntityId);
            }
            let id = "";
            let value = this.state.Manufacturer;
            for (let i = 0; i < this.state.manufacturerData.length; i++) {
              if (this.state.manufacturerData[i].name === value) {
                id = this.state.manufacturerData[i].id;
              }
            }
            if (id === "")
              this.setState({ modelData: [], DeviceModel: null });
            else
              this.fetchData("assetmodels", id);
          }
        );
      })
      .catch(err => {
        console.log(err);
      });
  }

  showCollection(coll_id) {
    this.setState({
      showCollectionTable: false
    });

    apiRequest(`/assetmaps/collections/${coll_id}`, "get")
      .then(response => {
        if (response.status > 0) {
          this.setState(
            {
              selectedCollection: response.data.length > 0 ? response.data : ""
            },
            () => {
              storeEntityId(response.data[0].EntityId);
            }
          );
        }
      })
      .catch(error => console.log(error));
  }

  addCollection = () => {
    if (this.state.newCollection == "") {
      this.props.showMessageBox("Please select Collection");
      return;
    }

    this.setState({ addCollectionClicked: true }, () => {
      let element = this.state.collectiondata.find(c => c.name == this.state.newCollection);
      if (element) {
        const data = {
          mac: [this.state.data.MacId],
          CollectionId: element.id
        };
        apiRequest("/assetmaps", "post", data)
          .then(x => {
            if (x.status > 0) {
              this.fetchAssetData();
            }
            if (x.status > 0 && x.message == null)
              this.props.showMessageBox("SUCCESS");
            else {
              this.props.showMessageBox(x.message);
            }
            this.setState({ addCollectionClicked: false });
          })
          .catch(err => {
            this.props.showMessageBox("ERROR");
          });
      }
      else {
        const data = {
          EntityId: this.state.data.EntityId,
          CollectionName: this.state.newCollection,
          CollectionType: "User Defined Collection",
          Description: "",
          CollectionTypeId: 2
        };
        let method = "post";
        let url = `/entities/${this.state.data.EntityId}/locations/0/collections`;

        apiRequest(url, method, data)
          .then(x => {
            if (x.status == 0) {
              this.setState({ addCollectionClicked: false });
              this.props.showMessageBox(x.message);
              return;
            }
            else {
              const data = {
                mac: [this.state.data.MacId],
                CollectionId: x.data[0]
              };
              apiRequest("/assetmaps", "post", data)
                .then(x => {
                  if (x.status > 0) {
                    this.fetchAssetData();
                  }
                  if (x.status > 0 && x.message == null)
                    this.props.showMessageBox("SUCCESS");
                  else {
                    this.props.showMessageBox(x.message);
                  }
                  this.setState({ addCollectionClicked: false });
                })
                .catch(err => {
                  this.props.showMessageBox("ERROR");
                });
            }
          })
          .catch(err => {
            this.props.showMessageBox("ERROR");
          });
      }
    });
  };

  addDepartment = () => {
    this.setState({ addDepartmentClicked: true }, () => {
      let element = this.state.departmentdata.find(d => d.CollectionName == this.state.newDepartment )
      if (element) {
        const data = {
          mac: [this.state.data.MacId],
          CollectionId: element.CollectionId
        };
        apiRequest("/assetmaps", "post", data)
          .then(x => {
            if (x.status > 0) {
              this.fetchAssetData();
            }
            if (x.status > 0 && x.message == null)
              this.props.showMessageBox("SUCCESS");
            else {
              this.props.showMessageBox(x.message);
            }
            this.setState({ addDepartmentClicked: false });
          })
          .catch(err => {
            this.props.showMessageBox("ERROR");
          });
        return;
      }
      else {
        this.setState({
          addDepartmentClicked: false,
          message:
            "*Either the Department you are looking for does not exist or you don't have access to that department. Consult Organization admin."
        });
      }
    })
  };

  handleRemoveFromCollection = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );

    apiRequest(`/assetmaps`, "delete", {
      ids: [this.state.deviceToDelete.AssetMapId]
    })
      .then(x => {
        if (x.status > 0) {
          this.showCollection(this.state.deviceToDelete.CollectionId);
        }
        if (x.status > 0 && x.message == null)
          this.props.showMessageBox("SUCCESS");
        else
          this.props.showMessageBox(x.message);
      })
      .catch(err => this.props.showMessageBox("ERROR"));
  };

  handleReplace = () => {
    this.setState(
      {
        openAlert: false,
        isReplaceInProgress: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );
    apiRequest(
      `/assets/${this.state.data.MacId}/replace/${
        this.state.deviceToReplaceWith
      }`,
      "post"
    )
      .then(res => {
        if (res.status > 0) {
          // this.setState(
          //   {
          //     MacId: this.state.deviceToReplaceWith
          //   },
          //   () => {
          //     this.fetchAssetData();
          //   }
          // );
          // this.props.getAsset();
          //this.setState({ isReplaceInProgress: false });
          this.props.history.replace("/asset/" + this.state.deviceToReplaceWith);
          window.location.href = window.location.href;
        }

        this.setState({
          showOrphanedList: false,
          isReplaceInProgress: false
        });
        if (res.status == 0)
          this.props.showMessageBox(res.message);
        else
          this.props.showMessageBox("SUCCESS");
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
  };

  handleReplaceAndDelete = () => {
    this.setState(
      {
        openAlert: false,
        isReplaceInProgress: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );
    apiRequest(
      `/assets/${this.state.data.MacId}/replacedelete/${
        this.state.deviceToReplaceWith
      }`,
      "post"
    )
      .then(res => {
        if (res.status > 0) {
          // this.setState(
          //   {
          //     MacId: this.state.deviceToReplaceWith
          //   },
          //   () => {
          //     this.fetchAssetData();
          //   }
          // );
          // this.props.getAsset();
          this.props.history.replace("/asset/" + this.state.deviceToReplaceWith);
          window.location.href = window.location.href;
        }

        this.setState({
          showOrphanedList: false,
          isReplaceInProgress: false
        });
        if (res.status == 0)
          this.props.showMessageBox(res.message);
        else
          this.props.showMessageBox("SUCCESS");
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
  };

  getOrphanDevices = () => {
    apiRequest(`/assets/replace`, "get")
      .then(res => {
        this.setState({
          orphanedDevices: res.data
        }, () => {
          apiRequest(`/assets/replacements`, "get")
          .then(resp => {
            this.replacements = this.props.assets.filter(o => resp.data.includes(o.MacId)).concat(this.state.orphanedDevices)
          })
          .catch(err => {
            this.props.showMessageBox("ERROR");
          });


          //this.replacements = this.props.assets.concat(this.state.orphanedDevices)/*.filter(d => this.filterByName(d.Name) && this.filterDepartment(d))*/;
        });
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
  };

  getEditableDevices = () => {
    apiRequest(`/assets/replacements`, "get")
      .then(res => {
        this.setState({
          orphanedDevices: res.data
        }, () => {
          this.replacements = this.props.assets.concat(this.state.orphanedDevices)/*.filter(d => this.filterByName(d.Name) && this.filterDepartment(d))*/;
        });
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
  };

  handleClickOpen = () => {
    this.setState({
      showOrphanedList: true
    });
  };

  handleClose = () => {
    this.setState({
      showOrphanedList: false
    });
  };

  checkEntityAccess() {
    apiRequest(
      `/accesscontrol?resourceType=entity&resourceId=${this.state.EntityId}`,
      "get"
    )
      .then(response => {
        if (response.data[0] == 700 || response.data[0] == 500) {
          this.setState({
            showRemoveEntityButton: true
          });
        } else {
          this.setState({
            showRemoveEntityButton: false
          });
        }
      })
      .catch(error => console.log("error", error));
  }

  filterByName(value) {
    if (this.state.searchText == "") {
      return true;
    } else {
      if (value.toLowerCase().includes(this.state.searchText.toLowerCase()))
        return true;
      return false;
    }
  }

  filterDepartment(value) {
    if (this.state.dept) {
      return value.hasOwnProperty("DepartmentName") && value.DepartmentName == this.state.dept.name;
    } else if (this.state.campus) {
      return value.hasOwnProperty("LocationName") && value.LocationName == this.state.campus.name;
    }
    return true;
  }

  filterType(value) {
    if (this.state.type == "Department")
      return value.EntityId != null && value.hasOwnProperty("DepartmentName") && value.DepartmentName;
    else if (this.state.type == "Orphan")
      return value.EntityId == null;
    else
      return true;
  }

  replaceDeleteRadio() {
    return(
      <div>
        Replace and Delete<br/>
        This will replace the current tag by inheriting it's attributes and deleting the original tag from the system
      </div>
    );
  }

  replaceRenameRadio() {
    return(
      <div>
        Replace<br/>
        This will replace the current tag and strip the original tag of it's attributes. The original tag name will be appended with "Replaced" in meridian
      </div>
    );
  }

  hardDeleteRadio() {
    return(
      <div>
        Delete Asset & Tag<br/>
        This will delete the current asset and associated tag information from the system.
      </div>
    );
  }

  softDeleteRadio() {
    return(
      <div>
        Delete Asset<br/>
        This will delete the current asset. However, the associated tag will remain in Meridian.
      </div>
    );
  }

  fileChangedHandler = (event) => {
    const fileImage = event.target.files[0];
    if (fileImage != undefined) {
      if (fileImage.size > 1024 * 1024 * 2) {
        this.setState({ updated: true });
        this.props.showMessageBox("File too large: " + Math.round((fileImage.size / 1024 / 1024) * 100) / 100 + "MB");
      }
      else {
        this.setState({ fileImage: fileImage });
      }
    }
  }

  getFirstAssignDate() {
    if (this.state.data && this.state.data.Collections && this.state.data.Collections.length> 0) {
      let entry = this.state.data.Collections.reduce((acc, val) => acc.Created < val.Created ? acc : val);
      return entry.Created ? formatDate(new Date(entry.Created)) : "Not a Date";
    }
    else
      return "Unassigned";
  }

  getLastAssignDate() {
    if (this.state.data && this.state.data.Collections && this.state.data.Collections.length> 0) {
      let entry = this.state.data.Collections.reduce((acc, val) => acc.Created > val.Created ? acc : val);
      return entry.Created ? formatDate(new Date(entry.Created)) : "Not a Date";
    }
    else
      return "Unassigned";
  }

  handleChangeRowsPerPage = event => {
    if (event.target.value * this.state.page > this.replacements.filter(d => this.filterByName(d.Name) && this.filterType(d) && this.filterDepartment(d)).length && this.state.page != 0)
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    else
      this.setState({ rowsPerPage: event.target.value });
  }

  render() {
    //console.log("propi", this.props, this.state);
    //console.log("rnr", this.state.currentZone);
    let styles= [
      {
        cursor: "pointer",
        color: "#4b2e83",
        textAlign: "center",
        borderBottom: "2px solid #4b2e83",
      },
      {
        cursor: "pointer",
        color: "lightgrey",
        textAlign: "center",
        borderBottom: "2px solid lightgrey",
      }
    ,];
    const { classes } = this.props;
    const {
      EntityId,
      EntityName,
      LocationId,
      LocationName,
      CollectionId,
      CollectionName,
      DeviceId,
      MacId,
      Name,
      DisplayName,
      Description,
      TagCode,
      DeviceFlag,
      BatteryLevel,
      FloorLabel,
      FloorNumber,
      Manufacturer,
      DeviceType,
      DeviceModel,
      DeviceStatus,
      IsActive,
      ImageUrl,
      MeridianBuildingName,
      ExternalId,
      SerialNumber,
      Timestamp,
      MeridianCreated,
      MeridianModified,
      FirstAssignment,
      LastAssignment
    } = this.state.data;

    const {
      manufacturerData,
      modelData,
      typeData,
      collectiondata,
      selectedCollection,
      orphanedDevices,
      showOrphanedList
    } = this.state;

    const dept = this.state.data.Collections
      ? this.state.data.Collections.filter(
          item => item.CollectionType == "Department"
        )
      : "";

    const date = new Date(`${this.props.lastSync}Z`);
    var lastKnownSync = formatDate(date);

    return (
      <React.Fragment>
        {this.state.loaded && this.state.data ? (
          <div style={{ height: "100%", width: "100%", paddingLeft: "15px", paddingRight: "15px" }}>
            <Dialog
              fullScreen
              open={showOrphanedList}
              onClose={this.handleClose}
              TransitionComponent={Transition}
            >
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={this.handleClose}
                    aria-label="Close"
                    disabled={this.state.isReplaceInProgress}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Select Device to Replace with
                  </Typography>
                </Toolbar>
              </AppBar>

              {this.state.isReplaceInProgress ?
                <React.Fragment>
                  <div
                    style={{
                      width: "100%",
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      //hidden={!this.state.isSubmitting}
                      className={classes.progress}
                      style={{ color: "#4b2e83", width: "80px", height: "80px" }}
                    />
                  </div>
                </React.Fragment>
              :
                <React.Fragment>
                  <Grid
                    container
                    //spacing={2}
                    style={{ width: "85%", display: "flex", alignItems: "center", justifyContent: "center", alignSelf: "center" }}
                  >
                    <Grid item xs={4} style={{ padding: "8px 8px 2px 0px" }}>
                      <TextField
                        style={{
                          width: "100%",
                          // paddingLeft: 30,
                          // paddingRight: 10,
                          paddingBottom: "1px",
                        }}
                        value={this.state.searchText}
                        margin="dense"
                        placeholder="Search"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment>
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          className: classes.input,
                        }}
                        onChange={evt => this.setState({ searchText: evt.target.value, page: 0 })}
                      />
                    </Grid>
                    <Grid item xs={2} style={{ padding: "8px 8px 2px 0px" }}>
                      <Autocomplete
                        id="type"
                        value={this.state.type}
                        options={["All", "Department", "Orphan"]}
                        getOptionSelected={(option, value) =>
                          option == value
                        }
                        getOptionLabel={(option) => option}
                        onChange={(evt, value) => {
                          if (value)
                            this.setState({ type: value, page: 0 });
                          else
                            this.setState({ type: "All", page: 0 });
                        }}
                        renderOption={(option) => (
                          <div style={{ padding: "8px 2px" }}>{option}</div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Type"
                            variant="outlined"
                            margin="dense"
                            className={classes.textField}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3} style={{ padding: "8px 8px 2px 0px" }}>
                      <Autocomplete
                        id="building"
                        value={this.state.campus ? this.props.locations.find(d => d.id == this.state.campus.id) : null}
                        options={this.props.locations.sort(byName)}
                        getOptionSelected={(option, value) =>
                          option.id == value.id
                        }
                        getOptionLabel={(option) => option.name}
                        onChange={(evt, value) => {
                          if (value)
                            this.props.getCollection(value.entityId, value.id);
                          this.setState({ dept: null, campus: value ? value : null, page: 0 });
                          }
                        }
                        renderOption={(option) => (
                          <div style={{ padding: "8px 2px" }}>{option.name}</div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Assigned Campus"
                            variant="outlined"
                            margin="dense"
                            className={classes.textField}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3} style={{ padding: "8px 0px 2px 0px" }}>
                      <Autocomplete
                        disabled={!this.state.campus}
                        id="dept"
                        value={this.state.dept ? this.props.collections.find(d => d.id == this.state.dept.id) : null}
                        options={this.props.collections.sort(byName)}
                        getOptionSelected={(option, value) =>
                          option && value ? option.id == value.id : false
                        }
                        getOptionLabel={(option) => option.name}
                        onChange={(evt, value) => {
                          this.setState({ dept: value ? value : null, page: 0 });
                        }}
                        renderOption={(option) => (
                          <div style={{ padding: "8px 2px" }}>{option.name}</div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Department"
                            variant="outlined"
                            margin="dense"
                            className={classes.textField}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <div
                    style={{ width: "85%", margin: "10px auto 0px" }}
                  >
                    <TableContainer>
                      <Table className="table table-striped coll-table table-bordered table-condensed">
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.tableHead}>
                              IMAGE
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                              DEVICE NAME
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                              TYPE
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                              ACTION
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.replacements
                          .filter(d => this.filterByName(d.Name) && this.filterType(d) && this.filterDepartment(d))
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            (this.state.page + 1) * this.state.rowsPerPage
                          )
                          .map((item) => (
                            <TableRow key={item.DeviceId}>
                              <TableCell
                                style={{
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  verticalAlign: "middle",
                                }}
                              >
                                <img
                                  className="image"
                                  style={{ borderRadius: "50%" }}
                                  src={item.ImageUrl ? item.ImageUrl : defaultImage}
                                  height={30}
                                  width={30}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableRow}>
                                {item.Name}
                              </TableCell>
                              <TableCell
                                className={classes.tableRow}>
                                {item.hasOwnProperty("DepartmentName") && item.DepartmentName ? "Department" : item.EntityId ? "" : "Orphan"}
                              </TableCell>
                              <TableCell
                                style={{
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  verticalAlign: "middle",
                                }}
                              >
                                <Button
                                  //className="button3"
                                  style={{
                                    color: "#4b2e83",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      actionType: "REPLACE",
                                      openAlert: true,
                                      deviceToReplaceWith: item.MacId,
                                    });
                                  }}
                                >
                                  REPLACE
                                </Button>
                              </TableCell>
                            </TableRow>))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100, 125, 150]}
                      component="div"
                      count={this.replacements.filter(d => this.filterByName(d.Name) && this.filterType(d) && this.filterDepartment(d)).length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      backIconButtonProps={{
                        "aria-label": "Previous Page",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "Next Page",
                      }}
                      onChangePage={(event, page) => this.setState({ page })}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      style={{ paddingBottom: "20px" }}
                    />
                  </div>

                  {false && <table
                    style={{ width: "85%", margin: "10px auto" }}
                    className="table table-striped coll-table table-bordered table-condensed"
                  >
                    <thead>
                      <tr>
                        <th className={classes.tableHead}>IMAGE</th>
                        <th className={classes.tableHead}>DEVICE NAME</th>
                        {/* <th className={classes.tableHead}>DISPLAY NAME</th> */}
                        <th className={classes.tableHead}>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/*this.props.assets.concat(orphanedDevices).filter(d => this.filterByName(d.Name) && this.filterDepartment(d))*/ this.replacements.slice(0, 10).map((item) => (
                        <tr key={item.MacId}>
                          <td
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              verticalAlign: "middle",
                            }}
                          >
                            <img
                              className="image"
                              style={{ borderRadius: "50%" }}
                              src={item.ImageUrl ? item.ImageUrl : defaultImage}
                              height={30}
                              width={30}
                            />
                          </td>
                          <td className={classes.tableRow}>{item.Name}</td>
                          {/* <td className={classes.tableRow}>{item.DisplayName}</td> */}
                          <td
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              verticalAlign: "middle",
                            }}
                          >
                            <Button
                              //className="button3"
                              style={{
                                color: "#4b2e83",
                              }}
                              onClick={() => {
                                this.setState({
                                  actionType: "REPLACE",
                                  openAlert: true,
                                  deviceToReplaceWith: item.MacId,
                                });
                              }}
                            >
                              REPLACE
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>}
                </React.Fragment>
              }
            </Dialog>

            <AlertDialog
              open={this.state.openAlert}
              onClickYes={
                this.state.actionType == "REMOVE FROM Organization"
                  ? this.handleRemoveFromEntity
                  : this.state.actionType == "SAVE"
                  ? this.saveData
                  : this.state.actionType == "REMOVE FROM COLLECTION"
                  ? this.handleRemoveFromCollection
                  : this.state.actionType == "REPLACE"
                  ? this.state.replaceRadio == "delete" ? this.handleReplaceAndDelete : this.handleReplace
                  : this.state.actionType == "DELETE"
                  ? this.state.deleteRadio == "hard" ? this.handleHardDelete : this.handleSoftDelete
                  : ""
              }
              action={
                this.state.actionType == "REMOVE FROM Organization"
                  ? "Remove From Organization"
                  : this.state.actionType == "SAVE"
                  ? "Save"
                  : this.state.actionType == "REPLACE"
                  ? "Replace"
                  : "Delete"
              }
              close={() => {
                this.setState({ openAlert: false });
              }}
            >
              {this.state.actionType == "REPLACE" &&
                <FormControl component="fieldset" style={{width: "400px"}}>
                  <RadioGroup aria-label="replace" name="replace1" value={this.state.replaceRadio} onChange={evt => { console.log("replace", evt.target.value); this.setState({ replaceRadio: evt.target.value }); }}>
                    <FormControlLabel style={{alignItems: "flex-start", marginRight: "0px"}} value="delete" control={<Radio style={{paddingTop: "0px"}} />} label={this.replaceDeleteRadio()} />
                    <FormControlLabel style={{alignItems: "flex-start", marginRight: "0px"}} value="rename" control={<Radio style={{paddingTop: "0px"}} />} label={this.replaceRenameRadio()} />
                  </RadioGroup>
                </FormControl>
              }
              {this.state.actionType == "DELETE" &&
                <FormControl component="fieldset" style={{width: "400px"}}>
                  <RadioGroup aria-label="delete" name="delete1" value={this.state.deleteRadio} onChange={evt => { console.log("delete", evt.target.value); this.setState({ deleteRadio: evt.target.value }); }}>
                    <FormControlLabel style={{alignItems: "flex-start", marginRight: "0px"}} value="hard" control={<Radio style={{paddingTop: "0px"}} />} label={this.hardDeleteRadio()} />
                    <FormControlLabel style={{alignItems: "flex-start", marginRight: "0px"}} value="soft" control={<Radio style={{paddingTop: "0px"}} />} label={this.softDeleteRadio()} />
                  </RadioGroup>
                </FormControl>
              }
            </AlertDialog>

            <Grid
              container
              spacing={2}
              //style={{ display: "none" /*paddingTop: "10px"*/ }}
            >
              <Grid item xs={6} md={6} lg={6}>
                <div
                  onClick={() => {
                    if (this.state.tab != 0) {
                      //if return from Asset History
                      this.setState({ tab: 0 }, () => {
                        if (this.state.data.MeridianMapId && document.getElementById("meridian-map")) {
                          this.renderMap(this.state.data.MeridianMapId);
                        }
                        else if (this.state.data.MeridianMapId && document.getElementById("meridian-map-full")) {
                          this.renderMap(this.state.data.MeridianMapId);
                        }
                      });
                    }
                  }}
                  style={this.state.tab == 0 ? styles[0] : styles[1]}
                >
                  <h5 style={{ marginBottom: "11px", marginTop: "4px" }}>Asset Detail</h5>
                </div>
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <div
                  onClick={() => this.setState({ tab: 1 })}
                  style={this.state.tab == 0 ? styles[1] : styles[0]}
                >
                  <h5 style={{ marginBottom: "11px", marginTop: "4px" }}>Asset History</h5>
                </div>
              </Grid>
            </Grid>

            {/* Asset History */}
            {this.state.tab == 1 ? (
              <History
                name={this.state.data.Name}
                mac={this.state.data.MacId}
                image={this.state.data.ImageUrl}
                assetDetail={this.state.data}
              />
            ) : (
              <React.Fragment>
                {this.state.backbtnvisible && (
                  <div className="border-bottom">
                    <Grid container spacing={3}>
                      <Grid item xs={10} sm={3} md={3} lg={3}>
                        {/* <Button
                          //className="button3"
                          style={{
                            color: "#4b2e83",
                            margin: 10,
                            marginLeft: 0,
                            marginTop: 10,
                            paddingTop: 10,
                            paddingBottom: 10,
                            //display: "none"
                          }}
                          onClick={this.handleBackButton}
                        >
                          <BackIcon /> Back
                        </Button> */}
                        <h5
                          style={{
                            color: "#4b2e83",
                            width: "100%",
                            paddingLeft: 2,
                            paddingTop: 8
                          }}
                        >
                          <Tooltip title="Go Back">
                            <IconButton
                              style={{
                                color: "#4b2e83",
                                opacity: "1",
                                outline: "none"
                              }}
                              aria-label="Go Back"
                              onClick={this.handleBackButton}
                            >
                              <GoBackIcon />
                            </IconButton>
                          </Tooltip>
                          Go back to previous screen
                        </h5>
                      </Grid>
                      <Grid item xs={10} sm={3} md={3} lg={3}>
                        <input
                          hidden={true}
                          ref={image => (this.image = image)}
                          type="file"
                          accept="image/x-png,image/jpeg"
                          onChange={this.fileChangedHandler}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={6}
                        md={6}
                        lg={6}
                        style={{ paddingRight: "0px" }}
                      >
                        <div className="text-right">
                          <CircularProgress
                            hidden={!this.state.isSubmitting}
                            className={classes.progress}
                            style={{ color: "#4b2e83", margin: 10 }}
                          />
                          <Button
                            hidden={true}
                            variant="outlined"
                            style={{
                              color: "#F44336",
                              margin: 10,
                              paddingTop: 10,
                              paddingBottom: 10,
                              height: 45,
                            }}
                            className="button1"
                          >
                            Retire Device
                          </Button>{" "}
                          <Button
                            hidden={
                              DeviceFlag == "ORPHAN" || this.state.isSubmitting
                            }
                            onClick={() => {
                              this.setState({
                                showOrphanedList: true,
                              });
                            }}
                            disabled={!this.state.isEditable}
                            variant="outlined"
                            style={{
                              color: "#F44336",
                              margin: 10,
                              paddingTop: 10,
                              paddingBottom: 10,
                              height: 45,
                            }}
                            className="button1"
                          >
                            Replace
                          </Button>{" "}
                          <Button
                            hidden={this.state.isSubmitting}
                            onClick={() => {
                              this.setState({
                                actionType: "DELETE",
                                openAlert: true,
                              });
                            }}
                            disabled={!this.state.isDeleteAllowed}
                            variant="outlined"
                            style={{
                              color: "#F44336",
                              margin: 10,
                              paddingTop: 10,
                              paddingBottom: 10,
                              height: 45,
                            }}
                            className="button1"
                          >
                            Delete
                          </Button>{" "}
                          <Button
                            hidden={this.state.isSubmitting}
                            onClick={() => {
                              this.setState({
                                actionType: "SAVE",
                                openAlert: true,
                              });
                            }}
                            disabled={!this.state.isEditable}
                            variant="outlined"
                            style={{
                              color: "#4b2e83",
                              margin: 10,
                              height: 45,
                            }}
                            className="button1"
                          >
                            Save
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
                <Grid
                  container
                  //hidden={DeviceFlag == "ORPHAN"}
                  spacing={3}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    style={{ paddingLeft: "0px" }}
                    item
                    xs={12}
                    md={3}
                    lg={3}
                    // md={
                    //   !this.state.openAddColection && !this.state.openAddDepartment
                    //     ? 8
                    //     : 2
                    // }
                    // lg={
                    //   !this.state.openAddColection && !this.state.openAddDepartment
                    //     ? 8
                    //     : 2
                    // }
                  >
                    {DeviceFlag !== "ORPHAN" && (
                      <React.Fragment>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Tooltip
                              className="float-left"
                              title="Assign to Collection"
                            >
                              <span>
                                <IconButton
                                  style={
                                    this.state.isEditable
                                      ? {
                                          color: "#4b2e83",
                                          opacity: "1",
                                          outline: "none",
                                        }
                                      : { color: "#343a40", opacity: "0.4" }
                                  }
                                  disabled={!this.state.isEditable}
                                  aria-label="Assign to Collection"
                                  onClick={() =>
                                    this.setState({
                                      openAddColection:
                                        !this.state.openAddColection,
                                      openAddDepartment: false,
                                      message: "",
                                    })
                                  }
                                >
                                  <AddIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <div>
                              <b>Collection</b>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Tooltip
                              className="float-left"
                              title="Assign to Department"
                            >
                              <span>
                                <IconButton
                                  style={
                                    this.state.isEditable && this.state.data.Collections.some(d => d.CollectionType == "Department") == false
                                      ? {
                                          color: "#4b2e83",
                                          opacity: "1",
                                          outline: "none",
                                        }
                                      : { color: "#343a40", opacity: "0.4" }
                                  }
                                  disabled={!this.state.isEditable || this.state.data.Collections.some(d => d.CollectionType == "Department")}
                                  aria-label="Assign to Department"
                                  onClick={() =>
                                    this.setState({
                                      openAddDepartment:
                                        !this.state.openAddDepartment,
                                      openAddColection: false,
                                    })
                                  }
                                >
                                  <AddIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <div>
                              <b>Department</b>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </Grid>
                  {this.state.openAddColection &&
                  !this.state.openAddDepartment &&
                  DeviceFlag !== "ORPHAN" ? (
                    <React.Fragment>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <input
                          list="text_editors2"
                          className={classes.input2}
                          placeholder="Add Collections..."
                          onChange={this.handleChange("newCollection")}
                          value={this.state.newCollection}
                        />
                        <datalist id="text_editors2">
                          <select multiple>
                            {collectiondata.map((user) => (
                              <option key={user.id} value={user.name}>
                                {user.name}
                              </option>
                            ))}
                          </select>
                        </datalist>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={2}
                        lg={2}
                        className="text-right"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title="Reset">
                          <IconButton
                            style={{
                              color: "#4b2e83",
                              opacity: "1",
                              outline: "none",
                            }}
                            aria-label="Reset"
                            onClick={() => {
                              this.setState({
                                newCollection: "",
                              });
                            }}
                          >
                            <ResetIcon />
                          </IconButton>
                        </Tooltip>
                        <Button
                          variant="outlined"
                          style={{
                            color: "#4b2e83",
                            margin: 10,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                          onClick={this.addCollection}
                          className="button1"
                          disabled={this.state.addCollectionClicked}
                        >
                          Add
                        </Button>
                      </Grid>
                    </React.Fragment>
                  ) : null}
                  {!this.state.openAddColection &&
                  this.state.openAddDepartment && this.state.data.Collections.some(d => d.CollectionType == "Department") == false &&
                  DeviceFlag !== "ORPHAN" ? (
                    <React.Fragment>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          list="text_editors2"
                          className={classes.input2}
                          placeholder="Add Departments..."
                          onChange={this.handleChange("newDepartment")}
                          value={this.state.newDepartment}
                        />
                        <datalist id="text_editors2">
                          <select multiple>
                            {this.state.departmentdata.map((user) => (
                              <option
                                key={user.CollectionId}
                                value={user.CollectionName}
                              >
                                {user.CollectionName}
                              </option>
                            ))}
                          </select>
                        </datalist>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={2}
                        lg={2}
                        className="text-right"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title="Reset">
                          <IconButton
                            style={{
                              color: "#4b2e83",
                              opacity: "1",
                              outline: "none",
                            }}
                            aria-label="Reset"
                            onClick={() => {
                              this.setState({
                                newDepartment: "",
                              });
                            }}
                          >
                            <ResetIcon />
                          </IconButton>
                        </Tooltip>
                        <Button
                          variant="outlined"
                          style={{
                            color: "#4b2e83",
                            margin: 10,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                          onClick={this.addDepartment}
                          className="button1"
                          disabled={this.state.addDepartmentClicked}
                        >
                          Add
                        </Button>
                      </Grid>
                    </React.Fragment>
                  ) : null}
                  <Grid item xs={10} md={3} lg={3}>
                    <div
                      style={{
                        display: "flex",
                        float: "right",
                        padding: 0,
                        margin: 0,
                        alignItems: "center",
                      }}
                    >
                      <h6
                        style={{ padding: 0, margin: 0 /*paddingTop: 15*/ }}
                        className="float-right"
                      >
                        {BatteryLevel > 100
                          ? "Unknown"
                          : "    " + BatteryLevel + "%"}
                      </h6>
                      {BatteryLevel > 0 && BatteryLevel <= 10 && (
                        <BatteryLowIcon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel > 10 && BatteryLevel <= 25 && (
                        <Battery20Icon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel > 25 && BatteryLevel <= 40 && (
                        <Battery30Icon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel > 40 && BatteryLevel <= 55 && (
                        <Battery50Icon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel > 55 && BatteryLevel <= 75 && (
                        <Battery60Icon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel > 75 && BatteryLevel <= 85 && (
                        <Battery80Icon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel > 85 && BatteryLevel <= 99 && (
                        <Battery90Icon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel == 100 && (
                        <BatteryFullIcon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel > 100 && (
                        <BatteryUnknown
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}

                      <img
                        className="image"
                        src={
                          this.state.fileImage ?
                          window.URL.createObjectURL(new Blob([this.state.fileImage], { type: "image/x-png,image/jpeg" }))
                           : ImageUrl ?
                          ImageUrl
                           : defaultImage
                        }
                        width="30px"
                        style={{
                          borderRadius: "50%",
                          //marginTop: 9,
                          marginLeft: 10,
                        }}
                        height="30px"
                        alt=""
                        onClick={() => this.image.click()}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div style={{ color: "red", marginLeft: 15 }}>
                  {this.state.message}
                </div>
                <hr
                  //hidden={DeviceFlag == "ORPHAN"}
                  style={{ marginBottom: 0, marginTop: 0 }}
                />
                <div className="justify-content-between  align-items-center pt-3 pb-2 mb-3">
                  <div>
                    {/* <Grid container spacing={3}>
                      <Grid className="padding0" item xs={10} sm={6} md={4} lg={4}>
                        <TextField
                          id="outlined-name"
                          label="Device Name"
                          disabled={!this.state.isEditable}
                          value={this.state.Name}
                          type="text"
                          style={{
                            width: "100%",
                            borderColor: "#4b2e83",
                          }}
                          required
                          margin="dense"
                          onChange={this.handleChange("Name")}
                          variant="outlined"
                          placeholder="Name"
                          InputProps={{ className: classes.input }}
                          InputLabelProps={{
                            className: classes.label,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      {/* <Grid className="padding0" item xs={10} sm={6} md={2} lg={2}>
                        <TextField
                          id="outlined-name"
                          label="Display Name"
                          disabled={!this.state.isEditable}
                          value={this.state.DisplayName}
                          type="text"
                          style={{
                            width: "100%",
                            borderColor: "#4b2e83",
                          }}
                          required
                          margin="dense"
                          onChange={this.handleChange("DisplayName")}
                          variant="outlined"
                          placeholder="Name"
                          InputProps={{ className: classes.input }}
                          InputLabelProps={{
                            className: classes.label,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={10} sm={6} md={2} lg={2}>
                        <TextField
                          id="outlined-name"
                          label="Device Id"
                          disabled
                          margin="dense"
                          value={DeviceId}
                          type="text"
                          style={{
                            width: "100%",
                            borderColor: "#4b2e83",
                          }}
                          onChange={this.handleChange("deviceid")}
                          variant="outlined"
                          placeholder="Device Id"
                          InputProps={{ className: classes.input }}
                          InputLabelProps={{
                            className: classes.label,
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={10} sm={6} md={2} lg={2}>
                        <TextField
                          id="outlined-name"
                          label="Device Flag"
                          disabled
                          value={DeviceFlag}
                          type="text"
                          style={{
                            width: "100%",
                            borderColor: "#4b2e83",
                          }}
                          margin="dense"
                          onChange={this.handleChange("Asset Flag")}
                          variant="outlined"
                          placeholder="Device Flag"
                          InputProps={{ className: classes.input }}
                          InputLabelProps={{
                            className: classes.label,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={10} sm={6} md={2} lg={2}>
                        <div
                          style={{
                            display: "flex",
                            float: "right",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <h6
                            style={{ padding: 0, margin: 0, paddingTop: 15 }}
                            className="float-right"
                          >
                            {BatteryLevel > 100
                              ? "unknown"
                              : "    " + BatteryLevel + "%"}
                          </h6>
                          {BatteryLevel > 0 && BatteryLevel <= 10 && (
                            <BatteryLowIcon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel > 10 && BatteryLevel <= 25 && (
                            <Battery20Icon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel > 25 && BatteryLevel <= 40 && (
                            <Battery30Icon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel > 40 && BatteryLevel <= 55 && (
                            <Battery50Icon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel > 55 && BatteryLevel <= 75 && (
                            <Battery60Icon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel > 75 && BatteryLevel <= 85 && (
                            <Battery80Icon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel > 85 && BatteryLevel <= 99 && (
                            <Battery90Icon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel == 100 && (
                            <BatteryFullIcon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel > 100 && (
                            <BatteryUnknown
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}

                          <img
                            className="image"
                            src={ImageUrl}
                            width="30px"
                            style={{
                              borderRadius: "50%",
                              marginTop: 9,
                              marginLeft: 10,
                            }}
                            height="30px"
                            alt=""
                          />
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={6} md={2} lg={2}>
                        {ExternalId != null && ExternalId != "" ? (
                          <Barcode
                            value={ExternalId}
                            displayValue={false}
                            height={35}
                            width={0.7}
                          />
                        ) : (
                          <img src={NoBarcode} height={45} width={100} />
                        )}
                      </Grid>
                    </Grid> */}

                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={10}
                        sm={10}
                        md={6}
                        lg={3}
                        style={{ paddingTop: 0 }}
                      >
                        <Paper
                          className={classes.root}
                          style={{ padding: 10, marginTop: 8 }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <h5
                              style={{
                                color: "#4b2e83",
                                width: "100%",
                                paddingLeft: 2,
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              Info
                            </h5>
                          </div>
                          <hr />
                          <TextField
                            id="outlined-name"
                            label="Device Name"
                            disabled={!this.state.isEditable}
                            value={this.state.Name}
                            type="text"
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                              marginBottom: 13,
                            }}
                            required
                            margin="dense"
                            onChange={this.handleChange("Name")}
                            variant="outlined"
                            placeholder="Name"
                            //InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          />
                          <TextField
                            margin="dense"
                            id="outlined-name"
                            label="MAC Address"
                            value={MacId}
                            type="text"
                            disabled
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                              marginBottom: 13,
                            }}
                            onChange={this.handleChange("mac")}
                            variant="outlined"
                            placeholder="MAC Address"
                            //InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          />

                          <TextField
                            id="outlined-name"
                            label="First Assignment"
                            disabled
                            className={classes.textField}
                            value={/*DeviceFlag == "ORPHAN"*/ EntityId == null ? "Unassigned" :  FirstAssignment ? formatDate(new Date(FirstAssignment+"Z")) : "" /*this.getLastAssignDate()*/}
                            type="text"
                            style={{
                              borderColor: "#4b2e83",
                              //marginBottom: 13,
                            }}
                            margin="dense"
                            onChange={this.handleChange("TagCode")}
                            variant="outlined"
                            placeholder="First Assignment"
                            //InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          />
                          {/* <TextField
                            id="outlined-name"
                            disabled={!this.state.isEditable}
                            label="Serial Number"
                            value={this.state.SerialNumber}
                            margin="dense"
                            type="text"
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                            }}
                            onChange={this.handleChange("SerialNumber")}
                            multiline
                            rows="1"
                            variant="outlined"
                            placeholder="Serial Number"
                            InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          /> */}
                        </Paper>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={10}
                        md={6}
                        lg={3}
                        style={{ paddingTop: 0 }}
                      >
                        <Paper
                          className={classes.root}
                          style={{ padding: 10, marginTop: 8 }}
                        >
                          <h5
                            style={{
                              color: "#4b2e83",
                              width: "100%",
                              paddingLeft: 2,
                            }}
                          >
                            Asset Details
                          </h5>
                          <hr />
                          {/* <TextField
                            id="outlined-name"
                            label="Tag Code"
                            disabled
                            className={classes.textField}
                            value={TagCode}
                            type="text"
                            style={{
                              borderColor: "#4b2e83",
                              marginBottom: 13,
                            }}
                            margin="dense"
                            onChange={this.handleChange("TagCode")}
                            variant="outlined"
                            placeholder="Tag Code"
                            InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          /> */}
                          <div>
                            <Autocomplete
                              disabled={!this.state.isEditable}
                              //disableClearable={true}
                              id="outlined-select"
                              select
                              value={this.state.Manufacturer}
                              options={manufacturerData.sort(byPropertyCalled("name")).map(m => m.name)}
                              getOptionSelected={(option, value) =>
                                //option.id == value.id
                                option == value
                              }
                              getOptionLabel={(user) => user? user/*.name*/ : ""}
                              renderOption={(user, {inputValue}) => {
                                const matches = match(user/*.name*/, inputValue);
                                const parts = parse(user/*.name*/, matches);
                                return (
                                  <div style={{ padding: "8px 2px" }}>
                                    {parts.map((part, index) => (
                                      <span
                                        key={index}
                                        style={{
                                          fontWeight: part.highlight ? 700 : 400,
                                        }}
                                      >
                                        {part.text}
                                      </span>
                                    ))}
                                  </div>
                                );
                              }}
                              onChange={this.inputChange("Manufacturer")}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Manufacturer"
                                  variant="outlined"
                                  margin="dense"
                                  className={classes.textField}
                                  style={{ marginBottom: 13 }}
                                  //InputProps={{ className: classes.input }}
                                  InputLabelProps={{
                                    shrink: true,
                                    className: classes.label
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div>
                            <Autocomplete
                              disabled={(this.state.isEditable && this.state.Manufacturer) ? false : true}
                              //disableClearable={true}
                              id="outlined-select"
                              select
                              value={this.state.DeviceModel}
                              options={modelData.sort(byPropertyCalled("name")).map(m => m.name)}
                              getOptionSelected={(option, value) =>
                                //option.id == value.id
                                option == value
                              }
                              getOptionLabel={(model) => model ? model/*.name*/ : ""}
                              renderOption={(model, {inputValue}) => {
                                const matches = match(model/*.name*/, inputValue);
                                const parts = parse(model/*.name*/, matches);
                                return (
                                  <div style={{ padding: "8px 2px" }}>
                                    {parts.map((part, index) => (
                                      <span
                                        key={index}
                                        style={{
                                          fontWeight: part.highlight ? 700 : 400,
                                        }}
                                      >
                                        {part.text}
                                      </span>
                                    ))}
                                  </div>
                                );
                              }}
                              onChange={this.inputChange("DeviceModel")}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Model"
                                  variant="outlined"
                                  margin="dense"
                                  className={classes.textField}
                                  style={{ marginBottom: 13 }}
                                  //InputProps={{ className: classes.input }}
                                  InputLabelProps={{
                                    shrink: true,
                                    className: classes.label
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div>
                            <Autocomplete
                              disabled={!this.state.isEditable}
                              //disableClearable={false}
                              id="outlined-select"
                              select
                              value={this.state.DeviceType}
                              options={this.state.typeData.sort(byPropertyCalled("name")).map(d => d.name)}
                              getOptionSelected={(option, value) =>
                                //option.id == value.id
                                option == value
                              }
                              getOptionLabel={(type) => type? type/*.name*/ : ""}
                              renderOption={(type, {inputValue}) => {
                                const matches = match(type/*.name*/, inputValue);
                                const parts = parse(type/*.name*/, matches);
                                return (
                                  <div style={{ padding: "8px 2px" }}>
                                    {parts.map((part, index) => (
                                      <span
                                        key={index}
                                        style={{
                                          fontWeight: part.highlight ? 700 : 400,
                                        }}
                                      >
                                        {part.text}
                                      </span>
                                    ))}
                                  </div>
                                );
                              }}
                              onChange={this.inputChange("DeviceType")}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Type"
                                  variant="outlined"
                                  margin="dense"
                                  className={classes.textField}
                                  //InputProps={{ className: classes.input }}
                                  InputLabelProps={{
                                    shrink: true,
                                    className: classes.label
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Paper>
                      </Grid>

                      <Grid
                        item
                        xs={10}
                        sm={10}
                        md={6}
                        lg={3}
                        style={{ paddingTop: 0 }}
                      >
                        <Paper
                          className={classes.root}
                          style={{ padding: 10, marginTop: 8 }}
                        >
                          <h5
                            style={{
                              color: "#4b2e83",
                              width: "100%",
                              paddingLeft: 2,
                            }}
                          >
                            Status
                          </h5>
                          <hr />

                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <TextField
                              id="outlined-name"
                              label="Organization"
                              type="text"
                              disabled={true}
                              margin="dense"
                              value={EntityName}
                              style={{
                                width: "100%",
                                borderColor: "#4b2e83",
                                marginBottom: 13,
                                paddingRight: 2,
                              }}
                              onChange={this.handleChange("enitity")}
                              variant="outlined"
                              placeholder="Organization"
                              //InputProps={{ className: classes.input }}
                              InputLabelProps={{
                                className: classes.label,
                                shrink: true,
                              }}
                            />
                            {EntityId != null && (
                              <Tooltip
                                className="float-right"
                                title="Remove asset from Organization"
                              >
                                <span>
                                  <IconButton
                                    onClick={() => {
                                      this.setState({
                                        actionType: "REMOVE FROM Organization",
                                        openAlert: true,
                                      });
                                    }}
                                    disabled={!this.state.showRemoveEntityButton}
                                    style={
                                      this.state.showRemoveEntityButton
                                        ? {
                                            color: "#4b2e83",
                                            outline: "none",
                                            height: "50px",
                                            width: "50px",
                                            opacity: "1",
                                          }
                                        : {
                                            color: "#343a40",
                                            opacity: "0.4",
                                            height: "50px",
                                            width: "50px",
                                          }
                                    }
                                    aria-label="Remove asset from Organization"
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            )}
                          </div>

                          {/* <TextField
                            id="outlined-name"
                            label="Building"
                            disabled={true}
                            type="text"
                            margin="dense"
                            value={LocationName}
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                              marginBottom: 13,
                            }}
                            onChange={this.handleChange("location")}
                            variant="outlined"
                            placeholder="Location"
                            InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          /> */}
                          {/* <TextField
                            id="outlined-name"
                            label="Meridian Building Name"
                            disabled={true}
                            type="text"
                            margin="dense"
                            value={MeridianBuildingName}
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                              marginBottom: 13,
                            }}
                            onChange={this.handleChange("meridianbuildingname")}
                            variant="outlined"
                            placeholder="Meridian Building Name"
                            InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          /> */}
                          <TextField
                            id="outlined-name"
                            label="Department"
                            type="text"
                            margin="dense"
                            disabled={true}
                            value={
                              dept.length > 0 ? dept[0].CollectionName : ""
                            }
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                              marginBottom: 13,
                            }}
                            onChange={this.handleChange("department")}
                            variant="outlined"
                            placeholder="Department"
                            //InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          />
                          <TextField
                            id="outlined-name"
                            label="Device Flag"
                            disabled
                            value={
                              DeviceFlag == "NOT_MAPPED"
                                ? "NO DEPARTMENT"
                                : DeviceFlag
                            }
                            type="text"
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                            }}
                            margin="dense"
                            onChange={this.handleChange("Asset Flag")}
                            variant="outlined"
                            placeholder="Device Flag"
                            //InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          />
                        </Paper>
                      </Grid>

                      <Grid
                        item
                        xs={10}
                        sm={10}
                        md={6}
                        lg={3}
                        style={{ paddingTop: 0 }}
                      >
                        <Paper
                          className={classes.root}
                          style={{ padding: 10, marginTop: 8 }}
                        >
                          <h5
                            style={{
                              color: "#4b2e83",
                              width: "100%",
                              paddingLeft: 2,
                            }}
                          >
                            Events
                          </h5>
                          <hr />

                          <TextField
                            id="tagCreate"
                            label="Tag Created"
                            disabled
                            value={MeridianCreated ? formatDate(new Date(MeridianCreated+"Z")) : "Unknown"}
                            type="text"
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                              marginBottom: 13,
                            }}
                            margin="dense"
                            variant="outlined"
                            placeholder="Tag Created"
                            //InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          />
                            <TextField
                              id="tagModify"
                              label="Tag Modified"
                              disabled
                              value={MeridianModified ? formatDate(new Date(MeridianModified+"Z")) : "Unknown"}
                              type="text"
                              style={{
                                width: "100%",
                                borderColor: "#4b2e83",
                                marginBottom: 13,
                              }}
                              margin="dense"
                              variant="outlined"
                              placeholder="Tag Modified"
                              //InputProps={{ className: classes.input }}
                              InputLabelProps={{
                                className: classes.label,
                                shrink: true,
                              }}
                            />
                            <TextField
                              id="assetAssignemnt"
                              label="Last Assignment"
                              disabled
                              value={/*DeviceFlag == "ORPHAN"*/ EntityId == null ? "Unassigned" : LastAssignment ? formatDate(new Date(LastAssignment+"Z")) : "" /*this.getFirstAssignDate()*/}
                              type="text"
                              style={{
                                width: "100%",
                                borderColor: "#4b2e83",
                              }}
                              margin="dense"
                              variant="outlined"
                              placeholder="Last Assignment"
                              //InputProps={{ className: classes.input }}
                              InputLabelProps={{
                                className: classes.label,
                                shrink: true,
                              }}
                            />
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>

                  <Collapse
                    in={!this.state.showCollectionTable}
                    style={{ marginTop: 10 }}
                  >
                    <Paper style={{ padding: 15 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={10} sm={6} md={8} lg={8}>
                          <Button
                            className="button3"
                            style={{
                              color: "#4b2e83",
                              paddingBottom: 10,
                            }}
                            onClick={() => {
                              this.setState({
                                showCollectionTable: true,
                                selectedCollection: "",
                              });
                            }}
                          >
                            <BackIcon /> Back to Collection List
                          </Button>
                        </Grid>
                        <Grid item xs={10} sm={3} md={3} lg={3}>
                          {selectedCollection.length > 0 ? (
                            <Link
                              disabled={!this.state.isEditable}
                              to={
                                this.state.isEditable
                                  ? this.state.selectedCollType ==
                                    "User Defined Collection"
                                    ? `/options/Modify/collection/${selectedCollection[0].CollectionId}?type=col`
                                    : `/options/Modify/collection/${selectedCollection[0].CollectionId}?type=dept`
                                  : "/devices/viewtable"
                              }
                            >
                              <p style={{ float: "right" }}>
                                Collection:{" "}
                                {selectedCollection.length > 0
                                  ? selectedCollection[0].CollectionName
                                  : ""}
                              </p>
                            </Link>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container spacing={16} style={{ marginLeft: 10 }}>
                        {selectedCollection.length > 0 ? (
                          <React.Fragment>
                            <h6
                              style={{
                                marginLeft: 10,
                                width: "100%",
                                marginTop: 4,
                              }}
                            >
                              List of devices assigned to{" "}
                              {selectedCollection.length > 0
                                ? selectedCollection[0].CollectionName
                                : ""}
                            </h6>
                            <table
                              style={{ width: "97%" }}
                              className="table table-striped coll-table table-bordered table-condensed"
                            >
                              <thead>
                                <tr>
                                  <th className={classes.tableHead}>IMAGE</th>
                                  <th className={classes.tableHead}>
                                    DEVICE NAME
                                  </th>
                                  {/* <th className={classes.tableHead}>
                                    DISPLAY NAME
                                  </th> */}
                                  <th className={classes.tableHead}>ACTION</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedCollection.map((item) => (
                                  <tr>
                                    <td
                                      style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <img
                                        className="image"
                                        style={{ borderRadius: "50%" }}
                                        src={item.ImageUrl ? item.ImageUrl : defaultImage}
                                        height={30}
                                        width={30}
                                      />
                                    </td>
                                    <td className={classes.tableRow}>
                                      {item.Name}
                                    </td>
                                    {/* <td className={classes.tableRow}>
                                      {item.DisplayName}
                                    </td> */}
                                    <td
                                      style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <Tooltip title="View Device">
                                        <IconButton
                                          aria-label="View Device"
                                          className={classes.icons}
                                          onClick={() => {
                                            this.setState(
                                              {
                                                MacId: item.MacId,
                                                showCollectionTable: true,
                                              },
                                              () => {
                                                this.fetchAssetData();
                                                this.handleScroll();
                                              }
                                            );
                                          }}
                                          style={{
                                            color: "#4b2e83",
                                            opacity: "1",
                                            outline: "none",
                                          }}
                                        >
                                          <ViewIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Delete">
                                        <IconButton
                                          aria-label="Delete"
                                          className={classes.icons}
                                          hidden={!this.state.isEditable}
                                          onClick={() => {
                                            this.setState({
                                              deviceToDelete: item,
                                              openAlert: true,
                                              actionType:
                                                "REMOVE FROM COLLECTION",
                                            });
                                          }}
                                          style={{
                                            color: "#4b2e83",
                                            opacity: "1",
                                            outline: "none",
                                          }}
                                        >
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </React.Fragment>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 30,
                            }}
                          >
                            <h5>No Access</h5>
                          </div>
                        )}
                      </Grid>
                    </Paper>
                  </Collapse>

                  <Paper
                    className={classes.root}
                    style={{ padding: 10, marginTop: 8 }}
                  >
                    <h5
                      style={{
                        color: "#4b2e83",
                        width: "100%",
                        paddingLeft: 2,
                      }}
                    >
                      Labels
                    </h5>
                    <hr />
                    {this.state.data.Labels.length > 0 ? (
                      <div style={{display: "flex", gap: "10px"}}>
                        {this.state.data.Labels.map(l => <Chip label={l.name} />)}
                      </div>
                    ) : (
                      <p style={{
                        //color: "#4b2e83",
                        width: "100%",
                        paddingLeft: 2,
                        //textAlign: "center"
                      }}>No labels assigned.</p>
                    )}
                  </Paper>

                  <Collapse in={this.state.showCollectionTable}>
                    <Paper>
                      <CollectionsTable
                        collections={this.state.data.Collections}
                        refreshData={() => this.fetchAssetData()}
                        showCollectionDetail={(coll_id, coll_type) => {
                          this.showCollection(coll_id);
                          this.setState({
                            selectedCollType: coll_type,
                          });
                        }}
                        disableButton={this.state.isEditable}
                      />
                    </Paper>

                    <Grid container spacing={2} style={{ paddingTop: "10px" }}>
                      <Grid item xs={6} md={4} lg={2}>
                        <div
                          onClick={() =>
                            this.setState({ index: 0 }, () => {
                              if (this.state.meridiandata.map.id)
                                this.renderMap();
                            })
                          }
                          style={this.state.index == 0 ? styles[0] : styles[1]}
                        >
                          <h6>Current location</h6>
                        </div>
                      </Grid>
                      <Grid item xs={6} md={4} lg={2}>
                        <div
                          onClick={() =>
                            this.setState({ index: 1 }, () => {
                              if (
                                this.state.data.MeridianMapId &&
                                document.getElementById("meridian-map")
                              )
                                this.renderMap(
                                  this.state.data.MeridianMapId
                                );
                            })
                          }
                          style={this.state.index == 0 ? styles[1] : styles[0]}
                        >
                          <h6>Last known location</h6>
                        </div>
                      </Grid>
                      <Grid item xs={6} md={4} lg={8} style={{ textAlign: "end" }}>
                          <Tooltip title="See Fullscreen">
                            <IconButton
                              style={{ padding: 0, outline: "none" }}
                              onClick={() =>
                                this.setState({ isFullScreen: true }, () => {
                                  setTimeout(() => {
                                    if (
                                      this.state.data.MeridianMapId &&
                                      document.getElementById("meridian-map-full")
                                    )
                                      this.renderMap(
                                        this.state.data.MeridianMapId
                                      );
                                  }, 500);
                                })
                              }
                            >
                              <SvgIcon>
                                <path fill="none" stroke="#4b2e83" strokeWidth="2" d="M10,14 L2,22 M1,15 L1,23 L9,23 M22,2 L14,10 M15,1 L23,1 L23,9"></path>
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                    </Grid>

                    <Paper style={{ marginBottom:"35px" }}>
                      {!this.state.hideMap ? (
                        <Paper
                          className={classes.root}
                          style={{ padding: 11, width: "100%" }}
                        >
                          {/* <Tabs
                            TabIndicatorProps={{ style: { background: "#4b2e83" } }}
                            value={this.state.index}
                            onChange={(event, index) => {
                              console.log("index", event, index);
                              this.setState({ index: index }, () =>
                                this.renderMap()
                              );
                            }}
                          >
                            <Tab index={0} label="Current location"></Tab>
                            <Tab index={1} label="Last known location"></Tab>
                          </Tabs> */}
                          {this.state.index == 0 &&
                            !this.state.meridiandata.map.id && (
                              <div
                                className={classes.root}
                                style={{
                                  width: "100%",
                                  height: "500px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: 30,
                                }}
                              >
                                <h4>
                                  Currently the device is not traceable. Please
                                  check Last known location.
                                </h4>
                              </div>
                            )
                          }
                          {this.state.index == 0 &&
                            this.state.meridiandata.map.id && (
                              <div /*style={{ padding: "10px 0" }}*/>
                                <Grid container spacing={2}>
                                  <Grid item xs={3}>
                                    <TextField
                                      id="building-name"
                                      label="Campus"
                                      disabled={true}
                                      type="text"
                                      margin="dense"
                                      value={MeridianBuildingName}
                                      style={{
                                        width: "100%",
                                        borderColor: "#4b2e83",
                                        marginBottom: 13,
                                      }}
                                      variant="outlined"
                                      placeholder="Campus"
                                      InputProps={{ className: classes.input }}
                                      InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                      id="floor"
                                      label="Floor"
                                      disabled={true}
                                      type="text"
                                      margin="dense"
                                      value={
                                        this.state.meridiandata.map.level_label
                                      }
                                      style={{
                                        width: "100%",
                                        borderColor: "#4b2e83",
                                        marginBottom: 13,
                                      }}
                                      variant="outlined"
                                      placeholder="Floor"
                                      InputProps={{ className: classes.input }}
                                      InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                      margin="dense"
                                      id="outlined-name"
                                      label="Zone"
                                      value={this.state.currentZone}
                                      type="text"
                                      disabled
                                      style={{
                                        width: "100%",
                                        borderColor: "#4b2e83",
                                        //marginBottom: 13,
                                      }}
                                      variant="outlined"
                                      placeholder="Zone"
                                      InputProps={{ className: classes.input }}
                                      InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <div
                                      style={{
                                        //padding: "10px 10px 0px 10px",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <TextField
                                        id="sync"
                                        disabled
                                        label="Last Heard"
                                        className={classes.textField}
                                        value={this.state.syncTime}
                                        type="text"
                                        style={{
                                          borderColor: "#4b2e83",
                                          marginBottom: 13,
                                        }}
                                        margin="dense"
                                        variant="outlined"
                                        placeholder="Last Heard"
                                        //placeholder={this.props.selectedAssetDetail.DeviceFlag !== "NOT_TRACEABLE" ?"Sync Time" : ""}
                                        InputProps={{
                                          className: classes.input,
                                        }}
                                        InputLabelProps={{
                                          className: classes.label,
                                          shrink: true,
                                        }}
                                      />
                                      <Tooltip
                                        title="Sync"
                                        className="float-right"
                                      >
                                        <span>
                                          <IconButton
                                            style={{
                                              color: "#4b2e83",
                                              opacity: "1",
                                              outline: "none",
                                            }}
                                            disabled={
                                              this.state.isCurrentlySyncing
                                            }
                                            aria-label="Sync"
                                            onClick={() =>
                                              this.setState(
                                                { isCurrentlySyncing: true },
                                                () =>
                                                  this.getAssetFromMeridian(
                                                    this.state.data
                                                      .MeridianLocationId,
                                                      this.state.data
                                                      .MacId
                                                  )
                                              )
                                            }
                                          >
                                            <RefreshIcon />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </Grid>
                                </Grid>
                                <div className="justify-content-between align-items-center">
                                  {/* <h6 style={{ width: "100%", paddingLeft: 2 }}>
                                    Floor  {FloorNumber}
                                    {this.state.meridiandata.map.level_label}
                                  </h6> */}
                                  <div className="map" id="meridian-map" />
                                </div>
                              </div>
                            )
                          }
                          {this.state.index !== 0 && (
                            <div /*style={{ padding: "10px 0" }}*/>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <TextField
                                    id="building-name"
                                    label="Campus"
                                    disabled={true}
                                    type="text"
                                    margin="dense"
                                    value={MeridianBuildingName}
                                    style={{
                                      width: "100%",
                                      borderColor: "#4b2e83",
                                      marginBottom: 13,
                                    }}
                                    variant="outlined"
                                    placeholder="Campus"
                                    InputProps={{ className: classes.input }}
                                    InputLabelProps={{
                                      className: classes.label,
                                      shrink: true,
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={3}>
                                  <TextField
                                    id="floor"
                                    label="Floor"
                                    disabled={true}
                                    type="text"
                                    margin="dense"
                                    value={FloorLabel}
                                    style={{
                                      width: "100%",
                                      borderColor: "#4b2e83",
                                      marginBottom: 13,
                                    }}
                                    variant="outlined"
                                    placeholder="Floor"
                                    InputProps={{ className: classes.input }}
                                    InputLabelProps={{
                                      className: classes.label,
                                      shrink: true,
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={3}>
                                  <TextField
                                    margin="dense"
                                    id="outlined-name"
                                    label="Zone"
                                    value={
                                      this.state.data.ZoneName
                                        ? this.state.data
                                            .ZoneName
                                        : "No Zone"
                                    }
                                    type="text"
                                    disabled
                                    style={{
                                      width: "100%",
                                      borderColor: "#4b2e83",
                                      //marginBottom: 13,
                                    }}
                                    variant="outlined"
                                    placeholder="Zone"
                                    InputProps={{ className: classes.input }}
                                    InputLabelProps={{
                                      className: classes.label,
                                      shrink: true,
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={3}>
                                  <TextField
                                    id="lastheard"
                                    label="Last Heard"
                                    disabled={true}
                                    type="text"
                                    margin="dense"
                                    // value={this.props.lastSync.toString()}
                                    //value={lastKnownSync}
                                    //value={/*his.lastKnownModified*/ this.state.syncTime}
                                    value={Timestamp ? formatDate(new Date(Timestamp*1000)) : "Unknown"}
                                    style={{
                                      width: "100%",
                                      borderColor: "#4b2e83",
                                      marginBottom: 13,
                                    }}
                                    variant="outlined"
                                    placeholder="Last Heard"
                                    InputProps={{ className: classes.input }}
                                    InputLabelProps={{
                                      className: classes.label,
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <div className="justify-content-between align-items-center">
                                {/* <h6 style={{ width: "100%", paddingLeft: 2 }}>
                                  {/* Floor  {FloorNumber}*
                                  {this.props.selectedAssetDetail.FloorNumber}
                                </h6> */}
                                <div className="map" id="meridian-map" />
                              </div>
                            </div>
                          )}
                        </Paper>
                      ) : (
                        <div
                          className={classes.root}
                          style={{
                            width: "100%",
                            height: "500px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 30,
                          }}
                        >
                          <h4>There's no map uploaded for this Device.</h4>
                        </div>
                      )}
                    </Paper>
                  </Collapse>

                  <Dialog fullScreen open={this.state.isFullScreen}>
                    <Collapse in={this.state.isFullScreen}>
                      <Grid
                        container
                        spacing={2}
                        style={{ paddingTop: "10px", width: "100%" }}
                      >
                        <Grid item xs={6} md={4} lg={2}>
                          <div
                            onClick={() =>
                              this.setState({ index: 0 }, () => {
                                if (this.state.meridiandata.map.id)
                                  this.renderMap();
                              })
                            }
                            style={
                              this.state.index == 0 ? styles[0] : styles[1]
                            }
                          >
                            <h6>Current location</h6>
                          </div>
                        </Grid>
                        <Grid item xs={6} md={4} lg={2}>
                          <div
                            onClick={() =>
                              this.setState({ index: 1 }, () => {
                                if (
                                  this.state.data.MeridianMapId &&
                                  document.getElementById("meridian-map")
                                )
                                  this.renderMap(
                                    this.state.data.MeridianMapId
                                  );
                              })
                            }
                            style={
                              this.state.index == 0 ? styles[1] : styles[0]
                            }
                          >
                            <h6>Last known location</h6>
                          </div>
                        </Grid>
                        <Grid item xs={6} md={4} lg={8} style={{ textAlign: "end" }}>
                          <Tooltip title="Close Fullscreen">
                            <IconButton
                              style={{ padding: 0, outline: "none" }}
                              onClick={() =>
                                this.setState({ isFullScreen: false }, () => {
                                  setTimeout(() => {
                                    if (
                                      this.state.data.MeridianMapId &&
                                      document.getElementById("meridian-map")
                                    )
                                      this.renderMap(
                                        this.state.data.MeridianMapId
                                      );
                                  }, 500);
                                })
                              }
                            >
                              <SvgIcon>
                                <path
                                  fill="none"
                                  stroke="#4b2e83"
                                  strokeWidth="2"
                                  d="M2,14 L10,14 L10,22 M1,23 L10,14 M23,1 L14,10 M22,10 L14,10 L14,2"
                                ></path>
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>

                      <Paper>
                        {!this.state.hideMap ? (
                          <Paper
                            className={classes.root}
                            style={{ padding: 11, width: "100%" }}
                          >
                            {/* <Tabs
                              TabIndicatorProps={{ style: { background: "#4b2e83" } }}
                              value={this.state.index}
                              onChange={(event, index) => {
                                console.log("index", event, index);
                                this.setState({ index: index }, () =>
                                  this.renderMap()
                                );
                              }}
                            >
                              <Tab index={0} label="Current location"></Tab>
                              <Tab index={1} label="Last known location"></Tab>
                            </Tabs> */}
                            {this.state.index == 0 &&
                              !this.state.meridiandata.map.id && (
                                <div
                                  className={classes.root}
                                  style={{
                                    width: "100%",
                                    height: "calc(100vh - 145px)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: 30,
                                  }}
                                >
                                  <h4>
                                    Currently the device is not traceable.
                                    Please check Last known location.
                                  </h4>
                                </div>
                              )
                            }
                            {this.state.index == 0 &&
                              this.state.meridiandata.map.id && (
                                <div /*style={{ padding: "10px 0" }}*/>
                                  <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                      <TextField
                                        id="building-name"
                                        label="Campus"
                                        disabled={true}
                                        type="text"
                                        margin="dense"
                                        value={MeridianBuildingName}
                                        style={{
                                          width: "100%",
                                          borderColor: "#4b2e83",
                                          marginBottom: 13,
                                        }}
                                        variant="outlined"
                                        placeholder="Campus"
                                        InputProps={{
                                          className: classes.input,
                                        }}
                                        InputLabelProps={{
                                          className: classes.label,
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <TextField
                                        id="floor"
                                        label="Floor"
                                        disabled={true}
                                        type="text"
                                        margin="dense"
                                        value={
                                          this.state.meridiandata.map
                                            .level_label
                                        }
                                        style={{
                                          width: "100%",
                                          borderColor: "#4b2e83",
                                          marginBottom: 13,
                                        }}
                                        variant="outlined"
                                        placeholder="Floor"
                                        InputProps={{
                                          className: classes.input,
                                        }}
                                        InputLabelProps={{
                                          className: classes.label,
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <TextField
                                        margin="dense"
                                        id="outlined-name"
                                        label="Zone"
                                        value={this.state.currentZone}
                                        type="text"
                                        disabled
                                        style={{
                                          width: "100%",
                                          borderColor: "#4b2e83",
                                          //marginBottom: 13,
                                        }}
                                        variant="outlined"
                                        placeholder="Zone"
                                        InputProps={{
                                          className: classes.input,
                                        }}
                                        InputLabelProps={{
                                          className: classes.label,
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <div
                                        style={{
                                          //padding: "10px 10px 0px 10px",
                                          display: "flex",
                                          width: "100%",
                                        }}
                                      >
                                        <TextField
                                          id="sync"
                                          disabled
                                          label="Last Heard"
                                          className={classes.textField}
                                          value={this.state.syncTime}
                                          type="text"
                                          style={{
                                            borderColor: "#4b2e83",
                                            marginBottom: 13,
                                          }}
                                          margin="dense"
                                          variant="outlined"
                                          placeholder="Last Heard"
                                          //placeholder={this.props.selectedAssetDetail.DeviceFlag !== "NOT_TRACEABLE" ?"Sync Time" : ""}
                                          InputProps={{
                                            className: classes.input,
                                          }}
                                          InputLabelProps={{
                                            className: classes.label,
                                            shrink: true,
                                          }}
                                        />
                                        <Tooltip
                                          title="Sync"
                                          className="float-right"
                                        >
                                          <span>
                                            <IconButton
                                              style={{
                                                color: "#4b2e83",
                                                opacity: "1",
                                                outline: "none",
                                              }}
                                              disabled={
                                                this.state.isCurrentlySyncing
                                              }
                                              aria-label="Sync"
                                              onClick={() =>
                                                this.setState(
                                                  { isCurrentlySyncing: true },
                                                  () =>
                                                  this.getAssetFromMeridian(
                                                    this.state.data
                                                      .MeridianLocationId,
                                                      this.state.data
                                                      .MacId
                                                  )
                                                )
                                              }
                                            >
                                              <RefreshIcon />
                                            </IconButton>
                                          </span>
                                        </Tooltip>
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <div className="justify-content-between align-items-center">
                                    {/* <h6 style={{ width: "100%", paddingLeft: 2 }}>
                                      Floor  {FloorNumber}
                                      {this.state.meridiandata.map.level_label}
                                    </h6> */}
                                    <div className="map" id="meridian-map-full" />
                                  </div>
                                </div>
                              )
                            }
                            {this.state.index !== 0 && (
                              <div /*style={{ padding: "10px 0" }}*/>
                                <Grid container spacing={2}>
                                  <Grid item xs={3}>
                                    <TextField
                                      id="building-name"
                                      label="Campus"
                                      disabled={true}
                                      type="text"
                                      margin="dense"
                                      value={MeridianBuildingName}
                                      style={{
                                        width: "100%",
                                        borderColor: "#4b2e83",
                                        marginBottom: 13,
                                      }}
                                      variant="outlined"
                                      placeholder="Campus"
                                      InputProps={{ className: classes.input }}
                                      InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                      id="floor"
                                      label="Floor"
                                      disabled={true}
                                      type="text"
                                      margin="dense"
                                      value={FloorLabel}
                                      style={{
                                        width: "100%",
                                        borderColor: "#4b2e83",
                                        marginBottom: 13,
                                      }}
                                      variant="outlined"
                                      placeholder="Floor"
                                      InputProps={{ className: classes.input }}
                                      InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                      margin="dense"
                                      id="outlined-name"
                                      label="Zone"
                                      value={
                                        this.state.data.ZoneName
                                          ? this.state.data
                                              .ZoneName
                                          : "No Zone"
                                      }
                                      type="text"
                                      disabled
                                      style={{
                                        width: "100%",
                                        borderColor: "#4b2e83",
                                        //marginBottom: 13,
                                      }}
                                      variant="outlined"
                                      placeholder="Zone"
                                      InputProps={{ className: classes.input }}
                                      InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                      id="lastheard"
                                      label="Last Heard"
                                      disabled={true}
                                      type="text"
                                      margin="dense"
                                      // value={this.props.lastSync.toString()}
                                      //value={lastKnownSync}
                                      //value={/*his.lastKnownModified*/ this.state.syncTime}
                                      value={Timestamp ? formatDate(new Date(Timestamp*1000)) : "Unknown"}
                                      style={{
                                        width: "100%",
                                        borderColor: "#4b2e83",
                                        marginBottom: 13,
                                      }}
                                      variant="outlined"
                                      placeholder="Last Heard"
                                      InputProps={{ className: classes.input }}
                                      InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                                <div className="justify-content-between align-items-center">
                                  {/* <h6 style={{ width: "100%", paddingLeft: 2 }}>
                                    {/* Floor  {FloorNumber}*
                                    {this.props.selectedAssetDetail.FloorNumber}
                                  </h6> */}
                                  <div className="map" id="meridian-map-full" />
                                </div>
                              </div>
                            )}
                          </Paper>
                        ) : (
                          <div
                            className={classes.root}
                            style={{
                              width: "100%",
                              height: "500px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 30,
                            }}
                          >
                            <h4>There's no map uploaded for this Device.</h4>
                          </div>
                        )
                      }
                      </Paper>
                    </Collapse>
                  </Dialog>
                </div>
              </React.Fragment>
            )}
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              marginTop: 100,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              style={{ width: "80px", height: "80px" }}
              className={classes.progress}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { entities, collections, locations, assets, deviceFlags } = state;
  return {
    entities,
    collections,
    locations,
    assets,
    deviceFlags
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEntities: () => getEntity(dispatch),
    getLocation: entityId => getLocations(dispatch, entityId),
    getCollection: (entityId, locationId) => getCollections(dispatch, entityId, locationId),
    getAsset: () => getAssets(dispatch),
    getFlags: () => getDeviceFlags(dispatch),
    resetData: () => dispatch(cleanData()),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Configure);
