import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  getEntity,
  getLocations,
  getCollections,
  getAssetType,
  getAssetModel,
  getAssets,
  getDeviceFlags,
  getAssetManufacturer,
} from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import ResetIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { apiRequest, byName, fetchRequest } from "../utils/Utils";
import { withRouter } from "react-router";

const batteryLevelOptions = [
  { id: 0, name: "Battery < 30%", value: "0,29" },
  { id: 1, name: "Battery 30 - 60%", value: "30,60" },
  { id: 2, name: "Battery < 60%", value: "0,59" },
  { id: 3, name: "Battery 61 - 90%", value: "61,90" },
  { id: 4, name: "Battery > 90%", value: "91,100" },
  { id: 5, name: "Battery Unknown", value: "101,9999" },
];

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    height: 38,
  },
  inputLabel: {
    //marginTop: "-5px",
  },
  paper: {
    padding: theme.spacing.unit * 2,
    width: "100%",
    color: theme.palette.text.secondary,
  },
  fab: {
    backgroundColor: "#4b2e83",
    color: "white",
    margin: theme.spacing.unit,
    autoCapitalize: false,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  textField: { width: "100%" },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class Filters extends Component {
  constructor(props) {
    super(props);
    let oldState = sessionStorage.getItem("filter") ? JSON.parse(sessionStorage.getItem("filter")) : null;
    //oldState = { ...oldState, ...this.props.location.state };
    this.state = {
      //loc: "",
      dept: oldState && oldState.dept ? oldState.dept : "",
      manufacturer: oldState && oldState.manufacturer ? oldState.manufacturer : "",
      type: oldState && oldState.type ? oldState.type : "",
      //entity: "",
      model: oldState && oldState.model ? oldState.model : "",
      flag: oldState && oldState.flag ? oldState.flag : "",
      batteryLevel: oldState && oldState.batteryLevel ? oldState.batteryLevel : "",
      zone: oldState && oldState.zone ? oldState.zone : "",
      assetTypeData: [],
      entityData: [],
      locationData: [],
      collectionData: [],
      assetModelData: [],
      assetManufacturerData: [],
      flagData: [],
      zoneData: [],
      searchStringFromTextBox: props.searchString,

      entity: sessionStorage.getItem("entityID")
        ? JSON.parse(sessionStorage.getItem("entityID"))
        : "",
      loc: sessionStorage.getItem("locationID")
        ? JSON.parse(sessionStorage.getItem("locationID"))
        : "",
      // dept: sessionStorage.getItem("departmentID")
      //   ? JSON.parse(sessionStorage.getItem("departmentID"))
      //   : "",
      coll: sessionStorage.getItem("collectionID")
        ? JSON.parse(sessionStorage.getItem("collectionID"))
        : "",
      floor: sessionStorage.getItem("floorID")
        ? sessionStorage.getItem("floorID")
        : "",
      building: sessionStorage.getItem("buildingID")
        ? sessionStorage.getItem("buildingID")
        : ""
    };
    this.k = 1;
    //this.zones = [];
  }

  handleChange = (name) => (event, value) => {
    //console.log("heloka", event, value);
    var param = "";
    if (value !== null) {
      switch (name) {
        case "dept":
          param = value.id;
          this.props.values.setCollectionEnable(false);
          break;
        case "batteryLevel":
          param = value.value;
          break;
        case "model":
          param = value.name;
          break;
        case "type":
          param = value.name;
          break;
        case "flag":
          param = value.name
          break;
        case "manufacturer":
          param = value.name
          break;
        case "zone":
          param = value.name
          break;
        //default:
        //  break;
      }
    }
    else {
      if (name == "dept") {
        this.props.values.setCollectionEnable(true);
      }
    }
    if (param) {
    //  this.props.history.push({
    //    pathname: this.props.location.pathname,
    //    state: {
    //      ...this.props.location.state,
    //      [name]: param
    //    }
    //  });
    } else {
      //Remove from state
    //  let oldState = {...this.props.location.state};
    //  delete oldState[name];
    //  const isEmpty = Object.keys(oldState).length === 0;
    //  oldState = isEmpty ? undefined : oldState;
    //  this.props.history.push({
    //    pathname: this.props.location.pathname,
    //    state: oldState
    //  });
      this.props.setLoading(false);
    }
    const newState = JSON.stringify({
      dept: this.state.dept ? this.state.dept : undefined,
      batteryLevel: this.state.batteryLevel ? this.state.batteryLevel : undefined,
      zone: this.state.zone ? this.state.zone : undefined,
      manufacturer: this.state.manufacturer ? this.state.manufacturer : undefined,
      model: this.state.model ? this.state.model : undefined,
      type: this.state.type ? this.state.type : undefined,
      flag: this.state.flag ? this.state.flag : undefined,
      [name]: param ? param : undefined
    });
    //if (param || this.state.dept || this.state.batteryLevel || this.state.zone || this.state.manufacturer || this.state.model || this.state.type || this.state.flag) {
    if (newState && newState.length > 2)
      sessionStorage.setItem("filter", newState);
    else
      sessionStorage.removeItem("filter");
    this.searchParam = "";
    this.setState(
      {
        //[name]: event.target.value,
        //[name]: value !== null ? value.id : "",
        [name]: param,
      },
      () => {
        if (name === "entity") {
          this.props.getLocation(event.target.value);
          this.props.getCollection(event.target.value);
          // this.searchParam += `EntityId=${event.target.value}&`;
          // this.props.getAsset(this.searchParam);
        } else if (name === "loc") {
          this.props.getCollection(this.state.entity, event.target.value);
        }
        this.createSearchParameters();
      }
    );
  };

  createSearchParameters() {
    const { entity, coll, loc, building, floor } = this.props.values;
    this.searchParam = "";
    if (entity !== "")
      this.searchParam += `entity_id=${entity}&`;
    if (this.state.type !== "")
      this.searchParam += `type=${this.state.type}&`;
    if (this.state.model !== "")
      this.searchParam += `model=${this.state.model}&`;
    if (this.state.manufacturer !== "")
      this.searchParam += `manufacturer=${this.state.manufacturer}&`;
    if (this.state.zone !== "")
      this.searchParam += `zone_name=${this.state.zone}&`;
    if (loc !== "")
      this.searchParam += `location_id=${loc}&`;
    if (building !== "")
      this.searchParam += `meridian_building_id=${building}&`;
    if (this.state.dept !== "")
      this.searchParam += `dept_id=${this.state.dept}&`;
    if (coll !== "")
      this.searchParam += `collection_id=${coll}&`;
    if (this.state.flag !== "")
      this.searchParam += `device_flag=${this.state.flag}&`;
    if (floor !== "")
      this.searchParam+= `meridian_map_id=${floor}&`;
    if (this.state.batteryLevel !== "")
      this.searchParam += `battery_level_between=${this.state.batteryLevel}&`;
    if (
      this.state.searchStringFromTextBox !== "" &&
      this.state.searchStringFromTextBox !== undefined
    )
      this.searchParam += `searchType=wildcard&param=${encodeURIComponent(
        this.state.searchStringFromTextBox
      )}&`;
    console.log("search", this.searchParam);
    this.props.getAsset(this.searchParam.slice(0, -1));
  }

  async getZones() {
    let r = null, next = null, body = null, zones = [];
    try {
      do {
        r = await fetchRequest("/zones", "get", next);
        if (r.ok) {
          if (r.headers.get('x-ms-continuation-nextrowkey')) {
            next = {
              "x-ms-continuation-nextpartitionkey" : r.headers.get('x-ms-continuation-nextpartitionkey'),
              "x-ms-continuation-nextrowkey" : r.headers.get('x-ms-continuation-nextrowkey')
            };
          } else {
            next = null;
          }
          body = await r.json();
          if (body && body.value)
            zones = zones.concat(body.value);
        }
      } while (next);
      this.setState({ zoneData: zones });
    } catch (error) {
      console.error(error);
    }
  }

  componentWillMount() {
    this.props.getEntities();
    this.props.getAssetTypes();
    this.props.getAssetModels();
    this.props.getAssetManufacturers();
    this.props.getFlags();
    this.getZones();

    if (this.state.entity !== "" && this.state.loc !== "") {
      this.props.getCollection(this.state.entity, this.state.loc);
    }
  }

  componentDidMount() {
    apiRequest(`/accesscontrol`, "get").then((x) => {
      if (x.data.length == 0) {
        this.props.history.push("/noaccess");
      } else {
        if (this.props.params === "") {
          //this.props.getAsset();
          this.createSearchParameters();
        } else {
          if (this.props.params.includes("BATTERY")) {
            if (this.props.params.includes("id")) {
              this.props.getAsset(
                `entity_id=${this.props.params.substring(
                  4,
                  this.props.params.indexOf("&")
                )}&battery_level_between=0,29`
              );
            } else {
              this.props.getAsset(`battery_level_between=0,29`);
            }
          } else if (this.props.params.includes("NOT_TRACEABLE")) {
            this.props.getAsset(
              `entity_id=${this.props.params.substring(
                4,
                this.props.params.indexOf("&")
              )}&device_flag=NOT_TRACEABLE`
            );
          } else if (this.props.params.includes("MISSING_SYNC")) {
            this.props.getAsset(
              `entity_id=${this.props.params.substring(
                4,
                this.props.params.indexOf("&")
              )}&device_flag=MISSING_SYNC`
            );
          } else if (this.props.params.includes("HEALTHY")) {
            this.props.getAsset(
              `entity_id=${this.props.params.substring(
                4,
                this.props.params.indexOf("&")
              )}&device_flag=HEALTHY`
            );
          } else if (this.props.params.includes("id")) {
            this.props.getAsset(
              `entity_id=${this.props.params.substring(
                4,
                this.props.params.indexOf("&")
              )}`
            );
          }
        }
      }
    });
  }

  componentWillReceiveProps({
    entities,
    locations,
    collections,
    assetTypes,
    assetModel,
    assetManufacturer,
    searchString,
    deviceFlags,
    params,
    values
  }) {
    if (values) {
      let old_entity = this.state.entity;
      let old_loc = this.state.loc;
      if (
        values.entity !== this.state.entity ||
        values.coll !== this.state.coll ||
        values.loc !== this.state.loc ||
        values.building !== this.state.building ||
        values.floor !== this.state.floor
      ) {
        this.setState(
          {
            entity: values.entity,
            coll: values.coll,
            loc: values.loc,
            building: values.building,
            floor: values.floor
          },
          () => {
            if (old_entity !== this.state.entity || old_loc !== this.state.loc) {
              this.setState({ dept: "" }, () => {
                this.createSearchParameters();
                values.setCollectionEnable(true);
              });
              if (this.state.entity && this.state.loc) {
                this.props.getCollection(this.state.entity, this.state.loc);
              }
            } else {
              this.createSearchParameters();
            }
          }
        );
      }
    }
    this.setState({
      entityData: entities,
      locationData: locations,
      collectionData: collections,
      assetTypeData: assetTypes || [],
      assetModelData: assetModel,
      assetManufacturerData: assetManufacturer || [],
      flagData: deviceFlags
    });
    if (params != "") {
      let flag = "";
      let batteryLevel = "";
      if (params.includes("NOT_TRACEABLE")) {
        flag = "NOT_TRACEABLE";
      } else if (params.includes("MISSING_SYNC")) {
        flag = "MISSING_SYNC";
      } else if (params.includes("HEALTHY")) {
        flag = "HEALTHY";
      } else if (params.includes("LOW_BATTERY_30")) {
        batteryLevel = "0,29";
      }
      if (this.k == 1) {
        this.setState(
          {
            // entity: params.includes("id")
            //   ? parseInt(params.substring(4, params.indexOf("&")))
            //   : "",
            flag,
            batteryLevel,
          },
          () => {
            this.k = 2;
            if (params.includes("id")) {
              this.props.getLocation(params.substring(4, params.indexOf("&")));
              this.props.getCollection(
                params.substring(4, params.indexOf("&"))
              );
            }
          }
        );
      }
    }
    if (this.state.searchStringFromTextBox != searchString) {
      this.setState(
        {
          searchStringFromTextBox: searchString,
        },
        () => {
          this.createSearchParameters();
        }
      );
    }
  }

  handleReset = () => {
    this.props.setLoading(false);
    this.props.resetSearchText();
    this.searchParam = "";
    this.props.values.setCollectionEnable(true);
    this.props.history.push({
      pathname: this.props.location.pathname,
      state: undefined
    });
    sessionStorage.removeItem("filter");
    this.setState(
      {
        //entity: "",
        //loc: "",
        dept: "",
        batteryLevel: "",
        zone: "",
        manufacturer: "",
        model: "",
        type: "",
        flag: ""
      },
      () => {
        this.createSearchParameters();
      }
    );
  };

  render() {
    const { classes } = this.props;
    //console.log("heloka", this.state.collectionData);
    var deptId = parseInt(this.state.dept, 10);
    var dObj = this.state.collectionData.find(e => e.id == deptId);
    dObj = dObj == undefined ? null : dObj;
    var bObj = batteryLevelOptions.find(e => e.value == this.state.batteryLevel);
    bObj = bObj == undefined ? null : bObj;
    var mObj = this.state.assetModelData.find(e => e.name == this.state.model);
    mObj = mObj == undefined ? null : mObj;
    var tObj = this.state.assetTypeData.find(e => e.name == this.state.type);
    tObj = tObj == undefined ? null : tObj;
    var manObj = this.state.assetManufacturerData.find(e => e.name == this.state.manufacturer);
    manObj = manObj == undefined ? null : manObj;
    var fObj = this.state.flagData.find(e => e.name == this.state.flag);
    fObj = fObj == undefined ? null : fObj;
    var zoneObj = this.state.zoneData.find(e => e.name == this.state.zone);
    zoneObj = zoneObj == undefined ? null : zoneObj;
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <div
          //className="justify-content-between border-top align-items-center pt-1 pb-2 mb-2"
          style={{
            paddingLeft: "12px",
            paddingRight: "12px",
          }}
        >
          <div>
            {/* <h1 className="jumbotron-heading">Filters</h1> */}
            {/* <Grid container spacing={24}>
              <Grid
                item
                xs={8}
                sm={6}
                md={4}
                lg={3}
                style={{ paddingBottom: "0px" }}
              >
                <TextField
                  width="100%"
                  id="outlined-select"
                  select
                  margin="dense"
                  label="Organization"
                  className={classes.textField}
                  value={this.state.entity}
                  InputLabelProps={{
                    className: classes.inputLabel
                  }}
                  InputProps={{ className: classes.input }}
                  onChange={this.handleChange("entity")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  // helperText="Please select the Organization"
                  variant="outlined"
                >
                  {this.state.entityData.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs={8}
                sm={6}
                md={4}
                lg={3}
                style={{ paddingBottom: "0px" }}
              >
                <TextField
                  // disabled={this.state.locationData.length === 0}
                  width="100%"
                  margin="dense"
                  id="outlined-select"
                  select
                  label="Building"
                  className={classes.textField}
                  value={this.state.loc}
                  onChange={this.handleChange("loc")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel
                  }}
                  InputProps={{ className: classes.input }}
                  // helperText="Please select the Location"
                  variant="outlined"
                >
                  {this.state.locationData.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs={8}
                sm={6}
                md={4}
                lg={3}
                style={{ paddingBottom: "0px" }}
              >
                <TextField
                  // disabled={this.state.collectionData.length === 0}
                  width="100%"
                  margin="dense"
                  id="outlined-select"
                  select
                  label="Department/Collection"
                  className={classes.textField}
                  value={this.state.dept}
                  InputLabelProps={{
                    className: classes.inputLabel
                  }}
                  InputProps={{ className: classes.input }}
                  onChange={this.handleChange("dept")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  // helperText="Please select the Department/Collection"
                  variant="outlined"
                >
                  {this.state.collectionData.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                item
                xs={8}
                sm={6}
                md={4}
                lg={3}
                style={{ paddingBottom: "0px" }}
              >
                <TextField
                  // disabled={this.state.istypedisabled}
                  width="100%"
                  margin="dense"
                  id="outlined-select"
                  select
                  label="Asset Type"
                  className={classes.textField}
                  value={this.state.type}
                  InputLabelProps={{
                    className: classes.inputLabel
                  }}
                  InputProps={{ className: classes.input }}
                  onChange={this.handleChange("type")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  // helperText="Please select the Asset Type"
                  variant="outlined"
                >
                  {this.state.assetTypeData.map(option => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs={8}
                sm={6}
                md={4}
                lg={3}
                style={{ paddingBottom: "0px", paddingTop: "0px" }}
              >
                <TextField
                  // disabled={this.state.istypedisabled}
                  width="100%"
                  margin="dense"
                  id="outlined-select"
                  select
                  label="Asset Model"
                  className={classes.textField}
                  value={this.state.model}
                  InputLabelProps={{
                    className: classes.inputLabel
                  }}
                  InputProps={{ className: classes.input }}
                  onChange={this.handleChange("model")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  // helperText="Please select the Asset Type"
                  variant="outlined"
                >
                  {this.state.assetModelData.map(option => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs={8}
                sm={6}
                md={4}
                lg={3}
                style={{ paddingBottom: "0px", paddingTop: "0px" }}
              >
                <TextField
                  // disabled={this.state.istypedisabled}
                  width="100%"
                  margin="dense"
                  id="outlined-select"
                  select
                  label="Device Flag"
                  className={classes.textField}
                  value={this.state.flag}
                  InputLabelProps={{
                    className: classes.inputLabel
                  }}
                  InputProps={{ className: classes.input }}
                  onChange={this.handleChange("flag")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  // helperText="Please select the Asset Type"
                  variant="outlined"
                >
                  {this.state.flagData.map(option => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name == "NOT_MAPPED"
                        ? "NO DEPARTMENT"
                        : option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs={8}
                sm={6}
                md={4}
                lg={3}
                style={{ paddingBottom: "0px", paddingTop: "0px" }}
              >
                <TextField
                  // disabled={this.state.istypedisabled}
                  width="100%"
                  margin="dense"
                  id="outlined-select"
                  select
                  label="Battery Level"
                  className={classes.textField}
                  value={this.state.batteryLevel}
                  InputLabelProps={{
                    className: classes.inputLabel
                  }}
                  InputProps={{ className: classes.input }}
                  onChange={this.handleChange("batteryLevel")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  // helperText="Please select the Asset Type"
                  variant="outlined"
                >
                  {batteryLevelOptions.map(option => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs={8}
                sm={6}
                md={4}
                lg={3}
                style={{ paddingBottom: "0px", paddingTop: "0px" }}
              >
                <Tooltip title="Reset">
                  <IconButton
                    style={{
                      color: "#4b2e83",
                      opaCity: "1",
                      outline: "none"
                    }}
                    // disabled={!this.stateChange()}
                    aria-label="Reset"
                    onClick={this.handleReset}
                  >
                    <ResetIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid> */}
          </div>
        </div>
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: "4px",
          }}
        >
          <Grid item /*xs={2}*/ style={{ width: `${(60/7/72)*100}%` }}>
            <Tooltip
              title={
                this.state.entity && this.state.loc
                  ? this.state.coll
                    ? "Please ensure Collection is deselected before selecting a Department"
                    : ""
                  : "Please ensure Campus is selected before selecting a Department"
              }
              placement="bottom"
            >
              <Autocomplete
                disabled={this.state.entity && this.state.loc && !this.state.coll ? false : true}
                id="deptbox"
                options={this.state.collectionData.sort(byName)}
                value={dObj}
                getOptionSelected={((option, value) => option.id == value.id)}
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <div style={{ padding: "8px 2px" }}>
                    {option.name}
                  </div>
                )}
                onChange={this.handleChange("dept")}
                renderInput={(params) => (
                  <TextField {...params} label="Department" variant="outlined" margin="dense" className={classes.textField} />
                )}
              />
              {/* <TextField
                // disabled={this.state.collectionData.length === 0}
                width="100%"
                margin="dense"
                id="outlined-select"
                select
                label="Department"
                className={classes.textField}
                value={this.state.dept}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                InputProps={{ className: classes.input }}
                onChange={this.handleChange("dept")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                // helperText="Please select the Department/Collection"
                variant="outlined"
              >
                {this.state.collectionData.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField> */}
            </Tooltip>
          </Grid>
          <Grid item /*xs={2}*/ style={{ width: `${(60/7/72)*100}%` }}>
            <Autocomplete
              id="batterybox"
              options={batteryLevelOptions}
              value={bObj}
              getOptionSelected={((option, value) => option.value == value.value)}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <div style={{ padding: "8px 2px" }}>
                  {option.name}
                </div>
              )}
              onChange={this.handleChange("batteryLevel")}
              renderInput={(params) => (
                <TextField {...params} label="Battery Level" variant="outlined" margin="dense" className={classes.textField} />
              )}
            />
            {/* <TextField
              // disabled={this.state.istypedisabled}
              width="100%"
              margin="dense"
              id="outlined-select"
              select
              label="Battery Level"
              className={classes.textField}
              value={this.state.batteryLevel}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              InputProps={{ className: classes.input }}
              onChange={this.handleChange("batteryLevel")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              // helperText="Please select the Asset Type"
              variant="outlined"
            >
              {batteryLevelOptions.map((option) => (
                <MenuItem key={option.id} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField> */}
          </Grid>

          <Grid item /*xs={2}*/ style={{ width: `${(60/7/72)*100}%` }}>
            <Autocomplete
              id="zonebox"
              options={this.state.zoneData.sort(byName)}
              value={zoneObj}
              getOptionSelected={((option, value) => option.name == value.name)}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <div style={{ padding: "8px 2px" }}>
                  {option.name}
                </div>
              )}
              onChange={this.handleChange("zone")}
              renderInput={(params) => (
                <TextField {...params} label="Zone" variant="outlined" margin="dense" className={classes.textField} />
              )}
            />
          </Grid>

          <Grid item /*xs={2}*/ style={{ width: `${(60/7/72)*100}%` }}>
            <Autocomplete
              id="manufacturerbox"
              options={this.state.assetManufacturerData.sort(byName)}
              value={manObj}
              getOptionSelected={((option, value) => option.name == value.name)}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <div style={{ padding: "8px 2px" }}>
                  {option.name}
                </div>
              )}
              onChange={this.handleChange("manufacturer")}
              renderInput={(params) => (
                <TextField {...params} label="Manufacturer" variant="outlined" margin="dense" className={classes.textField} />
              )}
            />
          </Grid>

          <Grid item /*xs={2}*/ style={{ width: `${(60/7/72)*100}%` }}>
            <Autocomplete
              id="modelbox"
              options={this.state.assetModelData.sort(byName)}
              value={mObj}
              getOptionSelected={((option, value) => option.name == value.name)}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <div style={{ padding: "8px 2px" }}>
                  {option.name}
                </div>
              )}
              onChange={this.handleChange("model")}
              renderInput={(params) => (
                <TextField {...params} label="Model" variant="outlined" margin="dense" className={classes.textField} />
              )}
            />
            {/* <TextField
              // disabled={this.state.istypedisabled}
              width="100%"
              margin="dense"
              id="outlined-select"
              select
              label="Asset Model"
              className={classes.textField}
              value={this.state.model}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              InputProps={{ className: classes.input }}
              onChange={this.handleChange("model")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              // helperText="Please select the Asset Type"
              variant="outlined"
            >
              {this.state.assetModelData.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField> */}
          </Grid>
          <Grid item /*xs={2}*/ style={{ width: `${(60/7/72)*100}%` }}>
            <Autocomplete
              id="typebox"
              options={this.state.assetTypeData.sort(byName)}
              value={tObj}
              getOptionSelected={((option, value) => option.name == value.name)}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <div style={{ padding: "8px 2px" }}>
                  {option.name}
                </div>
              )}
              onChange={this.handleChange("type")}
              renderInput={(params) => (
                <TextField {...params} label="Type" variant="outlined" margin="dense" className={classes.textField} />
              )}
            />
            {/* <TextField
              // disabled={this.state.istypedisabled}
              width="100%"
              margin="dense"
              id="outlined-select"
              select
              label="Asset Type"
              className={classes.textField}
              value={this.state.type}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              InputProps={{ className: classes.input }}
              onChange={this.handleChange("type")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              // helperText="Please select the Asset Type"
              variant="outlined"
            >
              {this.state.assetTypeData.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField> */}
          </Grid>
          <Grid item /*xs={2}*/ style={{ width: `${(60/7/72)*100}%` }}>
            <Autocomplete
              id="flagbox"
              options={this.state.flagData.sort(byName)}
              value={fObj}
              getOptionSelected={((option, value) => option.name == value.name)}
              getOptionLabel={(option) => option.name == "NOT_MAPPED" ? "NO DEPARTMENT" : option.name}
              renderOption={(option) => (
                <div style={{ padding: "8px 2px" }}>
                  {option.name == "NOT_MAPPED" ? "NO DEPARTMENT" : option.name}
                </div>
              )}
              onChange={this.handleChange("flag")}
              renderInput={(params) => (
                <TextField {...params} label="Device Flag" variant="outlined" margin="dense" className={classes.textField} />
              )}
            />
            {/* <TextField
              // disabled={this.state.istypedisabled}
              width="100%"
              margin="dense"
              id="outlined-select"
              select
              label="Device Flag"
              className={classes.textField}
              value={this.state.flag}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              InputProps={{ className: classes.input }}
              onChange={this.handleChange("flag")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              // helperText="Please select the Asset Type"
              variant="outlined"
            >
              {this.state.flagData.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name == "NOT_MAPPED" ? "NO DEPARTMENT" : option.name}
                </MenuItem>
              ))}
            </TextField> */}
          </Grid>
          <Grid
            item
            //xs={2}
            xs
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              onClick={this.handleReset}
              style={{
                color: "#4b2e83",
                //opacity: "1",
                //outline: "none"
              }}
              //className="buttonnobg float-center"
            >
              Clear
            </Button>
          </Grid>
          <Grid item /*xs={6} md={4} lg={8}*/ style={{ "textAlign": "end", alignSelf: "center", width: "24px", marginLeft: "8px" }}>

          </Grid>
        </Grid>
      </div>
    );
  }
}

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    entities,
    collections,
    locations,
    assetTypes,
    assetModel,
    assetManufacturer,
    deviceFlags,
  } = state;
  return {
    entities,
    collections,
    locations,
    assetTypes,
    assetModel,
    assetManufacturer,
    deviceFlags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEntities: () => getEntity(dispatch),
    getLocation: (entityId) => getLocations(dispatch, entityId),
    getCollection: (entityId, locationId = "") => getCollections(dispatch, entityId, locationId),
    getAssetTypes: () => getAssetType(dispatch),
    getAssetModels: () => getAssetModel(dispatch),
    getAssetManufacturers: () => getAssetManufacturer(dispatch),
    getAsset: (params) => getAssets(dispatch, params),
    getFlags: () => getDeviceFlags(dispatch),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter
)(Filters);
