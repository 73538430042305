import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { apiRequest, PASSWORD_REGEX, PASSWORD_ERROR } from "../utils/Utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { showMessage } from "../actions/index";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";
import SubmitIcon from "@material-ui/icons/CheckCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import ResetIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Cookies from "js-cookie";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { MsalContext, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { pca } from "../index.js";

const tableHeader = [
  { id: "0", label: "Resource Name" },
  { id: "1", label: "Resource Type" },
  { id: "2", label: "Access Level" }
];

const styles = theme => ({
  input: {
    height: 38,
    color: "#111"
  },
  label: {
    marginTop: "-5px",
    color: "#111"
  },
  button: {
    margin: theme.spacing.unit,
    paddingTop: 10,
    paddingBottom: 10
  },

  textField: { width: "100%" },
  menu: {
    width: "100%"
  }
});

let Msg = "<h5/>"
class UserProfile extends Component {
  static contextType = MsalContext;
  state = {
    name: "",
    userid: "",
    email: "",
    phone: "",
    imageUrl: "",
    permissions: [],
    usersData: [],
    password: "",
    confirmPassword: "",
    oldPassword: "",
    editName: "",
    editEmail: "",
    editPhone: "",
    open: false,
    show: false,
    editDetails: false,
    selectedFile: null,
    uploadImg: false,
    microsoftOnly: false
  };

  componentDidMount() {
    this.getUserDetail();
  }

  async getToken() {
    var request = {
      account: pca.getAllAccounts()[0],
      scopes: ["User.Read"]
    };
    let apiToken = await pca.acquireTokenSilent(request)
      .then((tokenResponse) => {
        return tokenResponse.accessToken;
      })
      .catch((error) => {
        return pca.acquireTokenRedirect(request);
      });

      return apiToken;
  }

  arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b) => binary += String.fromCharCode(b));

    return window.btoa(binary);
  }

  async getDataFromGraph(u='https://graph.microsoft.com/v1.0/me') {
    let url = u;
    let headers = new Headers();
    const t = await this.getToken();
    headers.append('Authorization', `Bearer ${t}`);
    let options= {
      'method': 'get',
      'headers': headers,
    }
      return fetch(url, options)
      .then(r => r.json())
      .then(r => r)
      .catch(er => console.log(er))
  }

  async getPhotoFromGraph(u='https://graph.microsoft.com/v1.0/me') {
    let url = u;
    let headers = new Headers();
    const t = await this.getToken();
    headers.append('Authorization', `Bearer ${t}`);
    let options = {
      'method': 'get',
      'headers': headers,
      'responseType': 'arraybuffer'
    }
      return fetch(url, options)
        .then(r => {
          if (r.ok)
            return r;
          return "";
        })
        .then((response) => {
          let link = ""
          if (response != "") {
            response.arrayBuffer().then((buffer) => {
              var base64Flag = 'data:image/jpeg;base64,';
              var imageStr = this.arrayBufferToBase64(buffer);
              link = base64Flag + imageStr;
              console.log("imgA", link);
              this.setState({ imageUrl: link, show: true });
              return link;
            });
            return link;
          }
          else {
            this.setState({ imageUrl: "/white.png", show: true });
          }
      })
      .catch(er => console.log(er));
  }

  async getUserDetail() {
    let that = this;
    apiRequest(`/users/${localStorage.getItem("user")}`, "get")
      .then(response => {
        if (response.status == 0 || (response.message && response.message.toLowerCase().includes("error"))) {
          Msg = <h5>User not authorized to access the application.</h5>
          //IF login in with microsoft && no user
          if (this.context.accounts.length > 0) {
            console.log("response", this.context.accounts);
            //call graph
            this.getDataFromGraph()
              .then(d => {
                that.setState(
                  {
                    name: this.context.accounts[0].name,
                    imageUrl: "",
                    email: this.context.accounts[0].username,
                    userid: this.context.accounts[0].username,
                    phone: d && d.mobilePhone? d.mobilePhone : "",
                    permissions: "",
                    editName: "",
                    editEmail: "",
                    editPhone: "",
                    show: true,
                    microsoftOnly: true
                  },
                  () =>
                    this.getPhotoFromGraph('https://graph.microsoft.com/v1.0/me/photo/$value')
                      .then(r => r)
                      .catch(er => console.log(er))
                );
              });
          }
          else {
            that.setState({
              name: "",
              imageUrl: "",
              email: "",
              userid: "",
              phone: "",
              permissions: "",
              editName: "",
              editEmail: "",
              editPhone: "",
              show: false,
              //microsoftOnly: true
            });
          }
          return;
        }
          Msg = <h5>You don't have access to any resources. Please contact <a href="mailto:assettracker@uw.edu"> System Administrator</a> to request access.</h5>
            that.setState(
              {
                name: response.data[0].FullName,
                imageUrl: response.data[0].ImageURL,
                email: response.data[0].Email,
                userid: response.data[0].UserId,
                phone: response.data[0].PhoneNumber,
                permissions: response.data[0].Permissiones,
                editName: response.data[0].FullName,
                editEmail: response.data[0].Email,
                editPhone: response.data[0].PhoneNumber,
                microsoftOnly: response.data[0].IsOfficeUser ? true : false,
                // show: true
              },
              () => {
                if (response.data[0].IsOfficeUser) {
                  this.getPhotoFromGraph('https://graph.microsoft.com/v1.0/me/photo/$value')
                  .then(r => r)
                  .catch(er => console.log(er))
                } else {
                  that.setState({ show: true });
                }
              }
            );
      })
      .catch(error => {
        that.setState({
          name: "",
          imageUrl: "",
          email: "",
          userid: "",
          phone: "",
          permissions: "",
          editName: "",
          editEmail: "",
          editPhone: "",
          show: false
        });
      });
  }

  setPassword() {
    const { password, confirmPassword, oldPassword } = this.state;

    if (password == "" || confirmPassword == "" || oldPassword == "") {
      this.props.showMessageBox("Fields should not be empty");
      return;
    }
    else {
      if (password !== confirmPassword) {
        this.props.showMessageBox(
          "Confirm Password should match with New Password"
        );
        return;
      }
      if (!PASSWORD_REGEX.test(password) || !PASSWORD_REGEX.test(confirmPassword)) {
        this.props.showMessageBox(PASSWORD_ERROR);
        return;
      }
    }
    const data = {
      UserId: this.state.userid,
      OldPasswordBase64: btoa(oldPassword),
      NewPasswordBase64: btoa(password),
      NewPasswordConfirmBase64: btoa(confirmPassword)
    };

    apiRequest(`/users/${this.state.userid}/password/0`, "post", data)
      .then(res => {
        if (res.status > 0) {
          this.props.showMessageBox("SUCCESS");
          // Cookies.remove(`owAuth${window.location.port}`);
          // localStorage.clear();
          // this.props.history.push("/");
        } else {
          this.props.showMessageBox(res.message);
        }
        this.setState({
          open: false
        });
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
  }

  handleEdit() {
    const data = {
      UserId: this.state.userid,
      FullName: this.state.editName,
      Email: this.state.editEmail,
      PhoneNumber: this.state.editPhone
    };
    apiRequest(`/users/${this.state.userid}`, "put", data)
      .then(res => {
        if (res.status > 0) {
          this.getUserDetail();
          this.props.showMessageBox("SUCCESS");
        } else {
          this.props.showMessageBox(res.message);
        }
        this.setState({
          editDetails: false
        });
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
  }

  uploadHandler = () => {
    const formData = new FormData();
    formData.append(
      "myFile",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    const tokenJson = Cookies.getJSON(`owAuth${window.location.port}`) || "";
    const token = tokenJson.token;
    var headers = {
      Authorization: `Bearer ${token}`,
      username: localStorage.getItem("user")
    };
    axios
      .post(window.BASE_URL + `/users/${this.state.userid}/image`, formData, {
        headers: headers
      })
      .then(res => {
        console.log("resp", res);
        if (res.data.status > 0)
          this.props.showMessageBox("SUCCESS");
        else
          this.props.showMessageBox(res.data.message);
        this.getUserDetail();
        this.setState({
          selectedFile: null
        });
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
  };

  fileChangedHandler = event => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  render() {
    const { name, email, phone, imageUrl, permissions, microsoftOnly } = this.state;
    const { classes } = this.props;
    console.log("image", imageUrl);
    return (
      <div
        id="content"
        style={{
          //paddingTop: "60px",
          transition: "0.25s",
          paddingLeft: "0px"
        }}
      >
        <div className="container-fluid">
          {this.state.show ? (
            <Paper style={{ padding: 10, marginTop: "15px" }}>
              <Grid container spacing={24} style={{ display: "flex" }}>
                <Grid
                  item
                  xs={12}
                  md={3}
                  lg={3}
                  style={{ textAlign: "center", alignItems: "center" }}
                >
                  <img
                    style={{
                      borderRadius: "100%",
                      //backgroundColor: "black"
                      //content: `url(${imageUrl})`
                    }}
                    width="170px"
                    height="170px"
                    alt=""
                    src={`${imageUrl}?${new Date().getTime()}`}
                  />
                  {!microsoftOnly &&
                    <div style={{ paddingTop: "5px" }}>
                      <div
                        style={{ color: "#4b2e83", cursor: "pointer" }}
                        onClick={() => this.image.click()}
                      >
                        <h6>Change profile picture</h6>
                      </div>
                    </div>
                  }
                  {this.state.selectedFile ? (
                    <div
                      style={{
                        display: "flex",
                        margin: "auto",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 5
                      }}
                    >
                      <p
                        style={{
                          fontSize: "12px",
                          color: "black",
                          margin: "auto",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                      >
                        {this.state.selectedFile
                          ? this.state.selectedFile.name
                          : ""}
                      </p>
                      <Button
                        //color="primary"
                        onClick={this.uploadHandler}
                        variant="outlined"
                        style={{
                          marginLeft: 5,
                          color: "#4b2e83",
                          heignt: 40,
                          fontSize: 11,
                          border: "1px solid #4b2e83"
                        }}
                      >
                        Upload!
                      </Button>
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={1} lg={1} />
                <Grid item xs={12} md={7} lg={7}>
                  <h3
                    style={{
                      color: "#4b2e83",
                      marginBottom: "10px",
                      paddingTop: "10px"
                    }}
                  >
                    {name}
                  </h3>

                  <h5>Username: {this.state.userid}</h5>
                  <h5>Email Id: {email}</h5>
                  <h5>Phone Number: {phone}</h5>
                  {!microsoftOnly &&
                    <div style={{ marginTop: "32px" }}>
                      <div
                        style={{ color: "#4b2e83", cursor: "pointer" }}
                        onClick={() => this.setState({ open: !this.state.open })}
                      >
                        <h6>CHANGE PASSWORD</h6>
                      </div>
                    </div>
                  }
                </Grid>
                <Grid item xs={12} md={1} lg={1}>
                  {!microsoftOnly &&
                    <Tooltip title="Edit">
                      <IconButton
                        style={{
                          color: "#4b2e83",
                          opacity: "1",
                          outline: "none"
                        }}
                        aria-label="Edit"
                        onClick={() => {
                          this.setState({
                            editDetails: !this.state.editDetails
                          });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </Grid>
              </Grid>
              <input
                hidden={true}
                ref={image => (this.image = image)}
                type="file"
                onChange={this.fileChangedHandler}
              />
              <Collapse in={this.state.editDetails}>
                <hr />
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                  <Grid item xs={12} md={4} lg={3}>
                    <TextField
                      id="full-name"
                      label="Full Name"
                      value={this.state.editName}
                      type="text"
                      style={{
                        paddingBottom: "5px",
                        width: "100%",
                        borderColor: "#4b2e83"
                      }}
                      onChange={e => {
                        this.setState({ editName: e.target.value });
                      }}
                      margin="dense"
                      variant="outlined"
                      placeholder="Full Name"
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <TextField
                      id="email"
                      label="Email Id"
                      value={this.state.editEmail}
                      type="email"
                      style={{
                        paddingBottom: "5px",
                        width: "100%",
                        borderColor: "#4b2e83"
                      }}
                      onChange={e => {
                        this.setState({ editEmail: e.target.value });
                      }}
                      margin="dense"
                      variant="outlined"
                      placeholder="Email Id "
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <TextField
                      id="contact"
                      label="Phone Number"
                      value={this.state.editPhone}
                      //type="number"
                      style={{
                        paddingBottom: "5px",
                        width: "100%",
                        borderColor: "#4b2e83"
                      }}
                      onChange={e => {
                        this.setState({ editPhone: e.target.value });
                      }}
                      margin="dense"
                      variant="outlined"
                      placeholder="Phone Number"
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} md={2} lg={2}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Tooltip title="Reset">
                        <IconButton
                          style={{
                            color: "#4b2e83",
                            outline: "none"
                          }}
                          aria-label="Reset"
                          onClick={() => {
                            this.setState({
                              editName: "",
                              editEmail: "",
                              editPhone: ""
                            });
                          }}
                        >
                          <ResetIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Done">
                        <IconButton
                          style={{
                            color: "#4b2e83",
                            outline: "none"
                          }}
                          aria-label="Done"
                          onClick={() => {
                            this.handleEdit();
                          }}
                        >
                          <SubmitIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={this.state.open}>
                <hr />
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                  <Grid item xs={12} md={4} lg={3}>
                    <TextField
                      id="old-pwd"
                      label="Old Password"
                      value={this.state.oldPassword}
                      type="password"
                      style={{
                        paddingBottom: "5px",
                        width: "100%",
                        borderColor: "#4b2e83"
                      }}
                      required
                      onChange={e => {
                        this.setState({ oldPassword: e.target.value });
                      }}
                      margin="dense"
                      variant="outlined"
                      placeholder="Old Password"
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <TextField
                      id="new-pwd"
                      label="New Password"
                      value={this.state.password}
                      type="password"
                      style={{
                        paddingBottom: "5px",
                        width: "100%",
                        borderColor: "#4b2e83"
                      }}
                      required
                      onChange={e => {
                        this.setState({ password: e.target.value });
                      }}
                      margin="dense"
                      variant="outlined"
                      placeholder="New Password"
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <TextField
                      id="cnf-pwd"
                      label="Confirm Password"
                      value={this.state.confirmPassword}
                      type="password"
                      style={{
                        paddingBottom: "5px",
                        width: "100%",
                        borderColor: "#4b2e83"
                      }}
                      required
                      onChange={e => {
                        this.setState({ confirmPassword: e.target.value });
                      }}
                      margin="dense"
                      variant="outlined"
                      placeholder="Confirm Password"
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} md={2} lg={2}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Tooltip title="Reset">
                        <IconButton
                          style={{
                            color: "#4b2e83",
                            outline: "none"
                          }}
                          aria-label="Reset"
                          onClick={() => {
                            this.setState({
                              password: "",
                              confirmPassword: ""
                            });
                          }}
                        >
                          <ResetIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Done">
                        <IconButton
                          style={{
                            color: "#4b2e83",
                            outline: "none"
                          }}
                          aria-label="Done"
                          onClick={() => {
                            this.setPassword();
                          }}
                        >
                          <SubmitIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
                <p style={{ fontSize: "12px", color: "red", marginBottom: 4 }}>
                  *Password must be between 8-15 characters. Must contains at
                  least ONE special characters (!#$_&@%), 1 numeric, 1
                  UpperCase, 1 smallcase letters
                </p>
              </Collapse>

              {permissions.length > 0 ? (
                <React.Fragment>
                  <hr />
                  <div
                    //style={{marginTop: "30px"}}
                  >
                    <h5 style={{ paddingLeft: "20px" }}>
                      You have access to these resources:
                    </h5>
                    <hr />
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow style={{ height: 20 }}>
                          {tableHeader.map(row => (
                            <TableCell
                              key={row.id}
                              style={{ color: "#4b2e83", fontSize: "15px" }}
                            >
                              {row.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {permissions
                          .filter(item => item.IsActive == true)
                          .map(row => {
                            let policyGroup = "";
                            if (row.PermissionGroupLevel == 700)
                              policyGroup = "Administrator";
                            else if (row.PermissionGroupLevel == 500)
                              policyGroup = "Admin";
                            else if (row.PermissionGroupLevel == 300)
                              policyGroup = "Editor";
                            else if (row.PermissionGroupLevel == 100)
                              policyGroup = "Read Only";

                            return (
                              <TableRow
                                style={{ height: 40 }}
                                key={row.MembershipId}
                              >
                                <TableCell>{row.ResourceInfo.Name}</TableCell>
                                <TableCell>{row.ResourceInfo.Type}</TableCell>
                                <TableCell>{policyGroup}</TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <hr />
                  <div style={{ paddingLeft: "20px" }}>
                    {Msg}
                    {false &&
                      <h5>
                        You don't have access to any resources. Please contact <a href="mailto:assettracker@uw.edu"> System Administrator</a> to request access.
                      </h5>
                    }
                  </div>
                  <hr />
                </React.Fragment>
              )}
            </Paper>
          ) : (
            <div
              style={{
                width: "100%",
                marginTop: 150,
                marginBottom: 150,
                display: "flex",
                justifyContent: "center"
              }}
            >
              <CircularProgress
                style={{ width: "80px", height: "80px", color: "#4b2e83" }}
                className={classes.progress}
              />
              <h4 style={{ marginTop: "25px", marginLeft: "5px" }}>Loading</h4>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(UserProfile);
