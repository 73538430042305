import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { token } from "../utils/Utils";
import * as MeridianSDK from "@meridian/web-sdk";
import './Map.css';
import { defaultImage, addLowBatteryHint } from "../utils/Utils";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    flexGrow: 1
  }
});

class DeviceMap extends Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.state = {
      //macid: this.props.macid,
      hideMap: false,
    };
  }

  componentDidMount() {
    this.renderMap(this.props.locid, this.props.floorid, this.props.macid, this.props.x, this.props.y);
  }

  renderMap(locid, floorid, macid, x, y) {
    const that = this;
    if (!locid || !floorid) {
      if (this.map && !this.map.isDestroyed) {
        this.map.destroy();
        this.map = null;
      }
      that.setState({
        hideMap: true,
      });
    } else {
      let point = x && y ? [{
        type: "point",
        x: x,
        y: y,
        backgroundColor: /*"#c00"*/"none",
        size: 25,
        backgroundImage: this.props.image ? this.props.image : defaultImage,
        //title: "Last Known"
      }] : [];
      that.setState(
        {
          hideMap: false,
        },
        () => {
          let element = document.getElementById(this.props.element);
          if (!this.map || this.map.isDestroyed) {
            const api = new MeridianSDK.API({
              environment: "production",
              token: token,
            });
            if (element) {
              element.innerHTML = "";
            }
            this.map = MeridianSDK.createMap(
              //document.getElementById(this.props.element),
              element,
              {
                api: api,
                locationID: locid,
                floorID: floorid,
                shouldMapPanZoom: function restrictedPanZoom(event) {
                  if (event.type === "wheel" && !event.altKey) {
                    return false;
                  } else if (event.type === "touchstart") {
                    return event.touches.length >= 2;
                  }
                  return true;
                },

                annotations: point,
                //height: "500px",
                height: this.props.element == "meridian-map2" ? "calc(100vh - 50px)" : "500px",
                placemarks: {
                  labelZoomLevel: 0.1,
                  filter: t => {
                    return true;
                  }
                },

                onPlacemarksUpdate: ({ allPlacemarks, filteredPlacemarks }) => {
                  this.map.update({
                    overlays: filteredPlacemarks
                      .filter(placemark => placemark.area)
                      .map((placemark, i) => {
                        const hue = (i * (360 / 6)) % 360;
                        return {
                          type: "polygon",
                          points: MeridianSDK.pointsFromArea(placemark.area),
                          //points: [3032,629],
                          fill: `hsla(${hue}, 100%, 60%, 0.3)`,
                          stroke: `hsl(${hue}, 100%, 60%)`
                        };
                      })
                  });
                },

                loadTags: point.length == 0 ? true : false,
                tags: {
                  //filter: function (tag) {
                  //  return tag.mac === that.props.macid && point.length == 0;
                  filter: (tag) => {
                    if (tag.mac === macid) {
                      addLowBatteryHint(tag);
                      return true;
                    }
                    return false;
                  },
                  updateInterval: 10000
                },
              }
            );
            //let x = document.getElementById(this.props.element);
            element = element ? element.querySelector(".meridian-map-background") : "";
            if (element) {
              element.click();
            }
          } else {
            //let x = document.getElementById(this.props.element);
            element = element ? element.querySelector(".meridian-map-background") : "";
            if (element) {
              element.click();
            }
            this.map.update({
              locationID: locid,
              floorID: floorid,
              annotations: point,
              loadTags: point.length == 0 ? true : false,
              tags: {
                filter: (tag) => {
                  if (tag.mac === macid) {
                    addLowBatteryHint(tag);
                    return true;
                  }
                  return false;
                },
                updateInterval: 10000
              }
            });
          }
        }
      );
    }
  }

  componentWillReceiveProps({ floorid, locid, macid, x = "", y = "" }) {
    //if (this.props.floorid !== floorid || this.props.locid !== locid || this.props.macid !== macid)
      this.renderMap(locid, floorid, macid, x, y);
  }

  componentWillUnmount() {
    if (this.map && !this.map.isDestroyed) {
      this.map.destroy();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {!this.state.hideMap ? (
          <div className="map" /*id="meridian-map"*/ id={this.props.element} />
        ) : (
          <Paper
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 30,
            }}
          >
            <h4>Currently the device is not traceable. Please check Last known location.</h4>
          </Paper>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(DeviceMap);
