import React, { Component } from "react";
import ReactDOM from "react-dom";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import MapIcon from "@material-ui/icons/Map";
import CloseIcon from "@material-ui/icons/HighlightOff";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import ResetIcon from "@material-ui/icons/Refresh";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  getEntity,
  getLocations,
  getCollections,
  getAssets,
  cleanData,
  showMessage
} from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import { apiRequest, getStoredEntityId, storeEntityId, token, getOption, admintoken, byPropertyCalled, addLowBatteryHint } from "../utils/Utils";
import * as MeridianSDK from "@meridian/web-sdk";
import { h } from "preact";
//import { Utils } from "ag-grid";
import Filters from "./Filters.jsx";
import Collapse from "@material-ui/core/Collapse";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./Map.css";

const lowBatteryLabelName = "tag" + Date.now().toString(16);
const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2
  },
  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: "auto"
  },
  input: {
    height: "30px",
    padding: "20px"
  },
  input1: {
    height: 38
  },
  label1: {
    marginTop: "-5px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    color: "#4b2e83",
  },
});

var selectedfloor;

class MapView extends Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.state = {
      padding: "90px",
      entity: sessionStorage.getItem("entityID") ? JSON.parse(sessionStorage.getItem("entityID")) : "",
      dept: "",
      loc: "",
      floor: "",
      collection: "",
      entityData: [],
      locationData: [],
      floorData: [],
      collectionData: [],
      assetData: [],
      currentData: [],
      selectedLocationMapId: "",
      selectedFloorMapId: "",
      renderMap: false,
      hideMap: false,
      tagMacs: [],
      showMapControls: false,
      showRedField: false,
      searchText: "",
      open: false,
      floors: [],
      tags: [],
      isSecondaryFilterOpened: false,
      aObj: null,
      isFullScreen: false
    };
  }

  componentDidMount() {
    if (this.state.entity && this.state.entity  !== "") {
      this.props.getLocation(this.state.entity);
    }

    //if (!document.getElementById(lowBatteryLabelName)) {
    //  var style = document.createElement("style");
    //  style.id = lowBatteryLabelName;
    //  style.innerHTML = `.${lowBatteryLabelName} { border: 3px solid red; overflow: visible; }`;
    //  style.setAttribute("type", "text/css");
    //  document.head.appendChild(style);
    //}
  }

  getMapsFromMeridian(location) {
    var url =`https://edit.meridianapps.com/api/locations/${location}/maps`;
    return fetch(url, getOption())
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          this.setState({ hideMap: true });
        }
      })
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log("error:", err);
      });
  }

  getFloorData(from) {
    const that = this;
    const { entity, loc, collection } = this.state;
    // API request
    console.log("** Get DATA:", entity, loc, collection, from);
    const request = from ? `/collections/${collection}/assets` : `/assets/search?entity_id=${entity}&location_id=${loc}`;
    console.log('**Request:', request);
    apiRequest(request, "get")
      .then(response => {
        let datas = response.data.map(c => {
          return {
            id: c.MacId,
            name: c.FloorNumber,
            location_id: c.MeridianLocationId,
            floor_id: c.MeridianMapId,
            device_name: c.Name,
            building_id: c.MeridianBuildingId,
            filter: true,
          };
        });
        datas = datas.filter(c => c.floor_id !== null);
        //get meridian maps
        if (datas.length > 0) {
          var locid =datas[0].location_id;
          var buildingid = datas[0].building_id;
          let floors= [];
          this.getMapsFromMeridian(locid).then((data) => {
            if (data) {
              console.log("maps:", data);
              data.results.map(item => floors.push(
                {
                  name: item.level_label,
                  floor_id: item.id,
                  location_id: locid,
                  building_id: item.group
              }));

              floors.sort(this.cmp);
              floors = floors.filter(item => item.building_id == buildingid);
              console.log("floors:", floors);
              this.setState({ floors: floors });
            }
          });
        }
        else {
          //no assets to show
          this.setState({ floors: [] });
        }

        let macs = datas.map(c => c.id);
        console.log("datas", datas, "macs", macs);
        let group = datas.reduce((result, currentValue) => {
          (result[currentValue['floor_id']] = result[currentValue['floor_id']] || []).push(
            currentValue
          );
          return result;
        }, {});
        let floors = Object.entries(group).map(c => {
          let devs = c[1];
          let tagmacs = devs.reduce((result, currentValue) => {
            result = result + currentValue['id'] + ",";
            return result;
          }, "");
          return {
            floor_id: c[0],
            name: devs[0].name,
            location_id: devs[0].location_id,
            tagmacs: tagmacs,
          };
        });
        const notfloors = (floors.length == 0);
        let assets = Object.entries(group);
        for (var i = 0; i < assets.length; i++) {
          var item = Object();
          var dev = assets[i][1];
          item.id = assets[i][0];
          item.name = dev[0].name;
          assets[i][0]= item;
        }
        assets = assets.sort(this.cmpf);
        that.setState({
          assetData: assets,
          currentData: assets,
          floorData: floors.sort(this.cmp),
          floor: "",
          renderMap: notfloors,
          hideMap: (floors.length == 0),
          searchText: "",
          tags: macs
        });
      })
      .catch(error => console.log(error));

/*
    apiRequest(`/entities/${entity}/locations/${loc}/maps`, "get")
      .then(response => {
        let floors = response.data.map(c => {
          return {
            id: c.floor_number,
            name: c.floor_label + " - " + c.building_name,
            location_id: c.meridian_location_id,
            floor_id: c.meridian_map_id
          };
        });
        floors = floors.filter(c => c.id !== null);
        console.log('**Floors:', floors);
        that.setState({
          floorData: floors.sort(this.cmp),
          floor: ""
        });
      })
      .catch(error => console.log(error));
*/
  }

  unmountMap() {
    this.setState({
      renderMap: false,
      showFilters: false,
      //entity: "",
      loc: "",
      collection: "",
      floor: "",
      searchText: "",
      currentData: [],
    });
    // if (this.state.entity) {
    //   this.props.getLocation(this.state.entity);
    //   this.props.getCollection(this.state.entity);
    // }
  }

  resetMap() {
    if (this.map && !this.map.isDestroyed) {
      this.map.destroy();
      this.map = null;
    }
    var element = document.getElementById("meridian-map");
    if (element) {
      element.innerHTML = "";
    };
    this.setState({
      renderMap: false,
    });
  }

  resetColl() {
    this.setState({
      collection: "",
      searchText: "",
      currentData: [],
    });
  }

  mountMap = () => {
    if (
      this.state.entity === "" ||
      (this.state.loc === "" && this.state.collection === "") ||
      this.state.floor === ""
    ) {
      this.props.showMessageBox("Please Select the Required fields");
      this.setState({
        showRedField: true
      });
      return;
    }
    this.setState(
      {
        //showFilters: true,
        renderMap: true,
        showRedField: false,
        showMapControls: this.state.collection? true: false,
      },
      () => {
        selectedfloor = this.state.floors.filter(
          item => item.floor_id === this.state.floor
        );

        if (this.map && !this.map.isDestroyed) {
          this.map.destroy();
          this.map = null;
        }
        if (!selectedfloor[0].location_id || !selectedfloor[0].floor_id) {
          this.setState({
            hideMap: true
          });
        } else {
          this.setState({
            hideMap: false
          }, () => {
            const api = new MeridianSDK.API({
              environment: "production",
              token: token
            });
            //console.log('**MAP Create:', selectedfloor[0]);
            let element = document.getElementById("meridian-map");
            if (element) {
              element.innerHTML = "";
            }
            this.map = MeridianSDK.createMap(
              //document.getElementById("meridian-map"),
              element,
              {
                api: api,
                locationID: selectedfloor[0].location_id,
                floorID: selectedfloor[0].floor_id,
                shouldMapPanZoom: function restrictedPanZoom(event) {
                  if (event.type === "wheel" && !event.altKey) {
                    return false;
                  } else if (event.type === "touchstart") {
                    return event.touches.length >= 2;
                  }
                  return true;
                },
                showFloorsControl: false, //this.state.showMapControls,
                showTagsControl: false, //this.state.showMapControls,
                height: "500px",

                placemarks: {
                  filter: function() {
                    return true;
                  }
                },
                onPlacemarksUpdate: ({ allPlacemarks, filteredPlacemarks }) => {
                  this.map.update({
                    overlays: filteredPlacemarks
                      .filter(placemark => placemark.area)
                      .map((placemark, i) => {
                        const hue = (i * (360 / 6)) % 360;
                        return {
                          type: "polygon",
                          points: MeridianSDK.pointsFromArea(placemark.area),
                          //points: [3032,629],
                          fill: `hsla(${hue}, 100%, 60%, 0.3)`,
                          stroke: `hsl(${hue}, 100%, 60%)`
                        };
                      })
                  });
                },

                tags: {
                  filter: t => {
                    //return this.state.tags.includes(t.mac);
                    if (this.props.assets.some(a => a.MacId == t.mac)) {
                      addLowBatteryHint(t);
                      return true;
                    }
                    return false;
                  },
                  updateInterval: 10000
                }
              }
            );
          });
        }
      }
    );
  };

  mountMap2 = (data) => {
    if (
      data.entity === "" ||
      //(data.loc === "" && data.coll === "") ||
      data.floor === ""
    ) {
      this.props.showMessageBox("Please Select the Required fields");
      //const showRedField = true;
      //data.handeChange(showRedField);
      this.setState({
        showRedField: true
      });
      return;
    }
    this.setState(
      {
        //showFilters: true,
        renderMap: true,
        showRedField: false,
        //showMapControls: this.state.collection? true: false,
      },
      () => {
        var selectedfloor = data.floorData.find(
          item => item.floor_id === data.floor
        );

        if (!selectedfloor || !selectedfloor.location_id || !selectedfloor.floor_id) {
          if (this.map && !this.map.isDestroyed) {
            this.map.destroy();
            this.map = null;
          }
          this.setState({
            hideMap: true
          });
        } else {
          this.setState({
            hideMap: false
          }, () => {
            if (this.map && !this.map.isDestroyed) {
              this.map.update({ floorID: selectedfloor.floor_id });
              return;
            }

            const api = new MeridianSDK.API({
              environment: "production",
              token: admintoken
            });
            //console.log('**MAP Create:', selectedfloor[0]);
            var element = document.getElementById("meridian-map");
            if (element) {
              var mm = element ? element.querySelector(".meridian-map-background") : "";
              if (mm) {
                mm.click();
              }
              element.innerHTML = "";
            };
            this.map = MeridianSDK.createMap(
              //document.getElementById("meridian-map"),
              element,
              {
                api: api,
                locationID: selectedfloor.location_id,
                floorID: selectedfloor.floor_id,
                shouldMapPanZoom: function restrictedPanZoom(event) {
                  if (event.type === "wheel" && !event.altKey) {
                    return false;
                  } else if (event.type === "touchstart") {
                    return event.touches.length >= 2;
                  }
                  return true;
                },
                showFloorsControl: false, //this.state.showMapControls,
                showTagsControl: false, //this.state.showMapControls,
                height: this.props.values.isFullScreen ? "calc(100vh - 135px)" : "calc(100vh - 220px)",

                placemarks: {
                  labelZoomLevel: 0.1,
                  filter: t => {
                    return true;
                  }
                },
                onPlacemarksUpdate: ({ allPlacemarks, filteredPlacemarks }) => {
                  this.map.update({
                    overlays: filteredPlacemarks
                      .filter(placemark => placemark.area)
                      .map((placemark, i) => {
                        const hue = (i * (360 / 6)) % 360;
                        return {
                          type: "polygon",
                          points: MeridianSDK.pointsFromArea(placemark.area),
                          //points: [3032,629],
                          fill: `hsla(${hue}, 100%, 60%, 0.3)`,
                          stroke: `hsl(${hue}, 100%, 60%)`
                        };
                      })
                  });
                },

                tags: {
                  filter: t => {
                    let match = false;
                    if (this.state.aObj != null) {
                      //return this.state.aObj.MacId == t.mac;
                      match = this.state.aObj.MacId == t.mac ? true : false;
                    } else {
                      //return this.state.tags.includes(t.mac);
                      match = this.props.assets.some(a => a.MacId == t.mac);
                    }
                    if (match) {
                      addLowBatteryHint(t);
                      return true;
                    }
                    return false;
                  },
                  updateInterval: 10000
                }
              }
            );
          });
        }
      }
    );
  };

  getData(e,f,b,c) {
    // const { entity, loc, collection } = this.state;
    // // API request
    // console.log("** Get DATA:", entity, loc, collection, from);
    // const request = from ? `/collections/${collection}/assets`
    // : `/assets/search?entity_id=${entity}&location_id=${loc}`;
    // console.log('**Request:', request);
    var url = `/assets/search?entity_id=${e}&meridian_map_id=${f}`;
    if (b && c) {
      //both
      url +=`&location_id=${b}&collection_id=${c}`;
    }
    else if (b) {
      //only loc
      //url +=`&location_id=${b}`;
    }
    else {
      //only coll
      url +=`&collection_id=${c}`;
    }
    apiRequest(url, "get")
      .then(response => {
        let datas = response.data.map(c => {
          return {
            id: c.MacId,
            name: c.FloorNumber,
            location_id: c.MeridianLocationId,
            floor_id: c.MeridianMapId,
            device_name: c.Name,
            building_id: c.MeridianBuildingId,
            filter: true,
          };
        });
        //Filter for the selected floor id
        datas = datas.filter(c => c.floor_id == f);
        datas = datas.filter(c => this.props.assets.map(a => a.MacId).includes(c.id));
        this.setState({ tags: datas.map(d => d.id) });
      });
  }

  componentWillMount() {
    this.props.getEntities();
    this.setState({
      //entity: "",
      loc: "",
      floor: ""
    });
    if (this.state.entity) {
      this.props.getLocation(this.state.entity);
      this.props.getCollection(this.state.entity);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (((this.state.renderMap && !nextState.renderMap) || (!this.state.hideMap && nextState.hideMap)) && this.map && !this.map.isDestroyed) {
      this.map.destroy();
      this.map = null;
    }
    return true;
  }

  componentWillUnmount() {
    if (this.map && !this.map.isDestroyed) {
      this.map.destroy();
    }
  }

  handleChange = name => event => {
    this.setState(
      {
        [name]: event.target.value,
      },
      () => {
        if (name === "entity") {
          sessionStorage.setItem("entityID", event.target.value ? JSON.stringify(event.target.value) : "");
          //this.props.getLocation(event.target.value);
          //this.props.getCollection(event.target.value);
          //this.setState({ collection: "" });
          this.unmountMap();
        } else if (name === "loc") {
          this.resetColl();
          this.getFloorData(false);
          this.resetMap();
        } else if (name === "collection") {
          this.getFloorData(true);
          this.resetMap();
        } else if (name === "floor") {
          selectedfloor = this.state.floors.filter(
            item => item.floor_id === event.target.value
          );
          // apiRequest(
          //   `/maps/${selectedfloor[0].location_id}/mapid/${
          //     selectedfloor[0].floor_id
          //   }`,
          //   "get"
          // ).then(response => {
          //   this.setState({
          //     tagMacs: response.data[0].map(item => item.id)
          //   });
          //this.setState({ tagMacs: selectedfloor[0].tagmacs });
          this.mountMap();
        };
      }
    );
  };

  componentWillReceiveProps({ entities, locations, collections, assets, values }) {
    if (this.state.aObj !== null) {
      this.setState({ aObj: null });
    }
    if (values && values.floor && values.floorData && values.entity && (values.coll || values.loc) && assets) {
      //this.getData(values.entity, values.floor, values.loc, values.coll);
      this.mountMap2(values);
    }
    else {
      this.resetMap();
    }
    this.setState({
      entityData: entities.sort(this.cmp),
      locationData: locations.sort(this.cmp),
      collectionData: collections.sort(this.cmp)
    });
  }

  handleReset = () => {
    this.props.resetData();
    this.unmountMap();
    this.setState({
      floorData: [],
      renderMap: false,
      hideMap: false
    });
  };

  cmp = (a,b) => {
    const alc = a.name? a.name.toLowerCase(): '';
    const blc = b.name? b.name.toLowerCase(): '';
    if (alc < blc) {
      return -1;
    }
    else if (alc > blc) {
      return 1;
    }
    else {
      return 0;
    }
  }

  cmpf = (a,b) => {
    const alc = a[0].name? a[0].name.toLowerCase(): '';
    const blc = b[0].name? b[0].name.toLowerCase(): '';
    if (alc < blc) {
      return -1;
    }
    else if (alc > blc) {
      return 1;
    }
    else {
      return 0;
    }
  }

  showMap(macid, floorid) {
    this.setState({ floor: floorid })
    selectedfloor = this.state.floors.filter(
      item => item.floor_id === floorid
    );
    if (selectedfloor) {
      //this.setState({ tagMacs: selectedfloor[0].tagmacs });
      this.setState({ tagMacs: macid });
      this.mountMap();
      this.handleClose();
    }
  }

  renderGroupInfo(assets) {
    return (
      <div>
        {assets && assets.map((c, i) => (
          <Grid key={i} item xs={12}>
            <h5 style={{ color: "#4b2e83" }}>{c[1][0].name}</h5>
            {c[1] && c[1].map((d, i) => (
              <span key={i}>
                {d.filter && (
                  <h6 style={{ padding: "2px 12px 2px 36px" }}>
                    <Tooltip
                      style={{ padding: 0 }}
                      title={"Show Map for Device: " + d.id}
                    >
                      <IconButton
                        aria-label="Show Map"
                        onClick={this.showMap.bind(this, d.id, d.floor_id)}
                      >
                        <MapIcon style={{ color: "#4b2e83" }} />
                      </IconButton>
                    </Tooltip>&nbsp;
                    {d.device_name}
                  </h6>
                )}
              </span>
            ))}
          </Grid>
        ))}
      </div>
    );
  }

  setSearchText = e => {
    const st = e.target.value.toLowerCase();
    let filtered = this.state.assetData;
    for (var i = 0; i < filtered.length; i++) {
        var item = filtered[i][1];
        for (var j = 0; j < item.length; j++) {
            if (item[j].device_name.toLowerCase().includes(st)) {
              item[j].filter = true;
            } else {
              item[j].filter = false;
            }
        }
        filtered[i][1] = item;
      }
    //const data = this.state.assetData.filter(a => a[1].device_name.toLowerCase().includes(st));
    this.setState({ searchText:st, currentData: filtered });
  };

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  isFloorMatching(floorid) {
    if (this.state.floorData.find(i => i.floor_id == floorid)) {
      return true;
    }
    else {
      return false;
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid
          container
          spacing={2}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={10}>
            <Autocomplete
              id="asset"
              disabled={this.props.assets.length==0}
              options={this.props.assets.sort(byPropertyCalled("Name"))}
              value={this.state.aObj}
              getOptionSelected={(option, value) => option.MacId == value.MacId}
              getOptionLabel={(option) => option.Name}
              onChange={(e, v) => {
                if (v !== null) {
                  this.setState({ aObj: v/*, tags: v.MacId*/ }, () => {
                    let data = {};
                    data.entity = v.EntityId;
                    data.floor = v.MeridianMapId;
                    data.floorData = this.props.values.floorData;
                    this.mountMap2(data);
                    // var x = document.getElementById("meridian-map");
                    // var mm = x ? x.querySelector(".meridian-map-background") : "";
                    // if (mm) {
                    //   mm.click();
                    // }
                  });
                } else {
                  //this.setState({ aObj: v });
                  const { assets, values } = this.props;
                  if ((values && values.floor && values.floorData && values.entity) && (values.coll || values.loc) && assets) {
                    //this.getData(values.entity, values.floor, values.loc, values.coll);
                    this.mountMap2(values);
                  }
                  else {
                    this.unmountMap();
                  }
                  this.setState({ aObj: v });
                }
              }}
              renderOption={(option) => (
                <div style={{ padding: "8px 2px" }}>{option.Name}</div>
              )}
              renderInput={params => {
                params.InputProps.startAdornment = (
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                );
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    //label="Search"
                    placeholder="Search"
                    margin="dense"
                  />
                );
              }}
            />
          </Grid>
          <Grid
            item
            xs
            //xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                this.setState({
                  isSecondaryFilterOpened: !this.state.isSecondaryFilterOpened,
                });
              }}
              style={{
                color: "#4b2e83",
                //opacity: "1",
                //outline: "none"
              }}
              //className="buttonnobg float-center"
            >
              Filter
            </Button>
          </Grid>
          <Grid item /*xs={6} md={4} lg={8}*/ style={{ textAlign: "end", alignSelf: "center", width: "24px", marginLeft: "8px" }}>

          </Grid>
        </Grid>
        <Collapse in={this.state.isSecondaryFilterOpened}>
          <Filters
            //searchString={this.state.searchText2}
            searchString=""
            params=""
            resetSearchText={() => this.setState({ searchText: "" })}
            values={this.props.values}
            setLoading={v => {}}
          />
        </Collapse>
        {/* <Paper style={{ marginBottom: "15px" }} className={classes.root}>
          <Toolbar>
            <div
              style={{ paddingLeft: 0, paddingRight: 0 }}
              className={classes.title}
            >
              <h4 style={{ color: "#4b2e83" }} variant="h4" id="tableTitle">
                Select
              </h4>
            </div>

            <Grid
              style={{
                width: "100%",
                paddingLeft: 30,
                paddingRight: 10,
                paddingTop: 10,
                paddingBottom: 5
              }}
              container
              spacing={16}
            >
              <Grid item xs={12} sm={8} md={2} lg={2} hidden={true}>
                <TextField
                  width="100%"
                  id="outlined-select"
                  select
                  style={{ width: "100%" }}
                  required
                  margin="dense"
                  disabled={true}//{this.state.showFilters}
                  label="Organization"
                  placeholder="Organization"
                  error={
                    this.state.showRedField && this.state.entity == ""
                      ? true
                      : false
                  }
                  className={classes.textField}
                  value={this.state.entity}
                  onChange={this.handleChange("entity")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  InputProps={{ className: classes.input1 }}
                  InputLabelProps={{
                    className: classes.label1
                  }}
                  variant="outlined"
                >
                  {this.state.entityData.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={8} md={3} lg={3}>
                <TextField
                  width="100%"
                  id="outlined-select"
                  select
                  required
                  margin="dense"
                  style={{ width: "100%" }}
                  error={
                    this.state.showRedField && this.state.loc == ""
                      ? true
                      : false
                  }
                  disabled={
                    this.state.locationData.length === 0 ||
                    this.state.showFilters
                  }
                  label="Building"
                  placeholder="Building"
                  className={classes.textField}
                  value={this.state.loc}
                  onChange={this.handleChange("loc")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  InputProps={{ className: classes.input1 }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label1
                  }}
                  variant="outlined"
                >
                  {this.state.locationData.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={8} md={3} lg={3}>
                <TextField
                  width="100%"
                  id="outlined-select"
                  select
                  margin="dense"
                  style={{ width: "100%" }}
                  error={
                    this.state.showRedField && this.state.collection == ""
                      ? true
                      : false
                  }
                  disabled={
                    this.state.collectionData.length === 0 ||
                    //this.state.loc == "" ||
                    this.state.showFilters
                  }
                  label="Collection"
                  placeholder="Collection"
                  className={classes.textField}
                  value={this.state.collection}
                  onChange={this.handleChange("collection")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  InputProps={{ className: classes.input1 }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label1
                  }}
                  variant="outlined"
                >
                  {this.state.collectionData.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={8} md={3} lg={3}>
                <TextField
                  width="100%"
                  id="outlined-select"
                  select
                  margin="dense"
                  disabled={
                    this.state.floorData.length === 0 || this.state.showFilters
                  }
                  error={
                    this.state.showRedField && this.state.floor == ""
                      ? true
                      : false
                  }
                  required
                  style={{ width: "100%" }}
                  label="Floor"
                  placeholder="Floor"
                  className={classes.textField}
                  value={this.state.floor}
                  onChange={this.handleChange("floor")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  InputProps={{ className: classes.input1 }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label1
                  }}
                  variant="outlined"
                >
                  {this.state.floors.map(option => (
                    <MenuItem key={option.floor_id} value={option.floor_id}>
                      {this.isFloorMatching(option.floor_id) ? <b>{option.name}</b> : <div>{option.name}</div>}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                item
                xs={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Tooltip title="Reset Filters">
                  <IconButton
                    style={{ color: "#4b2e83", paddingTop: "10px" }}
                    aria-label="Reset Filters"
                    onClick={this.handleReset}
                  >
                    <ResetIcon />
                  </IconButton>
                </Tooltip>
                  <IconButton
                    style={{ color: "#4b2e83", paddingTop: "10px", visibility: (!this.state.renderMap || this.state.hideMap) ? "hidden" : "visible" }}
                    aria-label="Reset Filters"
                    onClick={this.handleOpen}
                    //disabled={!this.state.renderMap || this.state.hideMap}
                  >
                    <FormatListBulletedIcon />
                  </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </Paper> */}

        {this.state.renderMap ? (
          !this.state.hideMap ? (
            <Paper style={{ marginBottom: "15px" }}>
              <div className="map" id="meridian-map" />
            </Paper>
          ) : (
            <Paper
              style={{
                marginTop: "20px",
                width: "100%",
                height: "calc(100vh - 220px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 30,
              }}
            >
              <h4>There's no map uploaded for this selection.</h4>
            </Paper>
          )
        ) : (
          <Paper
            style={{
              marginTop: "20px",
              width: "100%",
              height: "calc(100vh - 220px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 30,
            }}
          >
            <h4>
              Please select the Organization, Campus, Collection and Floor to see the Map
            </h4>
          </Paper>
        )}

        {/* Assets Dialog */}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll="paper"
          fullWidth={true}
          maxWidth="sm"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title" onClose={this.handleClose}>
            <TextField
              style={{
                width: "70%",
              }}
              value={this.state.searchText}
              margin="dense"
              placeholder="Search"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                ),
                className: classes.input,
              }}
              onChange={this.setSearchText}
            />
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers="true">
            <Grid
              style={{
                width: "100%",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 0,
                paddingBottom: 0,
              }}
              container
              spacing={0}
              direction="column"
              justify="center"
              alignItems="flex-start"
            >
              {this.renderGroupInfo(this.state.currentData)}
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { entities, locations, collections, assets } = state;
  return {
    entities,
    locations,
    collections,
    assets
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEntities: () => getEntity(dispatch),
    getLocation: entityId => getLocations(dispatch, entityId),
    getCollection: entityId => getCollections(dispatch, entityId),
    getAsset: id => getAssets(dispatch, id),
    resetData: () => dispatch(cleanData()),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(MapView);
