import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import ResetIcon from "@material-ui/icons/Refresh";
import SubmitIcon from "@material-ui/icons/CheckCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import {
  getEntityId,
  storeEntityId,
  storelocationId,
  getlocationId,
  getMaxEntityAccess,
  getMaxLocationAccess
} from "../utils/Utils";
import {
  getEntity,
  getLocations,
  getCollections,
  cleanData,
  showMessage
} from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import GoBackIcon from "@material-ui/icons/ArrowBack";
import { apiRequest } from "../utils/Utils";
import AlertDialog from "./AlertDialog";
import { byName } from "../utils/Utils";


const styles = theme => ({
  rootcheckbox: {
    color: "#4b2e83",
    "&$checked": {
      color: "#4b2e83"
    }
  },
  checked: {},
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    flexGrow: 1
  },
  root2: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  textField: { width: "100%" },
  dense: {
    marginTop: 14
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: "#4b2e83",
    width: "250px"
  },
  input: {
    height: 38
  },
  label: {
    marginTop: "-5px"
  }
});

class CreateLocation extends React.Component {
  state = {
    stateModel: {
      EntityId: sessionStorage.getItem("entityID") ? JSON.parse(sessionStorage.getItem("entityID")) : "",
      EntityShortName: "",
      EntityName: "",
      LocationId: "",
      LocationName: "",
      LocationShortName: "",
      Description: "",
      City: "",
      Address: "",
      ContactNumber: "",
      AlternateNumber: ""
    },
    serverModel: {
      EntityId: "",
      EntityName: "",
      EntityShortName: "",
      LocationId: "",
      LocationName: "",
      LocationShortName: "",
      Description: "",
      City: "",
      Address: "",
      ContactNumber: "",
      AlternateNumber: ""
    },
    entityData: [],
    locationData: [],
    isSubmitting: false,
    disableFields: false,
    openAlert: false,
    disableEntity: this.props.location.search.includes("xyx") ? true : false,
    showRedField: false
  };

  handleChange = name => event => {
    this.LocationId = this.state.stateModel.LocationId;

    this.setState(
      {
        stateModel: {
          ...this.state.stateModel,
          [name]: event.target.value
        }
      },
      () => {
        if (name == "EntityId") {
          storeEntityId(-1);
          storelocationId(-1);
          this.props.getLocation(event.target.value);
          const selectedEntity = this.state.entityData.filter(
            item => item.id === event.target.value
          );
          this.setState({
            stateModel: {
              ...this.state.stateModel,
              EntityName: selectedEntity[0].name,
              EntityShortName: selectedEntity[0].short_name
            }
          });
          storeEntityId(event.target.value);
          return;
        }
        if (name == "LocationId")
          this.checkAccess();
        if (this.state.stateModel.LocationId !== this.LocationId) {
          storelocationId(this.state.stateModel.LocationId);
          this.getLocationDetails();
        }
      }
    );
  };

  checkAccess() {
    const { LocationId } = this.state.stateModel;
    apiRequest(
      `/accesscontrol?resourceType=location&resourceId=${LocationId}`,
      "get"
    )
      .then(response => {
        if (response.data[0] < 300) {
          this.setState({
            disableFields: true
          });
        } else {
          this.setState({
            disableFields: false
          });
        }
      })
      .catch(error => console.log("error", error));
  }

  getLocationDetails() {
    const THAT = this;
    setTimeout(() => {
      this.props.showTables(
        true,
        this.state.stateModel.EntityId,
        this.state.stateModel.LocationId
      );
    }, 1000);
    this.props.getCollection(
      this.state.stateModel.EntityId,
      this.state.stateModel.LocationId
    );

    apiRequest(
      `/entities/${this.state.stateModel.EntityId}/locations/${
        this.state.stateModel.LocationId
      }`,
      "get"
    )
      .then(response => {
        this.setState(
          {
            stateModel: this.state.serverModel
          },
          () => {
            this.setState({
              stateModel: response.data[0] || this.state.serverModel
            });
          }
        );
      })
      .catch(error => console.log("error", error));
  }

  componentWillMount() {
    if (sessionStorage.getItem("entityID")) {
      storeEntityId(JSON.parse(sessionStorage.getItem("entityID")));
    }
    this.props.storeEntity();
    const THAT = this;
    if (this.props.params === "Create" && getEntityId() > 0) {
      this.setState({
        stateModel: {
          ...this.state.stateModel,
          EntityId: getEntityId()
        }
      });
    }

    if (this.props.params === "Modify" && getlocationId() > -1) {
      this.props.getLocation(getEntityId());
      this.setState(
        {
          stateModel: {
            ...this.state.stateModel,
            EntityId: getEntityId(),
            LocationId: getlocationId()
          }
        },
        () => this.getLocationDetails()
      );
    }

    if (this.props.id !== "0" && getEntityId() > -1 /*&& this.state.stateModel.LocationId!=""*/) {
      setTimeout(() => {
        this.props.showTables(
          true,
          this.state.stateModel.EntityId,
          this.state.stateModel.LocationId
        );
      }, 1000);
      this.props.getLocation(getEntityId());

      apiRequest(`/entities/${getEntityId()}/locations/${this.props.id}`, "get")
        .then(response => {
          this.setState({
            stateModel: response.data[0] || this.state.serverModel
          });
        })
        .catch(error => console.log(error));

      this.props.getCollection(getEntityId(), this.props.id);
    }
    else if (this.state.stateModel.EntityId) {
      this.props.getLocation(this.state.stateModel.EntityId);
    }
  }

  handleReset = () => {
    var _entity = sessionStorage.getItem("entityID") ? JSON.parse(sessionStorage.getItem("entityID")) : "";
    //const stateModel = this.state.serverModel;
    storelocationId(-1);
    this.setState({
      stateModel: {
        ...this.state.serverModel,
        EntityId: this.props.entity,
      },
      disableFields: false,
      //locationData: [],
      disableEntity: false
    });
    this.props.showTables(false, _entity);
  };

  stateChange() {
    const { serverModel, stateModel } = this.state;
    return JSON.stringify(serverModel) === JSON.stringify(stateModel)
      ? false
      : true;
  }

  componentWillReceiveProps({ entities = [], locations = [], params, entity }) {
    let storedEntity = getEntityId();
    //if (sessionStorage.getItem("entityID") && sessionStorage.getItem("entityID") != "" && getEntityId() != sessionStorage.getItem("entityID")) {
    if (sessionStorage.getItem("entityID")) {
      const sessionEntity = JSON.parse(sessionStorage.getItem("entityID"));
      if (storedEntity != sessionEntity)
        storeEntityId(sessionEntity);
    }
    if (this.page !== params) {
      this.handleReset();
      this.page = params;
      this.setState({
        disableFields: false
      });
    }
    if (locations.length === 0) {
      this.setState({
        stateModel: {
          ...this.state.serverModel,
          EntityId: this.state.stateModel.EntityId
        }
      });
    }
    this.setState(
      {
        entityData: entities,
        locationData: locations.sort(byName),
        disableEntity: this.props.location.search.includes("xyx") ? true : false
      },
      () => {
        if (this.props.params === "Create" && getEntityId() > 0) {
          this.setState({
            stateModel: {
              ...this.state.stateModel,
              EntityId: getEntityId()
            }
          });
        }
        //if (entity != getEntityId()) {
        if (entity != storedEntity) {
          storeEntityId(entity);
          this.handleReset();
        }
        else if (
          this.props.params === "Modify" &&
          getlocationId() > 0 &&
          this.state.stateModel.LocationId === "" &&
          getEntityId() > 0
        ) {
          this.props.getLocation(getEntityId());
          this.setState(
            {
              stateModel: {
                ...this.state.stateModel,
                EntityId: getEntityId(),
                LocationId: getlocationId()
              }
            },
            () => this.getLocationDetails()
          );
        }
      }
    );
  }

  handleSubmt = () => {
    const THAT = this;
    THAT.setState({
      isSubmitting: true,
      openAlert: false,
      showRedField: false
    });
    const data = this.state.stateModel;
    let url = "";
    let method = "";
    if (this.props.params === "Create") {
      url = `/entities/${this.state.stateModel.EntityId}/locations`;
      method = "post";
    } else if (this.props.params === "Modify") {
      url = `/entities/${this.state.stateModel.EntityId}/locations/${
        this.state.stateModel.LocationId
      }`;
      method = "put";
    }

    apiRequest(url, method, data)
      .then(x => {
        if (x.status > 0) {
          storelocationId(x.data[0]);
          if (THAT.props.params === "Create") {
            THAT.props.showTables(
              THAT,
              THAT.state.stateModel.EntityId,
              THAT.state.stateModel.LocationId
            );
            THAT.props.history.push("/options/Modify/loc/0");
          }
          THAT.props.getLocation(getEntityId());
        }
        THAT.setState({
          isSubmitting: false
        });
        if (x.status > 0 && x.message == null)
          THAT.props.showMessageBox("SUCCESS");
        else
          THAT.props.showMessageBox(x.message);
      })
      .catch(err => {
        THAT.setState({
          isSubmitting: false
        });
        THAT.props.showMessageBox("ERROR");
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        className="justify-content-between align-items-center pb-2 mb-3"
        style={{
          paddingLeft: "12px",
          paddingRight: "12px"
        }}
      >
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={this.handleSubmt}
          action={"Save"}
          close={() => {
            this.setState({ openAlert: false });
          }}
        />
        {getMaxEntityAccess() >= 300 || getMaxLocationAccess() > 500 ? (
          this.props.params === "Create" ? (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid #4b2e83"
                  }}
                >
                  <div className="nav-link">
                    <h5
                      style={{
                        color: "#4b2e83"
                      }}
                    >
                      Add Campus
                    </h5>{" "}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid lightgrey"
                  }}
                >
                  <Link className="nav-link" to="/options/Modify/loc/0" onClick={() => this.setState({ showRedField: false })}>
                    <h5
                      style={{
                        color: "lightgrey"
                      }}
                    >
                      Edit Campus
                    </h5>
                  </Link>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid lightgrey"
                  }}
                >
                  <Link className="nav-link" to="/options/Create/loc/0" onClick={() => this.setState({ showRedField: false })}>
                    <h5
                      style={{
                        color: "lightgrey"
                      }}
                    >
                      Add Campus
                    </h5>
                  </Link>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid #4b2e83"
                  }}
                >
                  <div className="nav-link">
                    <h5
                      style={{
                        color: "#4b2e83"
                      }}
                    >
                      Edit Campus
                    </h5>{" "}
                  </div>
                </div>
              </Grid>
            </Grid>
          )
        ) : (
          <Grid item xs={12}>
            <div
              style={{
                textAlign: "center",
                borderBottom: "2px solid #4b2e83"
              }}
            >
              <div className="nav-link">
                <h5
                  style={{
                    color: "#4b2e83"
                  }}
                >
                  Edit Campus
                </h5>{" "}
              </div>
            </div>
          </Grid>
        )}
        <Paper className={classes.root} style={{ padding: 10 }}>
          <div>
            <Grid
              container
              spacing={2}
              style={
                this.props.params === "Modify"
                  ? { marginBottom: "-13px", marginTop: "-13px" }
                  : {
                      marginBottom: "-13px",
                      marginTop: "-13px",
                      display: "flex",
                      justifyContent: "space-between"
                    }
              }
            >
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <div className="float-left">
                  <h5
                    style={{
                      color: "#4b2e83",
                      width: "100%",
                      paddingLeft: 2,
                      paddingTop: 8
                    }}
                  >
                    <Tooltip title="Go Back">
                      <IconButton
                        style={{
                          color: "#4b2e83",
                          opacity: "1",
                          outline: "none"
                        }}
                        aria-label="Go Back"
                        onClick={() => this.props.history.goBack()}
                      >
                        <GoBackIcon />
                      </IconButton>
                    </Tooltip>
                    Go back to previous screen
                  </h5>
                </div>
              </Grid>

              {!this.state.isSubmitting ? (
                <Grid item style={{ width: "33%" }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Tooltip title="Reset">
                      <IconButton
                        style={
                          this.stateChange()
                            ? {
                                color: "#4b2e83",
                                opaCity: "1",
                                outline: "none"
                              }
                            : { color: "#343a40", opacity: "0.4" }
                        }
                        disabled={!this.stateChange()}
                        aria-label="Reset"
                        onClick={this.handleReset}
                      >
                        <ResetIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={this.props.params === "Create" ? "Create" : "Save"}>
                      <IconButton
                        style={
                          this.stateChange() && !this.state.disableFields
                            ? {
                                color: "#4b2e83",
                                opaCity: "1",
                                outline: "none"
                              }
                            : { color: "#343a40", opacity: "0.4" }
                        }
                        disabled={
                          !this.stateChange() || this.state.disableFields
                        }
                        aria-label={this.props.params === "Create" ? "Create" : "Save"}
                        onClick={() => {
                          if (this.state.stateModel.EntityId == "") {
                            this.props.showMessageBox("Please Select Organization");
                            this.setState({
                              showRedField: true
                            });
                            return;
                          }
                          if (
                            this.state.stateModel.LocationName == "" ||
                            this.state.stateModel.LocationShortName == "" ||
                            this.state.stateModel.City == ""
                          ) {
                            this.props.showMessageBox(
                              "Please fill all the required fields"
                            );
                            this.setState({
                              showRedField: true
                            });
                            return;
                          }
                          this.setState({ openAlert: true });
                        }}
                      >
                        <SubmitIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={4}>
                  <div
                    style={{
                      width: "100%",
                      marginTop: 5,
                      display: "flex",
                      justifyContent: "flex-end"
                    }}
                  >
                    <CircularProgress className={classes.progress} />
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
          <hr />

          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                width="100%"
                id="outlined-select"
                select
                style={{
                  marginTop: 0,
                  marginBottom: 8
                }}
                required
                label="Organization"
                placeholder="Organization"
                margin="dense"
                disabled={this.state.disableEntity || this.state.disableFields}
                className={classes.textField}
                value={this.state.stateModel.EntityId}
                onChange={this.handleChange("EntityId")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                InputProps={{ className: classes.input }}
                InputLabelProps={{ shrink: true, className: classes.label }}
                variant="outlined"
              >
                {this.state.entityData.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}

            {this.props.params === "Modify" ? (
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Tooltip
                  title={
                    (this.state.disableFields || this.props.entity)
                      ? ""
                      : "Please ensure Organization is selected before selecting a Campus"
                  }
                  placement="bottom"
                >
                  <TextField
                    width="100%"
                    id="outlined-select"
                    select
                    required
                    style={{
                      marginTop: 0,
                      marginBottom: 8
                    }}
                    disabled={
                      this.state.locationData.length === 0 ||
                      this.state.disableFields ||
                      !this.props.entity
                    }
                    label="Campus"
                    margin="dense"
                    className={classes.textField}
                    value={this.state.stateModel.LocationId}
                    onChange={this.handleChange("LocationId")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ shrink: true, className: classes.label }}
                    variant="outlined"
                  >
                    {this.state.locationData.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </Grid>
            ) : null}

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                id="outlined-name"
                label="Name"
                value={this.state.stateModel.LocationName}
                type="text"
                error={
                  this.state.showRedField &&
                  this.state.stateModel.LocationName == ""
                    ? true
                    : false
                }
                style={{
                  width: "100%",
                  borderColor: "#4b2e83",
                  marginTop: 0,
                  marginBottom: 8
                }}
                disabled={
                  (this.props.params === "Modify" &&
                    this.state.stateModel.LocationId === "") ||
                  this.state.disableFields ||
                  (this.props.params === "Create" && !this.state.stateModel.EntityId)
                }
                required
                onChange={this.handleChange("LocationName")}
                margin="dense"
                variant="outlined"
                placeholder="Campus Name"
                InputProps={{ className: classes.input }}
                InputLabelProps={{ shrink: true, className: classes.label }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <h5
                style={{
                  color: "#4b2e83",
                  width: "100%",
                  paddingLeft: 2,
                  paddingTop: 4
                }}
              >
                Info
              </h5>
              <hr />
              <TextField
                id="outlined-name"
                label="Short Name"
                value={this.state.stateModel.LocationShortName}
                type="text"
                style={{
                  paddingBottom: "5px",
                  width: "100%",
                  borderColor: "#4b2e83"
                }}
                required
                error={
                  this.state.showRedField &&
                  this.state.stateModel.LocationShortName == ""
                    ? true
                    : false
                }
                disabled={
                  (this.props.params === "Modify" &&
                    this.state.stateModel.LocationId === "") ||
                  this.state.disableFields ||
                  (this.props.params === "Create" && !this.state.stateModel.EntityId)
                }
                onChange={this.handleChange("LocationShortName")}
                margin="dense"
                variant="outlined"
                placeholder="Campus Short Name"
                InputProps={{ className: classes.input }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label
                }}
              />
              <TextField
                id="outlined-name"
                label="Description"
                value={this.state.stateModel.Description == null ? "" : this.state.stateModel.Description}
                type="text"
                style={{
                  width: "100%",
                  borderColor: "#4b2e83"
                }}
                disabled={
                  (this.props.params === "Modify" &&
                    this.state.stateModel.LocationId === "") ||
                  this.state.disableFields ||
                  (this.props.params === "Create" && !this.state.stateModel.EntityId)
                }
                onChange={this.handleChange("Description")}
                margin="dense"
                multiline
                rows="3"
                variant="outlined"
                placeholder="Description"
                InputLabelProps={{
                  shrink: true,
                  className: classes.label
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <h5
                style={{
                  color: "#4b2e83",
                  width: "100%",
                  paddingLeft: 2,
                  paddingTop: 4
                }}
              >
                Address
              </h5>
              <hr />
              <TextField
                id="outlined-name"
                label="City"
                required
                value={this.state.stateModel.City}
                type="text"
                error={
                  this.state.showRedField && this.state.stateModel.City == ""
                    ? true
                    : false
                }
                style={{
                  paddingBottom: "5px",
                  width: "100%",
                  borderColor: "#4b2e83"
                }}
                disabled={
                  (this.props.params === "Modify" &&
                    this.state.stateModel.LocationId === "") ||
                  this.state.disableFields ||
                  (this.props.params === "Create" && !this.state.stateModel.EntityId)
                }
                onChange={this.handleChange("City")}
                margin="dense"
                variant="outlined"
                placeholder="City"
                InputProps={{ className: classes.input }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label
                }}
              />
              <TextField
                id="outlined-name"
                label="Street Address"
                value={this.state.stateModel.Address == null ? "" : this.state.stateModel.Address}
                type="text"
                style={{
                  width: "100%",
                  borderColor: "#4b2e83"
                }}
                disabled={
                  (this.props.params === "Modify" &&
                    this.state.stateModel.LocationId === "") ||
                  this.state.disableFields ||
                  (this.props.params === "Create" && !this.state.stateModel.EntityId)
                }
                onChange={this.handleChange("Address")}
                margin="dense"
                multiline
                rows="3"
                variant="outlined"
                placeholder="Address"
                InputLabelProps={{
                  shrink: true,
                  className: classes.label
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <h5
                style={{
                  color: "#4b2e83",
                  width: "100%",
                  paddingLeft: 2,
                  paddingTop: 4
                }}
              >
                Contact Details
              </h5>
              <hr />
              <TextField
                id="outlined-name"
                label="Contact Number"
                value={this.state.stateModel.ContactNumber == null ? "" : this.state.stateModel.ContactNumber}
                type="number"
                style={{
                  paddingBottom: "5px",
                  width: "100%",
                  borderColor: "#4b2e83"
                }}
                disabled={
                  (this.props.params === "Modify" &&
                    this.state.stateModel.LocationId === "") ||
                  this.state.disableFields ||
                  (this.props.params === "Create" && !this.state.stateModel.EntityId)
                }
                onChange={this.handleChange("ContactNumber")}
                margin="dense"
                variant="outlined"
                placeholder="Contact Number"
                InputProps={{ className: classes.input }}
                InputLabelProps={{ shrink: true, className: classes.label }}
              />
              <TextField
                id="outlined-name"
                label="Alternate Contact Number"
                value={this.state.stateModel.AlternateNumber == null ? "" : this.state.stateModel.AlternateNumber}
                type="number"
                style={{
                  width: "100%",
                  borderColor: "#4b2e83"
                }}
                disabled={
                  (this.props.params === "Modify" &&
                    this.state.stateModel.LocationId === "") ||
                  this.state.disableFields ||
                  (this.props.params === "Create" && !this.state.stateModel.EntityId)
                }
                onChange={this.handleChange("AlternateNumber")}
                margin="dense"
                variant="outlined"
                placeholder="Alternate Contact Number"
                InputProps={{ className: classes.input }}
                InputLabelProps={{ shrink: true, className: classes.label }}
              />
            </Grid>
          </Grid>
        </Paper>
        {this.state.submit ? (
          <React.Fragment>
            <Paper
              className={classes.root}
              style={{
                padding: 3,
                display: "flex",
                justifyContent: "space-evenly"
              }}
            >
              <Link to="/options/create/collection">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Create Collection
                </Button>
              </Link>
            </Paper>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { entities, locations } = state;
  return {
    entities,
    locations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    storeEntity: () => getEntity(dispatch),
    getLocation: entityId => getLocations(dispatch, entityId),
    getCollection: (entityId, locationId) => getCollections(dispatch, entityId, locationId),
    resetData: () => dispatch(cleanData()),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(CreateLocation);
